import { useSelector } from "react-redux"
import { Box, FormControl, Select, MenuItem, Checkbox, ListItemText, TextField, InputAdornment } from "@mui/material"
import { MenuItemStyle } from "../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FleetSelectIcon from "../../assets/Icons/FleetSelectIocn.svg"
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from "react";


const FleetFilter = ({setFleetOptions}) => {
    const {fleetData}=useSelector((state)=>state.fleetManage)
    const  fleetOptions = fleetData.map((fleet)=>fleet.fleet_name)

    const [selectedFleets, setSelectedFleets] = useState(['All Fleet', ...fleetOptions]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFleets, setFilteredFleets] = useState(fleetOptions.slice(0, 3));

    useEffect(()=>{
        setFleetOptions(['All Fleet',...fleetOptions])
    },[])

    const handleFleetChange = (event) => {
        const value = event.target.value;
        if (value.includes('All Fleet')) {

           
            if (selectedFleets.length === fleetOptions.length + 1) {
               console.log("working here box1")
                setSelectedFleets([]);
                setFleetOptions([])
            } 

            else {
                console.log("working here box2")
                setSelectedFleets(['All Fleet', ...fleetOptions]);
                setFleetOptions(['All Fleet', ...fleetOptions])
            }
        } 
        else {
          
            setSelectedFleets(value);
            setFleetOptions(value)

           
            if (value.length === fleetOptions.length) {
                setSelectedFleets(['All Fleet', ...fleetOptions]);
            }
        }
    };

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            if (searchTerm) {
                const filtered = fleetOptions.filter(fleet =>
                    fleet.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setFilteredFleets(filtered);
            } else {
                setFilteredFleets(fleetOptions.slice(0, 3)); // Default to 3 fleets if no search term
            }
        }, 300); // 300ms debounce

        return () => clearTimeout(debounceTimeout);
    }, [searchTerm]);

    const isAllSelected = selectedFleets.length === fleetOptions.length + 1

    return (
        <Box sx=
            {{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                gap: "10px",
                height:"40px",
                border: "1px solid #DDDEE1",
                background: "white",
                borderRadius: "8px",
                
            }} >
            <Box sx={{paddingInline:"5px"}} >
                <img src={FleetSelectIcon} />
            </Box>
            <Box
                sx={{
                    width: "fit-content"
                }}
            >
                <FormControl variant="standard" sx={{ width: "200px" }} >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        multiple
                        value={selectedFleets}
                        onChange={handleFleetChange}
                        disableUnderline
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{ width: "90%" }}
                        renderValue={(selected) =>
                            selected.includes('All Fleet') ? 'All Fleet' : selected.join(', ')
                        }
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300, // Limit dropdown height
                                },
                            },
                        }}
                    >

                        <MenuItem disableGutters sx={ {
                            paddingInline: "20px",
                            '&.Mui-selected': {
                                backgroundColor: 'white', 
                            },
                            '&:hover': {
                                backgroundColor: 'white', 
                            },

                        }} >
                            <TextField
                                variant="outlined"
                                placeholder="Search Fleets..."
                                sx={{
                                    color:"#283246",
                                    // Disable border color change on focus
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'inherit', // Maintain the original color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'inherit',

                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'inherit', // Disable focus change
                                        },
                                    },
                                }}
                                fullWidth
                                size="small"
                                onKeyDown={(e) => e.stopPropagation()}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MenuItem>
                        {/* 'All Fleet' Option */}
                        <MenuItem 
                            value="All Fleet"  
                            sx={{
                                '&.Mui-selected': {
                                    backgroundColor: 'transparent', 
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent', 
                                },
                                color:"#283246"
                            }} >
                            <Checkbox 
                                checked={isAllSelected} 
                                sx={{
                                    '&.Mui-checked': {
                                      color: "#283246"
                                    },
                                  }}
                                />
                            <ListItemText primary="All Fleet" />
                        </MenuItem>

                        {/* Individual fleet options */}
                        {filteredFleets.map((fleet, index) => (
                            <MenuItem 
                                key={index} 
                                value={fleet} 
                                sx={{
                                    '&.Mui-selected': {backgroundColor: 'transparent', },
                                    '&:hover': {backgroundColor: 'transparent', },
                                    color:"#283246"
                                    }}  >
                                <Checkbox 
                                    checked={selectedFleets.indexOf(fleet) > -1} 
                                    sx={{
                                        '&.Mui-checked': {
                                          color: "#283246"
                                        },
                                      }}
                                    />
                                <ListItemText primary={fleet} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default FleetFilter
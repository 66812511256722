import React, { useState } from "react";
import { Autocomplete, Avatar, Box, Button, Card, Checkbox, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Popper, Select, Tab, Tabs, TextField, Typography, useTheme, FormHelperText } from "@mui/material";
import { deactivateTableCellItem, deletedTableCellItem, paymentTableCellItem, tripDetailsTableCellItem, userDataEdit } from "./utils";
import SearchTextField from "../../components/SearchTextField";
import TripsTableComponent from "../../components/TripsTable/index"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton"
import backicon from "../../assets/Icons/backicon.svg"
import UserTimeline from "./UserTimeline";
import TripDetails from "./TripDetails";
import { updatedActiveUser } from "../../slices/userManageSlice";
import PaymentDetailsModal from "./PaymentDetailsModal";
import CircleIcon from '@mui/icons-material/Circle';
import OutLinedButton from "../../components/MainButtons/OutLinedButton";
import AnimatedNumber from "../../components/AnimateNumbers";
import { useSnackbar } from "../../hooks/Snackbar"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const tabStyles = {
  width: '191px',
  height: '40px',
  fontWeight: 600,
  fontSize: '1rem',
  textTransform: 'none',
  color: '#F99494',
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
    color: '#FFFFFF',
    borderRadius: '12px',
  },
};



const inputLabel = {
  fontSize: "12px",
  fontWeight: 700,
  mb: 1,
  color: "#283246"
}
export const EditUser = () => {
  const [value, setValue] = useState(0)
  const [searchInput, setSearchInput] = useState("")
  const [openTransaction, setTransactionOpen] = useState(false)
  const [tripDetails, setTripDetails] = useState("")
  const [paymentDetailsModal, setPaymentDeailsModal] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState("")
  const location = useLocation()
  const { data } = location.state || {}
  const showSnackbar = useSnackbar()

  const { activeUser, deactivatedUser } = useSelector((state) => state.userManage);

  const navigate = useNavigate()
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch()


  // Filter the user from either activeUser or deactivatedUser based on the id
  const user = activeUser.find((u) => u.id === id) || deactivatedUser.find((u) => u.id === id);
  // const user=data

  const [formData, setFormData] = useState({
    id: user?.id,
    user_name: user?.user_name,
    phone: user?.phone,
    email: user?.email,
    avliable_fleet: user?.avliable_fleet,
    register_date: user?.register_date,
    status: user.status,
  });

  const [errors, setErrors] = useState({
    user_name: false,
    phone: false,
    email: false,
    avliable_fleet: false,
    register_date: false,
    status: false,
  })

  // Handle the case when the user is not found
  if (!user) {
    return <Typography variant="h4">User not found</Typography>;
  }



  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prev) => ({
      ...prev,
      [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
    }));
  };

  const validateForm = () => {
    let hasErrors = false;
    const newErrors = Object.keys(formData).reduce((acc, key) => {
      const value = formData[key];
      acc[key] = value === "" || (typeof value === "string" && (value.includes("Select") || value.includes("Need")));
      if (acc[key]) hasErrors = true;
      return acc;
    }, {});

    setErrors(newErrors);
    return !hasErrors;
  };

  // Handle save changes
  const handleSaveChanges = () => {

    if (validateForm()) {
      console.log("Form data:", formData);
      dispatch(updatedActiveUser(formData))
      showSnackbar("Edited User successfully", "success");
      // navigate("/dashboard/user-management")
    } else {
      console.log(errors, "Validation failed");
    }


  };

  // Handle cancel (reset the form data back to original user data)
  const handleCancel = () => {
    setFormData({
      user_name: user.user_name,
      phone: user.phone,
      email: user.email,
      fleet: user.fleet,
      register_date: user.register_date,
      status: user.status,
    });
  };

  const handleChange = (event, newValue) => {
    setSearchInput('')
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  console.log(user, "this is user")

  const filteredTripDetails = searchInput
    ? user.trip_details
      .filter((user) =>
        user?.vehicle?.toLowerCase().includes(searchInput?.toLowerCase())
      )
      .sort((a, b) => a.vehicle.localeCompare(b.vehicle))
    : user.trip_details;
  console.log(filteredTripDetails, "filter")

  const filteredPaymentDetails = searchInput
    ? user.payment_details
      .filter((user) =>
        user?.fleet?.toLowerCase().includes(searchInput?.toLowerCase())
      )
      .sort((a, b) => a.fleet.localeCompare(b.fleet))
    : user.payment_details;




  const handleTransactionDetails = (userId) => {
    setTransactionOpen(true)
    setTripDetails(userId)
  }


  const hanldeTrip = (trip) => {
    setTransactionOpen(true)
    setTripDetails(trip)
    console.log(trip, 'trip---')
  }

  const handlePayment = (payment) => {
    setPaymentDeailsModal(true)
    setPaymentDetails(payment)
    console.log(payment, "this is payment")
  }

  const handleTransactionClose = () => {
    setTransactionOpen(false)
  }
  console.log(formData, "this is cgah")
  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", mb: 2 }}>


          <Box sx={{display:"flex",alignItems:"center"}} >
            <Box>
              <IconButton onClick={() => navigate(-1)} >
                <KeyboardBackspaceIcon sx={{ color: "#535A6A", fontSize: "30px" }} />
              </IconButton>
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                ml: 2,
                fontSize: "1.375rem",
                color: "#283246",
              }}
            >
              {user.user_name}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: "10px" }} >

            <Chip
              label={`Last active: ${user.last_active}`}
              variant="outlined"
              size="small"
              icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
              sx={{
                color: "#F99494",
                background: "#FFF3F3",
                borderColor: "#F99494",
                fontSize: "16px",
                fontWeight: "600"
              }}
            />
          </Box>

        </Box>

        <Box sx={{
          display: "flex", gap: 2,
          mt: 1,
          mb: 2,
        }}>
          {userDataEdit.map((user) => (
            <Card
              sx={{
                width: "20%",
                borderRadius: "1rem",
                padding: "1rem 3.438rem",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Center content horizontally
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "52px",
                  height: "52px",
                  background: "#97A1B61A",
                  display: "flex",
                  alignItems: "center", // Center content vertically
                  justifyContent: "center", // Center content horizontally
                  mb: 1,
                }}
              >
                <Avatar src={user.icon} />
              </Box>

              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#283246",
                  fontWeight: 500,
                  marginBottom: "0.5rem", // Optional: Adjust spacing between text elements
                }}
              >
                {user.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.375rem",
                  color: theme.typography.text1.color,
                  fontWeight: 700,
                }}
              >
                {user.count}
              </Typography>
            </Card>
          ))}
        </Box>
      </Paper>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Active Users"
            sx={{
              bgcolor: '#F8F8F8',
              borderRadius: '12px',
              border: '1px solid #DDDEE1',
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {['User Information', 'Trips Details', 'Payment', 'Timeline'].map((label, index) => (
              <Tab
                key={label}
                disableRipple
                label={label}
                sx={tabStyles}
              />
            ))}
          </Tabs>
        </Box>

        <Box>

          {value === 0 && (
            <>
              <Typography sx={{ fontSize: '1.4rem', fontWeight: 600, ml: 4, color: "#283246" }}>User Details</Typography>
              <Grid container spacing={4} sx={{ padding: 4 }}>
                {/* User Name */}
                <Grid item xs={12} md={4}>
                  <InputLabel sx={inputLabel}>
                    User Name <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.user_name}
                    placeholder="Enter User Name"
                    name="user_name"
                    onChange={handleInputChange}
                    error={errors.user_name}
                    helperText={errors.user_name ? "User name is required" : ""}
                  />
                </Grid>

                {/* Phone Number */}
                <Grid item xs={12} md={4}>
                  <InputLabel sx={inputLabel}>
                    Phone Number <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Enter Phone Number Here"
                    name="phone"
                    error={errors.phone}
                    helperText={errors.phone ? "Phone Number is required" : ""}
                  />
                </Grid>

                {/* Email */}
                <Grid item xs={12} md={4}>
                  <InputLabel sx={inputLabel}>
                    Email <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.email}
                    onChange={handleInputChange}
                    name="email"
                    placeholder="Enter Email Here"
                    error={errors.email}
                    helperText={errors.email ? "Email is required" : ""}
                  />
                </Grid>
              </Grid>

              <Typography sx={{ fontSize: '1.4rem', fontWeight: 600, ml: 4, color: "#283246" }}>Fleet Assignment</Typography>
              <Grid container spacing={4} sx={{ padding: 4 }}>

                <Grid item xs={12} md={4}>
                  <InputLabel sx={inputLabel}>
                    Fleet  <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>

                  <Autocomplete
                    multiple
                    name="avliable_fleet"
                    options={formData.avliable_fleet}
                    value={formData.avliable_fleet}
                    onChange={handleInputChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option}
                          {...getTagProps({ index })}
                          sx={{ maxWidth: "100%" }}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select fleet"
                        sx={{ width: "300px", height: "40px" }}
                      />
                    )}
                    PaperComponent={(props) => (
                      <Paper {...props} sx={{ maxHeight: 200, overflowY: "auto" }} />
                    )}
                    PopperComponent={(props) => (
                      <Popper {...props} placement="bottom" sx={{ width: "300px" }} />
                    )}
                    fullWidth={false} // Disable fullWidth if needed
                  />

                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel sx={inputLabel}>
                    Register Date <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.register_date}
                    onChange={handleInputChange}
                    name="register_date"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel sx={inputLabel}>
                    Status <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <FormControl error={errors.status} >
                    <Select
                      value={formData.status}
                      onChange={handleInputChange}
                      name="status"
                      sx={{
                        borderRadius: "10px",
                        width: {
                          xs: "100px",
                          sm: "300px",
                          md: "400px",
                          lg: "300px",
                          xl: "400px"
                        },
                        height: '40px',
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#283246",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1",
                        },
                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1",
                        },
                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1",
                        },
                        "& .MuiSelect-select": {
                          color: "#283246",
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: '"Helvetica Neue", Arial, sans-serif',
                        },


                      }}
                    >
                      <MenuItem value="Select Status">Select Status</MenuItem>
                      <MenuItem value="active">
                        <Box>
                          <Chip
                            label="Active"
                            variant="outlined"
                            icon={<CircleIcon color="#83C3A0" />}
                            size="small"
                            sx={{
                              color: "#83C3A0",
                              background: "#F1F7F3",
                              borderColor: "#83C3A0",
                              fontSize: "16px",
                              fontWeight: "600"
                            }}
                          />
                        </Box>
                      </MenuItem>
                      <MenuItem value="inactive">
                        <Box>
                          <Chip
                            label="Inactive"
                            variant="outlined"
                            size="small"
                            icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                            sx={{
                              color: "#F99494",
                              background: "#FFF3F3",
                              borderColor: "#F99494",
                              fontSize: "16px",
                              fontWeight: "600"
                            }}
                          />
                        </Box>
                      </MenuItem>
                    </Select>
                    {errors.status && (
                      <FormHelperText>
                        Status is required
                      </FormHelperText>
                    )}

                  </FormControl>

                </Grid>

              </Grid>


              <Box item display="flex" gap={2} sx={{ mt: 15, ml: 3 }}>
                <FilledButton
                  sx={{
                    width: '150px',borderRadius: '10px', fontSize: "16px", fontWeight: "600"
                  }}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </FilledButton>
                <FilledButton

                  onClick={() => navigate("/dashboard/user-management")}
                  sx={{
                    border: `1px solid #97A1B6`,
                    width: "150px",
                    color: "black",
                    background: "white",
                    boxShadow: 'none',
                    "&:hover": {
                      background: "white",
                    },
                  }}
                >
                  Cancel
                </FilledButton>
              </Box>
            </>
          )}


          {value === 1 &&
            <>
              <Box sx={{ border: '1px solid #DDDEE1', height: '42px', p: 1, background: '#DDDEE1', borderRadius: '6px', mb: 3 }}>
                <SearchTextField value={searchInput}
                  onChange={handleSearchChange} />
              </Box>

              <TripsTableComponent
                data={filteredTripDetails}
                tableHeaders={tripDetailsTableCellItem}
                showActions={true}
                handleEdit={hanldeTrip}
                handleDelete={() => { }}
              />
            </>
          }

          {value === 2 &&
            <>
              <Box sx={{ border: '1px solid #DDDEE1', height: '42px', p: 1, background: '#DDDEE1', borderRadius: '6px', mb: 3 }}>
                <SearchTextField value={searchInput}
                  onChange={handleSearchChange}
                  placeholder="Search by fleet"
                />
              </Box>
              <TripsTableComponent
                data={filteredPaymentDetails}
                tableHeaders={paymentTableCellItem}
                showActions={true}
                handleEdit={handlePayment}
                handleDelete={() => { }}
              />
            </>
          }

          {value === 3 && <UserTimeline timelineData={user.activity} username={user.user_name} />}
        </Box>

        <TripDetails open={openTransaction} handleClose={handleTransactionClose} TripData={tripDetails} />
        <PaymentDetailsModal selectedPaymentData={paymentDetails} paymentDetailsModal={paymentDetailsModal} setPaymentDeailsModal={setPaymentDeailsModal} />
      </Paper>
    </>
  );
};





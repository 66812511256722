import { Box, Paper, MenuItem, FormControl, Select, Typography } from "@mui/material"
import SearchTextField from "../../../components/SearchTextField"
import FleetSelectIocn from "../../../assets/Icons/FleetSelectIocn.svg"
import StatusSelectIocn from "../../../assets/Icons/StatusSelectIcon.svg"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import Plus from "../../../assets/Icons/plus.svg"
import FilledButton from "../../../components/MainButtons/FilledButton"
import { ParkingZoneTablesCellItems, ParkingZoneTablesData } from "../utills"
import UserTableComponent from "../../UserManagement/UserTable"
import { useDispatch, useSelector } from "react-redux"
import DeleteParkingZoneModal from "../DeleteParkingZone/DeleteParkingZone"
import { deleteParkingZone } from "../../../slices/parkingzoneSlice"
import { MenuItemStyle } from "../../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FleetFilter from "../../../components/FleetSelector/index";
import { deleteGeofence } from "../../../slices/geofenceSlice"
import DeleteModal from "../../../components/DeleteModal/DeleteModal"
import { useSnackbar } from "../../../hooks/Snackbar";


const ParkingZoneTable = () => {
    const navigate = useNavigate()
    const [searchInput, setSearchInput] = useState("");
    const [fleetOptions, setFleetOptions] = useState("All")
    const [status, setStatus] = useState("All")
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteParkingZoneId, setDeleteParkingZoneId] = useState()
    const [deleteParkingZoneName, setDeleteParkingZoneName] = useState()
    const { geofenceData } = useSelector((state) => state.geofenceManage)
    const showSnackbar=useSnackbar()
    const parkingzoneData = geofenceData.filter(data => data.zone_type == "Parking Zone")

    const dispatch = useDispatch()

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const handleFleetChange = (e) => {
        setFleetOptions(e.target.value)
    }
    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    }

    const handleDeleteParkingZone = () => {

        dispatch(deleteGeofence(deleteParkingZoneId))
        setDeleteModal(false)
        showSnackbar("Deleted parking zone successfully","success")

    }

    const OpenDeleteParkingZone = (id, name) => {
        setDeleteModal(true)
        setDeleteParkingZoneId(id)
        setDeleteParkingZoneName(name)
    }


    const filterParkingZoneTablesData = parkingzoneData.filter((parkingZone) => {
        const matchesSearchInput = searchInput ? parkingZone.name.includes(searchInput) : true;
        const matchesFleetOption = fleetOptions.includes("All Fleet") || fleetOptions.includes(parkingZone.fleet);
        const matchesStatus = status !== "All" ? parkingZone.status === status : true;

        return matchesSearchInput && matchesFleetOption && matchesStatus
    });



    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
            <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                <Box  >
                    <Typography sx={{ display: "flex", gap: "10px", fontSize: "24px", fontWeight: "700", color: "#283246" }}>Parking Zone</Typography>
                </Box>
            </Box>
            <Box sx=
                {{
                    border: '1px solid #DDDEE1',
                    background: '#F1F1F2',
                    height: '42px',
                    p: 1,
                    borderRadius: '6px',
                    mb: 2,
                    display: "flex"

                }}>

                <Box sx={{ display: "flex", gap: "20px", flex: 1 }} >
                    <FleetFilter setFleetOptions={setFleetOptions} />

                    <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={StatusSelectIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    onChange={(e) => handleStatusChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}

                                >
                                    <MenuItem sx={MenuItemStyle} value={"All"}>Status</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Active"}>Active</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Inactive"}>Inactive</MenuItem>

                                </Select>
                            </FormControl>
                        </Box>

                    </Box>

                </Box>
                <Box>
                    <SearchTextField value={searchInput} onChange={handleSearchChange} />
                </Box>
                <Box
                    onClick={() => { navigate("/dashboard/geofence/create-parking-zone") }}
                >
                    <FilledButton startIcon={<img src={Plus} style={{ width: "18px", height: "18px" }} />} sx={{ width: '200px', height: '40px', borderRadius: '6px', ml: 3, fontSize: "14px", fontWeight: "600" }}>
                        Add Parking Zone
                    </FilledButton>
                </Box>
            </Box>
            <Box>
                <UserTableComponent
                    tableHeaders={ParkingZoneTablesCellItems}
                    data={filterParkingZoneTablesData}
                    showActions={true}
                    handleEdit={() => { }}
                    handleDelete={OpenDeleteParkingZone}
                    parkingZoneNavigation={true}
                />
            </Box>
            <DeleteModal
                open={deleteModal}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDeleteParkingZone}
                title={"Confirm Parking Zone Removal"}
                description={
                    <>
                        Are you sure you want to permanently remove
                        <span style={{ fontWeight: 600 }}> {deleteParkingZoneName} </span>?
                        This action cannot be undone, and all associated data will be lost.
                    </>
                }
                buttonText={"Yes"}
            />
        </Box>
    )
}

export default ParkingZoneTable
import { createSlice } from "@reduxjs/toolkit";
import { FleetTablesData } from "../features/Fleet/FleetTable/utills";
import { useSelector } from "react-redux";




const avliableFleetOptions=FleetTablesData.map((fleet)=>fleet.fleet_name)


const initialState={
    fleetOptions:avliableFleetOptions||[]
}

const FleetOptionsSlice=createSlice({
    name:"AvlaibleFleetList",
    initialState,

})

export default FleetOptionsSlice.reducer
import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts"
import "./UserAreaChart.css"
import CustomDatePicker from "../../../Trips/TripsTable/CustomDatePicker";
import { useState } from "react";
const UsersAreaChart = () => {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)

    const series = [{
        name: "Users",
        data: [320, 280, 150, 280, 390, 275, 325, 300], // Sample data for the years 2017, 2018
    }];

    const options = {
        chart: {
            type: "area",
            toolbar: {
                show: false
            }
        },
        tooltip: {
            enabled: true,
        },
        xaxis: {
            categories:
                [2017, 2018, 2019, 2020, 2021, 2022,
                    2023, 2024],
            labels: {
                style: {
                    colors: ["#535A6A"],
                    fontSize: '12px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: 400,
                    // cssClass: 'apexcharts-yaxis-label',
                },
            }
        },
        yaxis: {
            min: 0,
            max: 400,
            tickAmount: 5,
            labels: {
                style: {
                    colors: ["#535A6A"],
                    fontSize: '12px',
                    fontFamily: 'Helvetica Neue',
                    fontWeight: 400,
                    // cssClass: 'apexcharts-yaxis-label',
                },
            }

        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            colors: ["#FFBABAF2"],
            width: 2
        },
        fill: {
            colors: "#FFBABAF2",
            opacity: 0.5,
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 0,
                gradientToColors: "#FFBABAF2",
                inverseColors: true,
                opacityFrom: 0.1,
                opacityTo: 0.7,
                stops: [0, 0, 100],

            }
        },
        markers: {
            size: 0,
            colors: "#FFBABAF2",
            hover: {
                size: 4,
            }
        },
        colors: ['#FFBABAF2'],


    };
    return (
        <Box
            bgcolor="#F8F8F8"
            borderRadius="25px"
            padding="5px"
        >
            <Box sx={{display:'flex',justifyContent:"space-between",marginInline:"20px"}} >
                <Box sx={{}} >
                    <Typography

                        sx={{
                            paddingInline: "15px",
                            paddingBlock: "10px",
                            color: "#283246",
                            fontSize: "16px",
                            fontWeight: "600"
                        }}>
                        Users
                    </Typography>
                </Box>
                <Box>
                    {/* <CustomDatePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    /> */}
                </Box>

            </Box>
            <Box  >
                <Chart
                    type="area"
                    series={series}
                    options={options}
                    height={"260px"}
                />
            </Box>
        </Box>
    )
}

export default UsersAreaChart
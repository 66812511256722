import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import noresults from "../../assets/Icons/noresults.svg"

const NoResultsFound = () => {
    const theme = useTheme()
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh', width: '100%' }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 1,
                    maxWidth: "600px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                <img
                    src={noresults}
                    alt="No Results"
                    style={{ width: "300px", marginBottom: "1rem" }}
                />
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontSize: "1rem", color: theme.palette.text.primary, marginBottom: "1.5rem", fontFamily: "DM Sans, Arial, sans-serif", }}
                >
                    No Results Found
                </Typography>
            </Box>
        </Box>
    );
};

export default NoResultsFound;
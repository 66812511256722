import { Box } from "@mui/material"
import TripsCards from "./TripsCards"
import TripsTimeline from "./TripsTimeline"
import TripsMap from "./TripsMap"
import { useLocation } from "react-router-dom";
const TripsDetails = () => {
    const location = useLocation()

    const { data } = location.state || {}
    console.log("this is data", data)

  
    return (
        <Box>
            <Box>
                <TripsCards
                    cost={data.cost}
                    duration={data.duration}
                    pause_duration={data.pause_duration}
                    distance={data.distance}
                    trip_id={data.trip_id}
                    ratings={data.ratings}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap:"5px"

                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        padding: 1,
                    }}
                >
                    <TripsTimeline
                        vehicle_id={data.vehicle_id}
                        user_id={data.user_id}
                        date={data.date}
                        ratings={data.ratings}
                        trip_id={data.trip_id}
                        activity={data.activity}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        padding: 1,
                    }}
                >
                    <TripsMap />
                    

                </Box>
            </Box>
        </Box>
    )
}

export default TripsDetails
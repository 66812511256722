import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts"
import { useState } from "react";
import CustomDatePicker from "../../../Trips/TripsTable/CustomDatePicker";

const VechiclesLineChart = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const series = [
    {
      name: 'Vehicles',
      data: [30, 50, 40, 60, 20, 45, 35, 60]
    }
  ];
  const options = {
    chart: {
      offsetY: 0,
      type: 'line',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: [
        '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'
      ],
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '12px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
        },
      }

    },
    yaxis: {
      min: 0,
      max: 150,
      tickAmount: 10,
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '12px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
        },

      },


    },

    colors: ['#F99494'], // Line color
    stroke: {
      curve: "straight",
      width: 1, // Line width
    },
    markers: {
      size: 3,
    },
    grid: {
      borderColor: '#e7e7e7',
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  return (
    <Box
      bgcolor="#F8F8F8"
      borderRadius="25px"
    >
      <Box sx={{display:'flex',
        alignItems:"center",
        justifyContent:"space-between",
        padding:"10px"
        
        }}  >
        <Box>
          <Typography sx={{
           paddingInline: "15px",
           paddingTop:"5px",
           color: "#283246",
           fontSize: "16px",
           fontWeight: "600"
          }} >Vechicles</Typography>
        </Box>
        <Box>
          {/* <CustomDatePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          /> */}
        </Box>
      </Box>
      <Box>
        <Chart
          options={options}
          series={series}
          type="line"
          height="350"
        />
      </Box>
    </Box>
  )
}
export default VechiclesLineChart
 export const GeofenceData = [
    {
        "name": "Shyamal Road Parking Zone",
        "id": "1",
        "fleet": "Shyamal Road Public Fleet",
        "zone_type": "Parking Zone",
        "minimum_vehicles": "10",
        "maximum_vehicles": "0",
        "desc": "THIS IS SHYAMAL PARKING ZONE",
        "status": "Active",
        "show_oprator_app": false,
        "lat": 23.014433014483792,
        "lng": 72.53036370328435,
        "created_date": "Oct 2th, 2024 18:33:45",
        "updated_date": "Oct 5th, 2024 18:33:45",
        "created_by": "User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        "geofenceData": [{
            id: "1",
            geofence_name: "Shyamal Road Parking Zone",
            fleet: 'Shyamal Road Public Fleet',
            zone: 'Parking Zone',
            type: 'polygon',
            status: 'Active',
            throttle: 'off',
            alarm: 'on',
            create_date: "Oct 2th, 2024 18:33:45",
            create_by: "Admin",
            updated_date: "Oct 5th, 2024 18:33:45",
            coordinates: {
                path: [
                    { lat: 23.012207855201176, lng: 72.50490059467073 },
                    { lat: 23.010390833351746, lng: 72.52884735675569 },
                    { lat: 23.002411447735522, lng: 72.51648773761507 }
                ], center: { lat: 23.00833671209615, lng: 72.5167452296805 }
            }
        }]
    },
    // {
    //     "name": "Ranip Parking Area",
    //     "id": "2",
    //     "fleet": "New Ranip Private Fleet",
    //     "zone_type": "Parking Zone",
    //     "minimum_vehicles": "22",
    //     "maximum_vehicles": "10",
    //     "desc": "THIS IS RANIP PARKING ZONE",
    //     "status": "Inactive",
    //     "show_oprator_app": true,
    //     "lat": 23.080933619335358,
    //     "lng": 72.57671187123493,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User2",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "2",
    //         geofence_name: "Ranip Parking Area",
    //         fleet: 'New Ranip Private Fleet',
    //         zone: 'Parking Zone',
    //         type: 'circle',
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates: { center: { lat: 23.093632438629022, lng: 72.55948850871505 }, radius: 997.2592983013626 },
    //     }]

    // },
    // {
    //     "name": "Shivranjini Parking Area ",
    //     "id": "3",
    //     "fleet": "Shivranjani Private Fleet",
    //     "zone_type": "Parking Zone",
    //     "minimum_vehicles": "18",
    //     "maximum_vehicles": "20",
    //     "desc": "THIS IS IS Shivranjini PARKING ZONE",
    //     "status": "Active",
    //     "show_oprator_app": false,
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "3",
    //         geofence_name: "Shivranjini Parking Area",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Parking Zone',
    //         type: 'polygon',
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates: {
    //             path: [
    //                 { lat: 23.00431238454394, lng: 72.5172662988116 },
    //                 { lat: 23.012607658855412, lng: 72.54061224607723 },
    //                 { lat: 22.993883316240023, lng: 72.53546240476864 }],
    //             center: { lat: 23.00360111987979, lng: 72.53111364988582 }
    //         }
    //     }]
    // },
    // {
    //     "name": "Shivranjini Parking Area North Zone",
    //     "id": "4",
    //     "fleet": "Shivranjani Private Fleet",
    //     "zone_type": "Parking Zone",
    //     "minimum_vehicles": "15",
    //     "maximum_vehicles": "10",
    //     "desc": "THIS IS Shivranjini Parking PARKING ZONE",
    //     "status": "Inactive",
    //     "show_oprator_app": false,
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "4",
    //         geofence_name: "Shivranjini Parking Area North Zone",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Parking Zone',
    //         type: 'circle',
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates: { center: { lat: 23.014029654669013, lng: 72.49546530393856 }, radius: 1024.7345856280665 }

    //     }]
    // },
    // {
    //     "name": "Iskcon temple East Zone Parking Area",
    //     "id": "5",
    //     "fleet": "Iskcon temple Public Fleet",
    //     "zone_type": "Parking Zone",
    //     "minimum_vehicles": "10",
    //     "maximum_vehicles": "10",
    //     "desc": "THIS IS Iskcon temple PARKING ZONE",
    //     "status": "Active",
    //     "show_oprator_app": true,
    //     "lat": 23.028318448763603,
    //     "lng": 72.5068473815918,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "5",
    //         geofence_name: "Iskcon temple East Zone Parking Area",
    //         fleet: 'Iskcon temple Public Fleet',
    //         zone: 'Parking Zone',
    //         type: 'polygon',
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates: { path: [{ lat: 23.029016201419076, lng: 72.48756408254849 }, { lat: 23.032333764581647, lng: 72.51193999807583 }, { lat: 23.018510046076383, lng: 72.50447272817837 }], center: { lat: 23.026620004025702, lng: 72.50132560293423 } }

    //     }]
    // },
    {
        "name": "Shyamal Restricted Area",
        "id": "6",
        "fleet": "Shyamal Road Public Fleet",
        "zone_type": "Restricted Zone",
        "desc": "THIS IS SHYAMAL Restricted ZONE",
        "status": "Active",
        "vehicle_exits_this_zone": ["Throttle", "Alarm"],
        "lat": 23.014433014483792,
        "lng": 72.53036370328435,
        "created_date": "Oct 2th, 2024 18:33:45",
        "updated_date": "Oct 5th, 2024 18:33:45",
        "created_by": "User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        "geofenceData": [{
            id: "6",
            geofence_name: "Shyamal Restricted Area",
            fleet: 'Shyamal Road Public Fleet',
            zone: 'Restricted Zone',
            type: 'polygon',
            status: 'Active',
            throttle: 'off',
            alarm: 'on',
            create_date: "Oct 2th, 2024 18:33:45",
            create_by: "Admin",
            updated_date: "Oct 5th, 2024 18:33:45",
            coordinates: {path:[{lat:23.020739630500618,lng:72.47228493304964},{lat:23.027691048321056,lng:72.49159683795686},{lat:23.01157585037714,lng:72.48970856281038}],center:{lat:23.020002176399604,lng:72.48453011127229}}

        }]
    },
    // {
    //     "name": "Ranip Restricted Area",
    //     "id": "7",
    //     "fleet": "New Ranip Private Fleet",
    //     "zone_type": "Restricted Zone",
    //     "vehicle_exits_this_zone": ["Alarm"],
    //     "desc": "THIS IS RANIP Restricted ZONE",
    //     "status": "Inactive",
    //     "lat": 23.080933619335358,
    //     "lng": 72.57671187123493,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User2",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "7",
    //         geofence_name: "Ranip Restricted Area",
    //         fleet: 'New Ranip Private Fleet',
    //         zone: 'Restricted Zone',
    //         type: 'circle',
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:23.07436660970413,lng:72.54000494243087},radius:578.8959438471827}

    //     }]
    // },
    // {
    //     "name": "Shivranjini Restricted Area ",
    //     "id": "8",
    //     "fleet": "Shivranjani Private Fleet",
    //     "vehicle_exits_this_zone": ["Throttle"],
    //     "zone_type": "Restricted Zone",
    //     "desc": "THIS IS IS Shivranjini Restricted ZONE",
    //     "status": "Active",
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "8",
    //         geofence_name: "Shivranjini Restricted Area",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Restricted Zone',
    //         type: 'polygon',
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{path:[{lat:23.005260441705072,lng:72.57786276487606},{lat:23.00842058413746,lng:72.58764746336239},{lat:23.001231152773823,lng:72.58584501890438}],center:{lat:23.004970726205453,lng:72.58378508238094}}

    //     }]
    // },
    // {
    //     "name": "Shivranjini Restricted North Zone",
    //     "id": "9",
    //     "fleet": "Shivranjani Private Fleet",
    //     "vehicle_exits_this_zone": ["Throttle", "Alarm"],
    //     "zone_type": "Restricted Zone",
    //     "desc": "THIS IS Shivranjini  Restricted ZONE",
    //     "status": "Inactive",
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "9",
    //         geofence_name: "Shivranjini Restricted North Zone",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Restricted Zone',
    //         type: "circle",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:22.993251225599504,lng:72.56687643675106},radius:825.1582898456597}

    //     }]
    // },
    // {
    //     "name": "Iskcon temple East Zone Restricted Area",
    //     "id": "10",
    //     "fleet": "Iskcon temple Public Fleet",
    //     "vehicle_exits_this_zone": ["Throttle", "Alarm"],
    //     "zone_type": "Restricted Zone",
    //     "desc": "THIS IS Iskcon temple ZONE",
    //     "status": "Active",
    //     "lat": 23.028318448763603,
    //     "lng": 72.5068473815918,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "10",
    //         geofence_name: "Iskcon temple East Zone Restricted Area",
    //         fleet: 'Iskcon temple Public Fleet',
    //         zone: 'Restricted Zone',
    //         type: "circle",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:23.034545427987613,lng:72.54961967031704},radius:466.10877704183764}
    //     }]
    // },
    {
        "name":"Shyamal Service Area",
        "id":"11",
        "fleet":"Shyamal Road Public Fleet",
        "zone_type":"Service Zone",
        "desc":"THIS IS SHYAMAL Service ZONE",
        "status":"Active",
        "vehicle_exits_this_zone":["Throttle","Alarm"],
        "lat": 23.014433014483792,
        "lng": 72.53036370328435,
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        "geofenceData": [{
            id: "11",
            geofence_name: "Shyamal Service Area",
            fleet: 'Shyamal Road Public Fleet',
            zone: 'Service Zone',
            type: "polygon",
            status: 'Active',
            throttle: 'off',
            alarm: 'on',
            create_date: "Oct 2th, 2024 18:33:45",
            create_by: "Admin",
            updated_date: "Oct 5th, 2024 18:33:45",
            coordinates:{path:[{lat:23.027691048321056,lng:72.53743042560335},{lat:23.029349856417973,lng:72.55133499713655},{lat:23.022951484138776,lng:72.54446854205842}],center:{lat:23.02666412962593,lng:72.54441132159944}}
        }]
    },
    // {
    //     "name":"Ranip Service Area",
    //     "id":"12",
    //     "fleet":"New Ranip Private Fleet",
    //     "zone_type":"Service Zone",
    //     "vehicle_exits_this_zone":["Alarm"],
    //     "desc":"THIS IS RANIP Service ZONE",
    //     "status":"Inactive",
    //     "lat": 23.080933619335358,
    //     "lng": 72.57671187123493,
    //     "created_date":"Oct 2th, 2024 18:33:45",
    //     "updated_date":"Oct 5th, 2024 18:33:45",
    //     "created_by":"User2",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "12",
    //         geofence_name: "Ranip Service Area",
    //         fleet: 'New Ranip Private Fleet',
    //         zone: 'Service Zone',
    //         type: "circle",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:23.08660546867749,lng:72.56987402202071},radius:457.05674266247195}
    //     }]

    // },
    // {
    //     "name":"Shivranjini Service Area ",
    //     "id":"13",
    //     "fleet":"Shivranjani Private Fleet",
    //     "vehicle_exits_this_zone":["Throttle"],
    //     "zone_type":"Service Zone",
    //     "desc":"THIS IS IS Shivranjini Service ZONE",
    //     "status":"Active",
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date":"Oct 2th, 2024 18:33:45",
    //     "updated_date":"Oct 5th, 2024 18:33:45",
    //     "created_by":"User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "13",
    //         geofence_name: "Shivranjini Service Area",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Service Zone',
    //         type: "polygon",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{path:[{lat:22.990643820430318,lng:72.58730414060848},{lat:22.992935179169628,lng:72.56533148435848},{lat:22.99743877095507,lng:72.57760527281063}],center:{lat:22.993672590185003,lng:72.57674696592586}}


    //     }]
    // },
    // {
    //     "name":"Shivranjini North Zone Service Area",
    //     "id":"14",
    //     "fleet":"Shivranjani Private Fleet",
    //     "vehicle_exits_this_zone":["Throttle","Alarm"],
    //     "zone_type":"Service Zone",
    //     "desc":"THIS IS Shivranjini North Service ZONE",
    //     "status":"Inactive",
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date":"Oct 2th, 2024 18:33:45",
    //     "updated_date":"Oct 5th, 2024 18:33:45",
    //     "created_by":"User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "14",
    //         geofence_name: "Shivranjini North Zone Service Area",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Service Zone',
    //         type: "circle",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:23.016636608059663,lng:72.56481650022762},radius:523.6745033765819}
    //     }]
    // },
    // {
    //     "name":"Iskon Temple South Service Area",
    //     "id":"15",
    //     "fleet":"Iskcon temple Public Fleet",
    //     "vehicle_exits_this_zone":["Throttle","Alarm"],
    //     "zone_type":"Service Zone",
    //     "desc":"THIS IS Iskcon temple Service ZONE",
    //     "status":"Active",
    //     "lat": 23.028318448763603,
    //     "lng": 72.5068473815918,
    //     "created_date":"Oct 2th, 2024 18:33:45",
    //     "updated_date":"Oct 5th, 2024 18:33:45",
    //     "created_by":"User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "15",
    //         geofence_name: "Iskon Temple South Service Area",
    //         fleet: 'Iskcon temple Public Fleet',
    //         zone: 'Service Zone',
    //         type: "circle",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:23.01658777788842,lng:72.5043868974899},radius:460.41224560615166}
    //     }]
    // },
    {
        "name": "Shyamal Slow Speed Area",
        "id": "16",
        "fleet": "Shyamal Road Public Fleet",
        "zone_type": "Slow Speed Zone",
        "desc": "THIS IS SHYAMAL Slow Speed ZONE",
        "status": "Active",
        "vehicle_exits_this_zone": ["Throttle", "Alarm"],
        "speed_mode": "Low",
        "lat": 23.014433014483792,
        "lng": 72.53036370328435,
        "created_date": "Oct 2th, 2024 18:33:45",
        "updated_date": "Oct 5th, 2024 18:33:45",
        "created_by": "User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        "geofenceData": [{
            id: "16",
            geofence_name: "Shyamal Slow Speed Area",
            fleet: 'Shyamal Road Public Fleet',
            zone: 'Slow Speed Zone',
            type: "polygon",
            status: 'Active',
            throttle: 'off',
            alarm: 'on',
            create_date: "Oct 2th, 2024 18:33:45",
            create_by: "Admin",
            updated_date: "Oct 5th, 2024 18:33:45",
            coordinates:{path:[{lat:22.99948818838987,lng:72.47846474261995},{lat:23.008099770921593,lng:72.49056686969514},{lat:22.99893513220265,lng:72.49709000201936}],center:{lat:23.002174363838037,lng:72.48870720477815}}
        }]
    },
    // {
    //     "name": "Ranip Slow Speed Area",
    //     "id": "17",
    //     "fleet": "New Ranip Private Fleet",
    //     "zone_type": "Slow Speed Zone",
    //     "speed_mode": "Medium",
    //     "vehicle_exits_this_zone": ["Alarm"],
    //     "desc": "THIS IS RANIP Slow Speed ZONE",
    //     "status": "Inactive",
    //     "lat": 23.080933619335358,
    //     "lng": 72.57671187123493,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User2",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "17",
    //         geofence_name: "Ranip Slow Speed Area",
    //         fleet: 'New Ranip Private Fleet',
    //         zone: 'Slow Speed Zone',
    //         type: "polygon",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:23.072392495965296,lng:72.58832762004317},radius:912.1910587182123}
    //     }]
    // },
    // {
    //     "name": "Shivranjini Slow Speed Area ",
    //     "id": "18",
    //     "speed_mode": "High",
    //     "fleet": "Shivranjani Private Fleet",
    //     "vehicle_exits_this_zone": ["Throttle"],
    //     "zone_type": "Slow Speed Zone",
    //     "desc": "THIS IS IS Shivranjini Slow Speed ZONE",
    //     "status": "Active",
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "18",
    //         geofence_name: "Shivranjini Slow Speed Area ",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Slow Speed Zone',
    //         type: "polygon",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{path:[{lat:23.00304829796871,lng:72.56430151609676},{lat:23.01442465084579,lng:72.56739142088192},{lat:23.00707753264674,lng:72.55262854246395}],center:{lat:23.00818349382041,lng:72.56144049314754}}
    //     }]
    // },
    // {
    //     "name": "Shivranjini East Slow Speed Area",
    //     "id": "19",
    //     "fleet": "Shivranjani Private Fleet",
    //     "speed_mode": "Low",
    //     "vehicle_exits_this_zone": ["Throttle", "Alarm"],
    //     "zone_type": "Slow Speed Zone",
    //     "desc": "THIS IS Gujarat University Slow Speed ZONE",
    //     "status": "Inactive",
    //     "lat": 23.006221658723216,
    //     "lng": 72.53303053962738,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "19",
    //         geofence_name: "Shivranjini East Slow Speed Area",
    //         fleet: 'Shivranjani Private Fleet',
    //         zone: 'Slow Speed Zone',
    //         type: "polygon",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:22.99649065885303,lng:72.58584501890438},radius:762.830655691426}
    //     }]
    // },
    // {
    //     "name": "Iskcon temple North Slow Speed Area",
    //     "id": "20",
    //     "fleet": "Iskcon temple Public Fleet",
    //     "speed_mode": "High",
    //     "vehicle_exits_this_zone": ["Throttle", "Alarm"],
    //     "zone_type": "Slow Speed Zone",
    //     "desc": "THIS IS Nikol Slow Speed ZONE",
    //     "status": "Active",
    //     "lat": 23.028318448763603,
    //     "lng": 72.5068473815918,
    //     "created_date": "Oct 2th, 2024 18:33:45",
    //     "updated_date": "Oct 5th, 2024 18:33:45",
    //     "created_by": "User1",
    //     "activity": [
    //         {
    //             user: "9",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 5th, 2024 18:33:45"
    //         },
    //         {
    //             user: "8",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 4th, 2024 18:33:45"
    //         },
    //         {
    //             user: "7",
    //             userRole: "Admin",
    //             type: "update",
    //             time: "Oct 3th, 2024 18:33:45"
    //         },
    //         {
    //             user: "1",
    //             userRole: "Super Admin",
    //             type: "create",
    //             time: "Oct 2th, 2024 18:33:45"
    //         },
    //     ],
    //     "geofenceData": [{
    //         id: "20",
    //         geofence_name: "Iskcon temple North Slow Speed Area",
    //         fleet: 'Iskcon temple Public Fleet',
    //         zone: 'Slow Speed Zone',
    //         type: "polygon",
    //         status: 'Active',
    //         throttle: 'off',
    //         alarm: 'on',
    //         create_date: "Oct 2th, 2024 18:33:45",
    //         create_by: "Admin",
    //         updated_date: "Oct 5th, 2024 18:33:45",
    //         coordinates:{center:{lat:23.01408615682262,lng:72.46971129934536},radius:659.0807464797773}
    //     }]
    // },
]
import { Box, Typography, Paper, Grid, InputLabel, Select, MenuItem, FormControl, FormHelperText } from "@mui/material"
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import { useEffect, useState } from "react"
import ApplePay from "../../../assets/Icons/ApplePay.svg"
import GooglePay from "../../../assets/Icons/GooglePay.svg"
import IDealPay from "../../../assets/Icons/IdealPay.svg"
import BanContact from "../../../assets/Icons/BanContact.svg"
import IOSSwitch from "../../../components/Switch/IOSswitch"
import FilledButton from "../../../components/MainButtons/FilledButton"
import OutLinedButton from "../../../components/MainButtons/OutLinedButton"
import { createPaymentGateway } from "../../../slices/paymentgatewaySlice"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbar } from "../../../hooks/Snackbar"


const CreatePaymentGateway = () => {
    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // console.log(formData)

    const [formData, setFormData] = useState({
        uuid: `Uuid ${Math.floor(Math.random() * (1000000 - 10 + 1)) + 10}`,
        name: "",
        currencey: "Select Currencey",
        publish_key: "",
        secret_key: "",
        client_id: "",
        cvv_requirement: "Select CVV Requirement",
        payment_methods: [
            {
                name: "Apple Pay",
                support: false
            },
            {
                name: "Google Pay",
                support: false
            },
            {
                "name": "iDEAL",
                support: false
            },
            {
                name: "Bancontact",
                support: false

            }
        ],
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]

    })

    const showSnackbar=useSnackbar()
    const [errors, setErrors] = useState({
        name: false,
        currencey: false,
        publish_key: false,
        secret_key: false,
        client_id: false,
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
        }));
    }
    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };
    const handleSubmit = () => {

        if (validateForm()) {
            dispatch(createPaymentGateway(formData))
            navigate("/dashboard/payments/payment-gateway")
            showSnackbar("Created payment gateway successfully","success")
        } else {
            console.log(errors, "Validation failed");
        }
    };

    useEffect(()=>{
        if(formData.cvv_requirement=="Select CVV Requirement"){
            setFormData((prevFormData) => ({
                ...prevFormData,
                cvv_requirement: "No",
            }));
        }
    },[validateForm])

    const handleSwitchChange = (index) => {
        setFormData(prevState => {
            const updatedMethods = prevState.payment_methods.map((method, i) =>
                i === index ? { ...method, support: !method.support } : method
            );
            return { ...prevState, payment_methods: updatedMethods };
        });
    };
    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    mb: 2,
                }}>

                <Box>
                    <Typography
                        sx={{
                            color: "#283246",
                            fontSize: "24px",
                            fontWeight: "600"
                        }}
                    >Add Payment Gateway </Typography>
                </Box>
        
                <Box>
                    <Grid container spacing={2}>
                        {/* Row 1 */}
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Name <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder={"Enter Payment Gateway Name"}
                                error={errors.name}
                                helperText={errors.name ? "Name is required" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Currency  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <FormControl error={errors.currencey} >
                                <Select
                                    value={formData.currencey}
                                    defaultValue={formData.currencey}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px',
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#283246",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#283246",
                                            fontSize: "14px",
                                            fontWeight: 500, 
                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                        },


                                    }}
                                    name="currencey"

                                >
                                    <MenuItem value={"Select Currencey"}>Select Currencey</MenuItem>
                                    <MenuItem value="IND">IND</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                </Select>
                                {errors.currencey && (
                                    <FormHelperText>
                                        Currencey is required
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Publishable Key  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.publish_key}
                                type="password"
                                onChange={handleInputChange}
                                name="publish_key"
                                placeholder={"Enter Publish Key"}
                                error={errors.publish_key}
                                helperText={errors.publish_key ? "Publish Key is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Secret Key  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.secret_key}
                                type="password"
                                onChange={handleInputChange}
                                name="secret_key"
                                placeholder={"Enter Secret Key"}
                                error={errors.secret_key}
                                helperText={errors.secret_key ? "Secret Key is required" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Client ID  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.client_id}
                                type="password"
                                onChange={handleInputChange}
                                name="client_id"
                                placeholder={"Enter Client ID Key"}
                                error={errors.client_id}
                                helperText={errors.client_id ? "Client id is required" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                CVV required for transaction
                            </InputLabel>
                            <Select
                                value={formData.cvv_requirement}
                                defaultValue={formData.cvv_requirement}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px',
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#283246",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "& .MuiSelect-select": {
                                        color: "#283246",
                                        fontSize: "14px",
                                        fontWeight: 500, 
                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                    },


                                }}
                                name="cvv_requirement"

                            >
                                <MenuItem value={"Select CVV Requirement"}>Select CVV Requirement</MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 5,
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    mb: 2,
                }}>

                <Box>
                    <Typography
                        sx={{
                            color: "#283246",
                            fontSize: "24px",
                            fontWeight: "600"
                        }}
                    >Supported payment methods</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: "space-between"
                    }}
                >
                    {
                        formData.payment_methods.map((method, key) => {
                            return (
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "25px", bgcolor: "#FFFFFF", gap: "40px", borderRadius: "15px" }} >
                                    <Box>
                                        {method.name.includes("Apple") ? (<img src={ApplePay} />) : (<></>)}
                                        {method.name.includes("Google") ? (<img src={GooglePay} />) : (<></>)}
                                        {method.name.includes("iDEAL") ? (<img src={IDealPay} />) : (<></>)}
                                        {method.name.includes("Bancontact") ? (<img src={BanContact} />) : (<></>)}
                                    </Box>
                                    <Box>
                                        <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600" }} >{method.name}</Typography>
                                    </Box>
                                    <Box>
                                        <IOSSwitch onChange={() => handleSwitchChange(key)} checked={method.support} />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
                <Box sx={{ display: "flex", gap: "20px" }} >

                    <Box>
                        <FilledButton
                            onClick={() => handleSubmit()}

                            sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                            Save
                        </FilledButton>
                    </Box>
                    <Box>
                        <FilledButton
                            onClick={() => { navigate("/dashboard/payments/payment-gateway") }}
                            sx={{
                                border: `1px solid #97A1B6`,
                                width: "150px",
                                color: "black",
                                background: "white",
                                boxShadow: 'none',
                                "&:hover": {
                                  background: "white",
                                },
                            }} >
                            Cancel
                        </FilledButton>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default CreatePaymentGateway
import { InputLabel, Select, MenuItem, FormControl, FormHelperText, Box } from "@mui/material"
import { FormMenuItem } from "../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from "react-redux";


const inputLabel = {
    fontSize: "12px",
    fontWeight: 700,
    mb: 1,
    color: "#283246"
}

const FleetSelection = ({ value, defaultValue, handleFleetChange, error }) => {

    const { fleetData } = useSelector((state) => state.fleetManage)
    const fleetOptions = fleetData.map((fleet) => fleet.fleet_name)
    return (
        <Box>
            <InputLabel sx={inputLabel}>
                Fleet  <span style={{ color: "#F99494" }}>*</span>
            </InputLabel>
            <FormControl error={error} >
                <Select
                    value={value}
                    IconComponent={KeyboardArrowDownIcon}
                    defaultValue={defaultValue}
                    onChange={handleFleetChange}
                    error={error}
                    name="fleet"
                    sx={{
                        borderRadius: "10px",
                        width: {
                            xs: "100px",
                            sm: "300px",
                            md: "400px",
                            lg: "300px",
                            xl: "400px"
                        },
                        height: '40px',
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#283246",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1",
                        },
                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1",
                        },
                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1",
                        },
                        "& .MuiSelect-select": {
                            color: "#283246",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                        },


                    }}
                >
                    <MenuItem sx={FormMenuItem} value={"Select Fleet"}>Select Fleet</MenuItem>
                    {
                        fleetOptions.map((fleet, key) => {
                            return (
                                <MenuItem sx={FormMenuItem} value={fleet}>{fleet}</MenuItem>
                            )
                        })
                    }
                </Select>
                {error && (
                    <FormHelperText>
                        Fleet is required
                    </FormHelperText>
                )}

            </FormControl>
        </Box>
    )
}

export default FleetSelection
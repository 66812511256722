import { Typography, Box, IconButton, Paper, Tab, Tabs, Grid, MenuItem, InputLabel, Select, Chip, Input, FormControl, FormHelperText } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CustomTextField from "../../../components/MainTextField/CustomTextField";
import FilledButton from "../../../components/MainButtons/FilledButton";
import OutLinedButton from "../../../components/MainButtons/OutLinedButton";
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useState } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LinkTimeline from "../../../assets/Icons/LinkTimeLine.svg"
import { useDispatch } from "react-redux";
import { updateBillingPlan } from "../../../slices/billingplanSlice";
import { useSnackbar } from "../../../hooks/Snackbar";



const tabStyles = {
    width: '191px',
    height: '40px',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#F99494',
    '&.Mui-selected': {
        background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
        color: '#FFFFFF',
        borderRadius: '12px',
    },
};
const EditBillingPlan = () => {

    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }
    const showSnackbar=useSnackbar()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { data } = location.state || {}
    console.log("this is data", data)

    const [formData, setFormData] = useState({
        uuid: data.uuid,
        name: data.name,
        desc: data.desc,
        status: data.status,
        billing_unit_minutes: data.billing_unit_minutes,
        first_unit_fee: data.first_unit_fee,
        next_unit_fee: data.next_unit_fee,
        pause_trip_fee_same: data.pause_trip_fee_same,
        reservation_allowed: data.reservation_allowed,
        card_mandatory: data.card_mandatory,
        max_billing_price_per_trip: data.max_billing_price_per_trip,
        tax: data.tax,
        display_price_tex_include: data.display_price_tex_include,
        created_date: data.created_date,
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    })

    const [errors, setErrors] = useState({
        name: false,
        desc: false,
        status: false,
        billing_unit_minutes: false,
        first_unit_fee: false,
        reservation_allowed: false,
        card_mandatory: false,
        next_unit_fee: false,
        max_billing_price_per_trip: false,
        display_price_tex_include: false

    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
        }));
    }
    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };
    const handleSubmit = () => {

        if (validateForm()) {
            dispatch(updateBillingPlan(formData))
            navigate("/dashboard/payments/billing-plan")
            showSnackbar("Edited billing plan successfully","success")
        } else {
            console.log(errors, "Validation failed");
        }
    };

    useEffect(() => {
        if (formData.pause_trip_fee_same == "Select Pause Trip Fee") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                pause_trip_fee_same: "No",
            }));
        }
    }, [validateForm])

    const handleChipDelete = (chipToDelete) => () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            tax: prevFormData.tax.filter((chip) => chip !== chipToDelete),
        }));
    };
    console.log(formData)
    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}>

                <Box

                >
                    <Box sx={{ display: "flex", flexDirection: 'column', gap: "20px" }} >
                        <Box sx={{
                            display: "flex",
                            alignItems: "center"
                        }} >

                            <Box>
                                <Typography
                                    sx={{
                                        color: "#283246",
                                        fontSize: "24px",
                                        fontWeight: "600"
                                    }}
                                >
                                    {data.name}
                                </Typography>
                            </Box>
                        </Box>

                        <Box>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="Active Users"
                                sx={{
                                    bgcolor: '#F8F8F8',
                                    width: "fit-content",
                                    borderRadius: '12px',
                                    border: '1px solid #DDDEE1',
                                    '& .MuiTabs-indicator': {
                                        display: 'none',
                                    },
                                }}
                            >
                                {['Billing Plan', 'Timeline'].map((label, index) => (
                                    <Tab
                                        key={label}
                                        disableRipple
                                        label={label}
                                        sx={tabStyles}
                                    />
                                ))}
                            </Tabs>
                        </Box>

                        <Box>
                            {
                                value == 0 ? (
                                    <Box>
                                        <Grid container spacing={2}>
                                            {/* Row 1 */}
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Name <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    name="name"
                                                    placeholder={"Enter Plan Name"}
                                                    error={errors.name}
                                                    helperText={errors.name ? "Name is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Description <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.desc}
                                                    onChange={handleInputChange}
                                                    name="desc"
                                                    placeholder={"Enter Description"}
                                                    error={errors.desc}
                                                    helperText={errors.desc ? "Description is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Status  <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <FormControl error={errors.status} >
                                                    <Select
                                                        value={formData.status}
                                                        defaultValue={formData.status}
                                                        onChange={handleInputChange}
                                                        sx={{
                                                            borderRadius: "10px",
                                                            width: {
                                                                xs: "100px",
                                                                sm: "300px",
                                                                md: "400px",
                                                                lg: "300px",
                                                                xl: "400px"
                                                            },
                                                            height: '40px',
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#283246",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#283246",
                                                                fontSize: "14px",
                                                                fontWeight: 500, 
                                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                            },
            
            
                                                        }}
                                                        name="status"
                                                    >
                                                        <MenuItem value={"Select Plan Status"}>Select Plan Status</MenuItem>
                                                        <MenuItem value="Inactive">
                                                            <Box>
                                                                <Chip
                                                                    label="Inactive"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                                                                    sx={{
                                                                        color: "#F99494",
                                                                        background: "#FFF3F3",
                                                                        borderColor: "#F99494",
                                                                        fontSize: "12px"
                                                                    }}
                                                                />
                                                            </Box>
                                                        </MenuItem>
                                                        <MenuItem value="Active">
                                                            <Box>
                                                                <Chip
                                                                    label="Active"
                                                                    variant="outlined"
                                                                    icon={<CircleIcon color="#83C3A0" />}
                                                                    size="small"
                                                                    sx={{
                                                                        color: "#83C3A0",
                                                                        background: "#F1F7F3",
                                                                        borderColor: "#83C3A0",
                                                                        fontSize: "12px"
                                                                    }}
                                                                />
                                                            </Box>
                                                        </MenuItem>
                                                    </Select>
                                                    {errors.status && (
                                                        <FormHelperText>
                                                            Status is required
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Billing unit in minutes  <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.billing_unit_minutes}
                                                    onChange={handleInputChange}
                                                    name="billing_unit_minutes"
                                                    placeholder={"Enter Billing Plan Units"}
                                                    error={errors.billing_unit_minutes}
                                                    helperText={errors.billing_unit_minutes ? "Unit Minute Billing is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    First unit fee  <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.first_unit_fee}
                                                    onChange={handleInputChange}
                                                    name="first_unit_fee"
                                                    placeholder={"Enter First Unit Fee"}
                                                    error={errors.first_unit_fee}
                                                    helperText={errors.first_unit_fee ? "First unit fee is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Pause trip fee same as above?
                                                </InputLabel>
                                                <Select
                                                    value={formData.pause_trip_fee_same}
                                                    defaultValue={formData.pause_trip_fee_same}
                                                    onChange={handleInputChange}
                                                    sx={{
                                                        borderRadius: "10px",
                                                        width: {
                                                            xs: "100px",
                                                            sm: "300px",
                                                            md: "400px",
                                                            lg: "300px",
                                                            xl: "400px"
                                                        },
                                                        height: '40px',
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        color: "#283246",
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "#DDDEE1", 
                                                        },
                                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "#DDDEE1", 
                                                        },
                                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "#DDDEE1", 
                                                        },
                                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "#DDDEE1", 
                                                        },
                                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "#DDDEE1", 
                                                        },
                                                        "& .MuiSelect-select": {
                                                            color: "#283246",
                                                            fontSize: "14px",
                                                            fontWeight: 500, 
                                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                        },
        
        
                                                    }}
                                                    name="pause_trip_fee_same"
                                                >
                                                    <MenuItem value={"Select Pause Trip Fee"}>Select Pause Trip Fee</MenuItem>
                                                    <MenuItem value="Yes">Yes</MenuItem>
                                                    <MenuItem value="No">No</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Is reservation allowed  <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <FormControl error={errors.reservation_allowed} >
                                                    <Select
                                                        value={formData.reservation_allowed}
                                                        defaultValue={formData.reservation_allowed}
                                                        onChange={handleInputChange}
                                                        sx={{
                                                            borderRadius: "10px",
                                                            width: {
                                                                xs: "100px",
                                                                sm: "300px",
                                                                md: "400px",
                                                                lg: "300px",
                                                                xl: "400px"
                                                            },
                                                            height: '40px',
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#283246",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#283246",
                                                                fontSize: "14px",
                                                                fontWeight: 500, 
                                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                            },
            
            
                                                        }}
                                                        name="reservation_allowed"
                                                    >
                                                        <MenuItem value={"Select Reservation"}>Select Reservation</MenuItem>
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>
                                                    </Select>
                                                    {errors.reservation_allowed && (
                                                        <FormHelperText>
                                                            Required filed
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Card mandatory   <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <FormControl error={errors.card_mandatory} >
                                                    <Select
                                                        value={formData.card_mandatory}
                                                        defaultValue={formData.card_mandatory}
                                                        onChange={handleInputChange}
                                                        sx={{
                                                            borderRadius: "10px",
                                                            width: {
                                                                xs: "100px",
                                                                sm: "300px",
                                                                md: "400px",
                                                                lg: "300px",
                                                                xl: "400px"
                                                            },
                                                            height: '40px',
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#283246",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#283246",
                                                                fontSize: "14px",
                                                                fontWeight: 500, 
                                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                            },
            
            
                                                        }}
                                                        name="card_mandatory"
                                                    >
                                                        <MenuItem value={"Select Card Requirment"}>Select Card Requirmentss</MenuItem>
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>
                                                    </Select>
                                                    {errors.card_mandatory && (
                                                        <FormHelperText>
                                                            Required filed
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Next unit fee  <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.next_unit_fee}
                                                    onChange={handleInputChange}
                                                    name="next_unit_fee"
                                                    placeholder={"Enter Next Unit Fee"}
                                                    error={errors.next_unit_fee}
                                                    helperText={errors.next_unit_fee ? "Next unit fee is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Max billing price per trip <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.max_billing_price_per_trip}
                                                    onChange={handleInputChange}
                                                    name="max_billing_price_per_trip"
                                                    placeholder={"Enter Max Billing Price For Per Trip "}
                                                    error={errors.max_billing_price_per_trip}
                                                    helperText={errors.max_billing_price_per_trip ? "Max billing price fee is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Tax  <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <Select
                                                    multiple
                                                    value={formData.tax}
                                                    onChange={handleInputChange}
                                                    input={<Input id="select-multiple-chip" />}
                                                    name="tax"
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip
                                                                    key={value}
                                                                    label={value}
                                                                    onDelete={handleChipDelete(value)}
                                                                    onMouseDown={(event) => event.stopPropagation()}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}
                                                >
                                                    {['GST', 'SGST', 'CGST', 'VAT', 'Service Tax'].map((tax) => (
                                                        <MenuItem key={tax} value={tax}>
                                                            {tax}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Display price with tax included <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <FormControl error={errors.display_price_tex_include} >
                                                    <Select
                                                        value={formData.display_price_tex_include}
                                                        defaultValue={formData.display_price_tex_include}
                                                        onChange={handleInputChange}
                                                        sx={{
                                                            borderRadius: "10px",
                                                            width: {
                                                                xs: "100px",
                                                                sm: "300px",
                                                                md: "400px",
                                                                lg: "300px",
                                                                xl: "400px"
                                                            },
                                                            height: '40px',
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#283246",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1", 
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#283246",
                                                                fontSize: "14px",
                                                                fontWeight: 500, 
                                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                            },
            
            
                                                        }}
                                                        name="display_price_tex_include"
                                                    >
                                                        <MenuItem value={"Select Tex Price Include"}>Select Tex Price Include</MenuItem>
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>
                                                    </Select>
                                                    {errors.display_price_tex_include && (
                                                <FormHelperText>
                                                   Required filed
                                                </FormHelperText>
                                            )}
                                                </FormControl>
                                            </Grid>
                                            <Box>
                                                <Box sx={{ display: "flex", gap: "10px", margin: "20px" }} >

                                                    <Box
                                                        onClick={() => handleSubmit()}

                                                    >
                                                        <FilledButton sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                                            Save
                                                        </FilledButton>
                                                    </Box>
                                                    <Box
                                                        onClick={() => {

                                                            navigate("/dashboard/payments/billing-plan")
                                                        }}

                                                    >
                                                        <FilledButton
                                                           sx={{
                                                            border: `1px solid #97A1B6`,
                                                            width: "150px",
                                                            color: "black",
                                                            background: "white",
                                                            boxShadow: 'none',
                                                            "&:hover": {
                                                              background: "white",
                                                            },
                                                          }} >
                                                            Cancel
                                                        </FilledButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Box>
                                ) : (<></>)
                            }

                            {
                                value == 1 ? (<Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px"
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: "#283246",
                                                fontSize: "24px",
                                                fontWeight: "600"
                                            }}
                                        >
                                            {data.name}

                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "18px",
                                                color: "#28324680",
                                                textAlign: "center",
                                                paddingInline: "150px",
                                                fontWeight: "500"
                                            }}
                                        >
                                            This is timeline for Billing Plan here you can see activity that done with Billing Plan like create and update with time.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }} >
                                        <Timeline position="alternate" >
                                            {
                                                data.activity.map((activity, key) => {
                                                    return (
                                                        <TimelineItem >
                                                            <TimelineSeparator
                                                                sx={{ paddingInline: key % 2 !== 0 ? "140px" : "5px" }}
                                                            >
                                                                <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                                                <TimelineConnector sx={{ background: "#97A1B6", width: "5px" }} />
                                                            </TimelineSeparator>
                                                            <TimelineContent

                                                            >
                                                                <Box
                                                                    sx={{
                                                                        width: key % 2 !== 0 ? "135%" : "93%",
                                                                        background: "#ffff",
                                                                        display: "flex",
                                                                        paddingBlock: "20px",
                                                                        flexDirection: "row",
                                                                        gap: {
                                                                            lg: "20px",
                                                                            xl: "33px"
                                                                        },
                                                                        paddingInline: "20px",
                                                                        borderRadius: "20px"
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: "#F994941A",

                                                                            width: "fit-content",
                                                                            padding: {
                                                                                lg: "18px",
                                                                                xl: "20px"
                                                                            },
                                                                            borderRadius: "60px",

                                                                        }}

                                                                    >
                                                                        <img src={LinkTimeline} height={"30px"} width={"30px"} />
                                                                    </Box>
                                                                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }} >
                                                                        <Box>
                                                                            <Typography sx={{

                                                                                color: "#283246",
                                                                                fontSize: {
                                                                                    lg: "14px",
                                                                                    xl: "16px"
                                                                                },
                                                                                fontWeight: "600"

                                                                            }} >
                                                                                User ID : {activity.user} ({activity.userRole}) {activity.type == "update" ? ("updated") : ("created")} this Billing Plan.
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <Typography sx={{
                                                                                fontSize: "14px",
                                                                                color: '#97A1B6'

                                                                            }} >
                                                                                This Billing Plan  {activity.type == "update" ? ("updated") : ("created")}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>

                                                                    <Box>
                                                                        <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                            {activity.time.split(" ")[0]}
                                                                        </Typography>
                                                                        <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600", textAlign: "center" }}>
                                                                            {activity.time.split(" ")[1].replace("th,", "")}
                                                                        </Typography>
                                                                        <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                            {activity.time.split(" ")[2]}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </TimelineContent>
                                                        </TimelineItem>
                                                    )
                                                })
                                            }


                                        </Timeline>
                                    </Box>
                                </Box>) : (<></>)
                            }
                        </Box>
                    </Box>
                </Box>
            </Paper>

        </Box>
    )
}

export default EditBillingPlan
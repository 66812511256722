import { Box, IconButton, Typography } from "@mui/material"
import AnalyticsIcon from '@mui/icons-material/Analytics';
import MapIcon from '@mui/icons-material/Map';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import PersonIcon from '@mui/icons-material/Person';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsIcon from '@mui/icons-material/Settings';

const Notification=({type,title,desc,date})=>{
    return(
        <Box 
            sx={{
                display:'flex',
                alignItems:"center",
                background:"#F8F8F8",
                padding:{lg:"10px",xl:"20px"},
                gap:"20px",
                borderRadius:"10px",
                "&:hover":{
                    cursor:"pointer"
                }
            }}
        >
            
            <Box
                sx={{
                    background:"white",
                    padding:{lg:"5px",xl:"10px"}
                }}
            >
                    <IconButton>
                        {type=="analytics"?(<AnalyticsIcon/>):(<></>)}
                        {type=="map"?(<MapIcon  />):(<></>)}
                        {type=="vehicles"?(<BikeScooterIcon/>):(<></>)}
                        {type=="users"?(<PersonIcon/>):(<></>)}
                        {type=="trips"?(<TripOriginIcon/>):(<></>)}
                        {type=="fleet"?(<RunningWithErrorsIcon/>):(<></>)}
                        {type=="geofence"?(<AddLocationAltIcon/>):(<></>)}
                        {type=="payment"?(<PaymentsIcon/>):(<></>)}
                        {type=="settings"?(<SettingsIcon/>):(<></>)}
                    </IconButton>
            </Box>
            <Box sx={{flex:1}} >
                <Box>
                    <Typography sx={{ fontSize:{xl:"20px",lg:"14px"},fontWeight:"600",color:'#283246'  }} >{title}</Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize:{xl:"16px",lg:"12px"},fontWeight:"500",color:'#283246'}}  >{desc}</Typography>
                </Box>
            </Box>
            <Box>
                <Typography sx={{fontSize:{xl:"17px",lg:"12px"},color:'#283246'}} >{date}</Typography>
            </Box>
           
        </Box>
    )
}

export default Notification;
export const MenuItemStyle = {
    fontSize: "16px", 
    fontWeight: "500", 
    color: "#283246",
    "&.Mui-focused": {
        backgroundColor: "white", // Prevent background change on focus
      },
    "&:hover": {
        backgroundColor: "white", // Prevent background change on hover
    },
   
}

export const FormMenuItem={
    fontSize: "14px", 
    fontWeight: "500", 
    color: "#283246",
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
}

export const MenuPlaceholder={
     color: "#283246", // Placeholder color
      fontSize: "14px", // Placeholder font size
      fontWeight: 500, // Placeholder font weight
      fontFamily: '"Helvetica Neue", Arial, sans-serif',
}
import { Paper, Box, Typography, InputLabel, Select, MenuItem, Chip, Grid, FormControl, FormHelperText } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
import CustomTextField from "../../../components/MainTextField/CustomTextField";
import CircleIcon from '@mui/icons-material/Circle';
import FilledButton from "../../../components/MainButtons/FilledButton";
import OutLinedButton from "../../../components/MainButtons/OutLinedButton";
import { MainMap } from "../../../components/MainMap/MainMap";
import { useDispatch } from "react-redux";
import { createFleet } from "../../../slices/fleetSlice";
import getCurrentTimeFormatted from "../../../utils/TimeFormat";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useSnackbar} from "../../../hooks/Snackbar"


const CreateFleet = () => {
    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }
    const navigate = useNavigate()
    const showSnackbar=useSnackbar()
    const dispatch = useDispatch()
    const [markerPosition, setMarkerPosition] = useState(null);
  
    const [formData, setFormData] = useState({
        fleet_id: Math.floor(Math.random() * (1000000 - 10 + 1)) + 10,
        fleet_name: "",
        support_email: "",
        alert_email: "",
        fleet_type: "Select Fleet Type",
        billing_plan: "Select Billing Plan",
        address: "",
        lat: "",
        lng: "",
        support_phone_number: "",
        parking_requirment: "Select Parking Requirment",
        auto_end_ride_critical_battery_vehicle: "Select Auto End Ride Requirment",
        distance_lock_unlock_vehicle: "Select Distance",
        auto_end_ride_ideal_vehicle: "Select Auto End Ride Requirment",
        low_battery_level: "Select Low battery level",
        pause_ride: "Select Ride Pause",
        auto_end_ride_paused_vehicle: "Select Auto End Ride Requirment",
        auto_close_vehicle: "Select Auto close vehicle",
        location: "Shyamal Cross Road",
        created_date: getCurrentTimeFormatted(),
        total_vehicle: "0", total_trips: "0", today_trips: "04", revenue_generated: "0$", 
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]

    })
    const handleLocationSelected = (location) => {
        // Update the marker position state with the selected lat/lng
        setMarkerPosition(location);
        console.log("Selected Location:", location);  // Logs the latitude and longitude

        setFormData({...formData,lat:location.lat,lng:location.lng})
    };


    const [errors, setErrors] = useState({
        fleet_name: false,
        support_email: false,
        alert_email: false,
        fleet_type: false,
        billing_plan: false,
        address: false,
        lat: false,
        lng: false,
        parking_requirment: false,
        support_phone_number: false,
        auto_end_ride_paused_vehicle: false,
        auto_end_ride_critical_battery_vehicle: false,
        low_battery_level: false,
        distance_lock_unlock_vehicle: false

    })

   


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
        }));
    };


    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };

    useEffect(() => {
        if (formData.parking_requirment === "Select Parking Requirment") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                parking_requirment: "Not Required",
            }));
        }

        if (formData.auto_end_ride_ideal_vehicle === "Select Auto End Ride Requirment") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                auto_end_ride_ideal_vehicle: "Not Required",
            }));
        }

        if (formData.auto_close_vehicle === "Select Auto close vehicle") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                auto_close_vehicle: "No",
            }));
        }

        if (formData.pause_ride === "Select Ride Pause") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                pause_ride: "No",
            }));
        }
    }, [validateForm])

    const handleSubmit = () => {

        if (validateForm()) {
            dispatch(createFleet(formData))
            navigate("/dashboard/fleet")
            showSnackbar("Added fleet successfully", "success");
        } else {
            console.log(errors, "Validation failed");
        }
    };




    return (
        <>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                    display: "flex",
                    flexDirection: 'column',
                    gap: "20px"
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>

                    <Box>
                        <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }}>
                            Create Fleet
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "20px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Name <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.fleet_name}
                                onChange={handleInputChange}
                                name="fleet_name"
                                error={errors.fleet_name}
                                helperText={errors.fleet_name ? "Fleet Name is required" : ""}
                                placeholder={"Enter Fleet Name"}
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Support Email <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.support_email}
                                onChange={handleInputChange}
                                name="support_email"
                                error={errors.support_email}
                                helperText={errors.support_email ? "Support Email is required" : ""}
                                placeholder={"Enter Support Email"}
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Alert Email <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.alert_email}
                                onChange={handleInputChange}
                                name="alert_email"
                                error={errors.alert_email}
                                helperText={errors.alert_email ? "Alert Email is required" : ""}
                                placeholder={"Enter Alert Email"}
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Fleet Type <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <FormControl error={errors.fleet_type}  >
                                <Select
                                    value={formData.fleet_type}
                                    defaultValue={formData.fleet_type}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px',
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#283246",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#283246",
                                            fontSize: "14px",
                                            fontWeight: 500, 
                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                        },


                                    }}
                                    name="fleet_type"

                                >
                                    <MenuItem value={"Select Fleet Type"}>Select Fleet Type</MenuItem>
                                    <MenuItem value="Private">Private</MenuItem>
                                    <MenuItem value="Public">Public</MenuItem>
                                </Select>
                                {errors.fleet_type && (
                                    <FormHelperText>
                                        Fleet type is required
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        <Box>
                            <InputLabel sx={inputLabel}>
                                Billing Plan <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <FormControl error={errors.billing_plan} >
                                <Select
                                    value={formData.billing_plan}
                                    defaultValue={formData.billing_plan}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px',
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#283246",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#283246",
                                            fontSize: "14px",
                                            fontWeight: 500, 
                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                        },


                                    }}
                                    name="billing_plan"

                                >
                                    <MenuItem value={"Select Billing Plan"}>Select Billing Type</MenuItem>
                                    <MenuItem value="Private">
                                        <Box>
                                            <Chip
                                                label="Private Billing Plan"
                                                variant="outlined"
                                                size="small"
                                                icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                                                sx={{
                                                    color: "#F99494",
                                                    background: "#FFF3F3",
                                                    borderColor: "#F99494",
                                                    fontSize: "12px"
                                                }}
                                            />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="Public">
                                        <Box>
                                            <Chip
                                                label="Public Billing Plan"
                                                variant="outlined"
                                                icon={<CircleIcon color="#83C3A0" />}
                                                size="small"
                                                sx={{
                                                    color: "#83C3A0",
                                                    background: "#F1F7F3",
                                                    borderColor: "#83C3A0",
                                                    fontSize: "12px"
                                                }}
                                            />
                                        </Box>
                                    </MenuItem>
                                </Select>
                                {errors.billing_plan && (
                                    <FormHelperText>
                                        Billing Plan is required
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box>
                            <Box>
                                <InputLabel sx={inputLabel}>
                                    Address <span style={{ color: "#F99494" }}>*</span>
                                </InputLabel>
                                <CustomTextField
                                    value={formData.address}
                                    
                                    onChange={handleInputChange}
                                    name="address"
                                    multiline={true}
                                    rows={4}
                                    sx={{ "& .MuiOutlinedInput-root": { height: "100px",width:"auto" } }}
                                    placeholder={"Enter Fleet Address"}
                                    error={errors.address}
                                    helperText={errors.address ? "Addess is required" : ""}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }} >
                        <MainMap
                            largeHeight={"550px"}
                            extraLargeHeight={"550px"}
                            center={{ lat: 23.0230, lng: 72.5720 }}  
                            enablePin={true}                         
                            onLocationSelected={handleLocationSelected}  
                            markerlocation={markerPosition}            
                            livelocation={true}               
                        />
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent:"space-between",
                    gap: "20px"
                }} >
                    <Box>
                        <InputLabel sx={inputLabel}>
                            Location Latitude  <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.lat}
                            onChange={handleInputChange}
                            name="lat"
                            error={errors.lat}
                            helperText={errors.lat ? "Latitude is required" : ""}
                            placeholder={"Enter Fleet Latitude"}
                        />
                    </Box>
                    <Box>
                        <InputLabel sx={inputLabel}>
                            Location Longitude  <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.lng}
                            onChange={handleInputChange}
                            name="lng"
                            error={errors.lng}
                            helperText={errors.lat ? "Longitude is required" : ""}
                            placeholder={"Enter Fleet Longitude"}
                        />
                    </Box>
                    <Box>
                        <InputLabel sx={inputLabel}>
                            Support Phone Number <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.support_phone_number}
                            onChange={handleInputChange}
                            name="support_phone_number"
                            error={errors.support_phone_number}
                            helperText={errors.support_phone_number ? "Support Phone Number is required" : ""}
                            placeholder={"Enter Support Phone Number"}
                        />
                    </Box>
                </Box>

            </Paper>



            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                    display: "flex",
                    flexDirection: 'column',
                    gap: "20px"
                }}
            >
                <Box>
                    <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }}>
                        Advance Settings
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {/* Row 1 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Parking requirement</InputLabel>
                        <Select
                            value={formData.parking_requirment}
                            defaultValue={formData.parking_requirment}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#283246",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "& .MuiSelect-select": {
                                    color: "#283246",
                                    fontSize: "14px",
                                    fontWeight: 500, 
                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                },


                            }}
                            name="parking_requirment"
                        >
                            <MenuItem value={"Select Parking Requirment"}>Select Parking Requirment</MenuItem>
                            <MenuItem value="Required">Required</MenuItem>
                            <MenuItem value="Not Required">Not Required</MenuItem>
                        </Select>
                    </Grid>

                    {/* Row 2 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto end ride - idle vehicle </InputLabel>
                        <Select
                            value={formData.auto_end_ride_ideal_vehicle}
                            defaultValue={formData.auto_end_ride_ideal_vehicle}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#283246",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "& .MuiSelect-select": {
                                    color: "#283246",
                                    fontSize: "14px",
                                    fontWeight: 500, 
                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                },


                            }}
                            name="auto_end_ride_ideal_vehicle"
                        >
                            <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                            <MenuItem value="Required">Required</MenuItem>
                            <MenuItem value="Not Required">Not Required</MenuItem>
                        </Select>
                    </Grid>

                    {/* Row 3 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto end ride - paused vehicle  <span style={{ color: "#F99494" }}>*</span></InputLabel>
                        <FormControl error={errors.auto_end_ride_paused_vehicle} >
                            <Select
                                value={formData.auto_end_ride_paused_vehicle}
                                defaultValue={formData.auto_end_ride_paused_vehicle}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px',
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#283246",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "& .MuiSelect-select": {
                                        color: "#283246",
                                        fontSize: "14px",
                                        fontWeight: 500, 
                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                    },


                                }}
                                name="auto_end_ride_paused_vehicle"
                            >
                                <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                                <MenuItem value="Required">Required</MenuItem>
                                <MenuItem value="Not Required">Not Required</MenuItem>
                            </Select>
                            {errors.auto_end_ride_paused_vehicle && (
                                <FormHelperText>
                                    Required Filed
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    {/* Row 4 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto end ride - critical battery vehicle  <span style={{ color: "#F99494" }}>*</span> </InputLabel>
                        <FormControl error={errors.auto_end_ride_critical_battery_vehicle} >
                            <Select
                                value={formData.auto_end_ride_critical_battery_vehicle}
                                defaultValue={formData.auto_end_ride_critical_battery_vehicle}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px',
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#283246",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "& .MuiSelect-select": {
                                        color: "#283246",
                                        fontSize: "14px",
                                        fontWeight: 500, 
                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                    },


                                }}
                                name="auto_end_ride_critical_battery_vehicle"
                            >
                                <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                                <MenuItem value="Applicable">Applicable</MenuItem>
                                <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                            </Select>
                            {errors.auto_end_ride_critical_battery_vehicle && (
                                <FormHelperText>
                                    Required Filed
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    {/* Row 5 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Low battery level <span style={{ color: "#F99494" }}>*</span> </InputLabel>

                        <FormControl error={errors.low_battery_level} >
                            <Select
                                value={formData.low_battery_level}
                                defaultValue={formData.low_battery_level}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px',
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#283246",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "& .MuiSelect-select": {
                                        color: "#283246",
                                        fontSize: "14px",
                                        fontWeight: 500, 
                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                    },


                                }}
                                name="low_battery_level"
                            >
                                <MenuItem value={"Select Low battery level"}>Select Low battery level</MenuItem>
                                <MenuItem value="50">50%</MenuItem>
                                <MenuItem value="40">40%</MenuItem>
                                <MenuItem value="30">30%</MenuItem>
                                <MenuItem value="20">20%</MenuItem>
                                <MenuItem value="10">10%</MenuItem>
                                <MenuItem value="0">0%</MenuItem>
                            </Select>
                            {errors.low_battery_level && (
                                <FormHelperText>
                                    Required Filed
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    {/* Row 6 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto close vehicle</InputLabel>
                        <Select
                            value={formData.auto_close_vehicle}
                            defaultValue={formData.auto_close_vehicle}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#283246",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "& .MuiSelect-select": {
                                    color: "#283246",
                                    fontSize: "14px",
                                    fontWeight: 500, 
                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                },


                            }}
                            name="auto_close_vehicle"
                        >
                            <MenuItem value={"Select Auto close vehicle"}>Select Auto close vehicle</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Distance to unlock & lock vehicle  <span style={{ color: "#F99494" }}>*</span> </InputLabel>
                        <FormControl error={errors.distance_lock_unlock_vehicle} >
                            <Select
                                value={formData.distance_lock_unlock_vehicle}
                                defaultValue={formData.distance_lock_unlock_vehicle}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px',
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#283246",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "& .MuiSelect-select": {
                                        color: "#283246",
                                        fontSize: "14px",
                                        fontWeight: 500, 
                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                    },


                                }}
                                name="distance_lock_unlock_vehicle"
                            >
                                <MenuItem value={"Select Distance"}>Select Distance</MenuItem>
                                <MenuItem value="30">30 meter</MenuItem>
                                <MenuItem value="20">20 meter</MenuItem>
                                <MenuItem value="10">10 meter</MenuItem>
                                <MenuItem value="0">0 meter</MenuItem>
                            </Select>
                            {errors.distance_lock_unlock_vehicle && (
                                <FormHelperText>
                                    Required Filed
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Pause Ride</InputLabel>
                        <Select
                            value={formData.pause_ride}
                            defaultValue={formData.pause_ride}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#283246",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#DDDEE1", 
                                },
                                "& .MuiSelect-select": {
                                    color: "#283246",
                                    fontSize: "14px",
                                    fontWeight: 500, 
                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                },


                            }}
                            name="pause_ride"
                        >
                            <MenuItem value={"Select Ride Pause"}>Select Ride Pause</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Box sx={{ display: "flex", gap: "10px" }}
                >

                    <Box onClick={() => handleSubmit()}  >
                        <FilledButton sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                            Save
                        </FilledButton>
                    </Box>
                    <Box>
                        <FilledButton
                            onClick={() => navigate("/dashboard/fleet")}
                            sx={{
                                border: `1px solid #97A1B6`,
                                width: "150px",
                                color: "black",
                                background: "white",
                                boxShadow: 'none',
                                "&:hover": {
                                  background: "white",
                                },
                              }}>
                            Cancel
                        </FilledButton>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}

export default CreateFleet
import { Box, Typography, Divider, IconButton } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Chip from '@mui/material/Chip';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Button from '@mui/material/Button';
import NoNotificationIcon from "../assets/Icons/NoNotificationIcon.svg"
import { useState } from "react";
import { Notifications } from "../utils/NotificationArray";
import Notification from "./Notification";
import CustomDatePicker from "../features/Trips/TripsTable/CustomDatePicker";
const NotificationDrwaer = ({ setNotificatioDrawer }) => {

    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        return new Date(today.getFullYear(), 0, 1); 
      });
      const [endDate, setEndDate] = useState(new Date());

    let filteredNotifications;

    if (endDate) {

        filteredNotifications = Notifications.filter((notification) => {
            const notificationDate = new Date(notification.date); 
            return (
                notificationDate >= startDate && (endDate === null || notificationDate <= endDate)
            );
        });
    }


    const [notificationType, setNotificationType] = useState("all")
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;
    let count;

    if(filteredNotifications){
        count = Math.ceil(filteredNotifications.length / itemsPerPage);
    }
    
    else{
        count = Math.ceil(Notifications.length / itemsPerPage);
    }
    const startIndex = (page - 1) * itemsPerPage;

    let currentItems;

    if(filteredNotifications){
        currentItems = filteredNotifications.slice(startIndex, startIndex + itemsPerPage);
    }
    else{
        currentItems = Notifications.slice(startIndex, startIndex + itemsPerPage);
    }
    const handlePageChange = (event, value) => {
        setPage(value);
    };
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    marginTop: "20px",
                    marginInline: "5px",
                    flexDirection: "column",
                    gap: "10px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap:"20px"

                    }}

                >
                    <Box>
                        <IconButton onClick={() => setNotificatioDrawer(false)} size="large"  >
                            <KeyboardBackspaceIcon sx={{fontSize: {lg:"16px",xl:"24px"},color:"#283246"}}  />
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: "#283246",
                                fontSize: {lg:"16px",xl:"24px"},
                                fontWeight: "600"
                                
                            }}
                        >Notifications</Typography>
                    </Box>
                </Box>
                <Box sx={{ marginInline: "10px" }}>
                    <Button
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(45deg, #535A6A 30%, #283246 90%)',
                            color: 'white',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #283246 30%, #535A6A 90%)',
                            },
                            borderRadius: "10px",
                            width: {lg:"10vw",xl:"15vw"},
                            fontSize:{lg:"12px",xl:"16px"},
                            fontWeight:"600"
                        }}
                    >Inbox</Button>
                </Box>
            </Box>
            <Box>
                <Divider sx={{ borderColor: "#F99494" }} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px"
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        marginInline: "20px"
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: "10px",
                            flex: 1
                        }}>
                        <Box>
                            <Chip label="All Activity"
                                size="small"
                                variant="outlined"
                                onClick={() => setNotificationType("all")}
                                sx={{
                                    color: notificationType == "all" ? "#F99494" : "#283246",
                                    background: notificationType == "all" ? "#FFD9D933" : "#C7C6C633",
                                    borderColor: notificationType == "all" ? "#F99494" : "#97A1B6",
                                    fontSize:"14px",
                                    fontWeight:"500"
                                }}
                            />
                        </Box>
                        <Box>
                            <Chip
                                label="Important Message"
                                variant="outlined"
                                onClick={() => setNotificationType("important")}
                                sx={{
                                    color: notificationType == "important" ? "#F99494" : "#283246",
                                    background: notificationType == "important" ? "#FFD9D933" : "#C7C6C633",
                                    borderColor: notificationType == "important" ? "#F99494" : "#97A1B6",
                                     fontSize:"14px",
                                    fontWeight:"500"
                                }}
                                size="small"
                                deleteIcon={<StarBorderIcon />}
                                onDelete={() => { }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <CustomDatePicker
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        marginInline: "5px",

                    }}
                >
                    {
                        Notifications.length > 0 && currentItems.length>0 ?
                            (
                                <Box
                                    sx={{
                                        marginInline: "5px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "20px",
                                        }}
                                    >
                                        {currentItems.map((notification, key) => (
                                            <Notification
                                                date={notification.date}
                                                desc={notification.desc}
                                                title={notification.title}
                                                type={notification.type}
                                                key={key}
                                            />
                                        ))}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBlock: "20px"
                                        }}
                                    >
                                        <Pagination
                                            count={count}
                                            page={page}
                                            onChange={handlePageChange}
                                            color="primary"
                                            sx={{
                                                "& .MuiPaginationItem-root": {
                                                    color: "#97A1B6", // Default color for pagination items
                                                    "&:hover": {
                                                      backgroundColor: "transparent", // Prevent background color change on hover
                                                    },
                                                  },
                                                  "& .Mui-selected": {
                                                    backgroundColor: "#283246", // Change to your desired background color for the current page
                                                    color: "white", // Change the text color of the selected button
                                                    "&:hover": {
                                                      backgroundColor: "#283246", // Prevent background color change on hover for the selected button
                                                    },
                                                  },
                                                
                                            }}
                                           
                                        />
                                    </Box>
                                </Box>
                            ) :
                            (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: 'center',
                                        alignItems: "center",
                                        gap: "20px"
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "400",
                                                color: "#283246"
                                            }}
                                        >You Have No Notifications</Typography>
                                    </Box>
                                    <Box>
                                        <img
                                            height={200}
                                            width={200}
                                            src={NoNotificationIcon} />
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "36px",
                                                fontWeight: "400",
                                                color: "#283246"
                                            }}
                                        >Notifications Center</Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                color: "#283246",
                                                textAlign: "center",
                                                paddingInline: "180px"
                                            }}
                                        >Notifications are displayed here to alert you when various actions or activities occur in your account . watch for expanded capabilities in the near future as we introduce additional notifications.</Typography>
                                    </Box>
                                </Box>
                            )
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default NotificationDrwaer;
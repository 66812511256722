function getCurrentTimeFormatted() {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const now = new Date();

    const day = now.getDate();
    const daySuffix = getDaySuffix(day); // Helper function to get 'st', 'nd', 'rd', or 'th'
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${month} ${day}${daySuffix}, ${year} ${hours}:${minutes}:${seconds}`;
}

function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'; // Handle 11th, 12th, 13th
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

export default getCurrentTimeFormatted


export const formatFilterDate = (dateStr) => {
    // Remove any "th", "st", "nd", "rd" from the date string
    const formattedDateStr = dateStr.replace(/(\d+)(st|nd|rd|th)/, '$1');
    return new Date(formattedDateStr);
};

export const  getTodayDate = () => {
    const today = new Date();
    const formattedDate = today.getFullYear() + '-' +
        String(today.getMonth() + 1).padStart(2, '0') + '-' +
        String(today.getDate()).padStart(2, '0');

    return formattedDate
}
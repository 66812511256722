import React from "react";
import { Button as MUIButton } from "@mui/material";

const FilledButton = ({ startIcon, endIcon, children, sx = {}, ...props }) => (
  <MUIButton
    variant="contained"
    disableRipple
    endIcon={endIcon}
    startIcon={startIcon}
    sx={{
      height: "45px",
      width: "310px",
      background: "linear-gradient(90deg, #535A6A 0%, #283246 100%)",
      color: "white",
      textTransform: "none",
      borderRadius: "12px",
      fontSize: "14px",
      fontWeight: 500,
      padding: "0px 10px 0px 10px",
      gap: 1,
      '&:hover': {
          background: "linear-gradient(90deg, #535A6A 0%, #283246 100%)", // Set hover background to match normal background
          transform: 'scale(1.05)', // Scaling effect on hover
          boxShadow: 'none' // Optional: remove boxShadow if present on hover
        },
      ...sx,
    }}
    {...props}
  >
    {children}
  </MUIButton>
);

export default FilledButton;

import { Typography, Box, IconButton, Paper, Tab, Tabs, Grid, MenuItem, InputLabel, Select, FormControl, Chip, FormHelperText } from "@mui/material"
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import { useState } from "react";
import FilledButton from "../../../components/MainButtons/FilledButton";
import OutLinedButton from "../../../components/MainButtons/OutLinedButton";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LinkTimeline from "../../../assets/Icons/attachment.svg"
import StatusSelectIocn from "../../../assets/Icons/StatusSelectIcon.svg"
import SearchTextField from "../../../components/SearchTextField";
import UserTableComponent from "../../UserManagement/UserTable";
import { TripsTablesCellItems } from "../utils"
import CustomDatePicker from "../../Trips/TripsTable/CustomDatePicker";
import IOSSwitch from "../../../components/Switch/IOSswitch"
import ServiceStartEnd from "../../../assets/Icons/ServiceStartEndFlag.svg"
import BatteryFlg from "../../../assets/Icons/BatteryCriticalFlag.svg"
import MaintanceFlag from "../../../assets/Icons/MaintaniceFlag.svg"
import IOTFLag from "../../../assets/Icons/IOTFaultFlag.svg"
import QRCODE from "../../../assets/Icons/QrcodeIcon.svg"
import COPY from "../../../assets/Icons/CopyButton.svg"
import { updatedVehicle } from "../../../slices/vehicleManageSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItemStyle } from "../../../styles/MenuItemStyle";
import { formatFilterDate } from "../../../utils/TimeFormat";
import FleetSelection from "../../../components/FleetSelector/FleetSelection";
import { useSnackbar } from "../../../hooks/Snackbar"


const tabStyles = {
    width: '191px',
    height: '40px',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#F99494',
    '&.Mui-selected': {
        background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
        color: '#FFFFFF',
        borderRadius: '12px',
    },
};

const VehiclesForms = ({
    battery,
    flag,
    flags,
    distance_travelled,
    id,
    location,
    revenue_genrated,
    total_trips,
    user_name,
    vehicle_action,
    vehicle_qr,
    vehicle_status,
    vehicle_type,
    vehicle_name,
    battery_critical,
    fleet,
    qr_code,
    iot_type,
    iot_name,
    imei,
    bluetooth_flow,
    activity,
    trips_table_data,
    qr_code_link,
    service_start_end,
    maintenance,
    iot_fault

}) => {

    const [formData, setFormData] = useState({
        vehicle_name: vehicle_name,
        vehicle_type: vehicle_type,
        fleet: fleet,
        qr_code: qr_code,
        iot_type: iot_type,
        iot_name: iot_name,
        imei: imei,
        bluetooth_flow: bluetooth_flow,
        battery: battery,
        flag: flag,
        flags: flags,
        distance_travelled: distance_travelled,
        id: id,
        location: location,
        revenue_genrated: revenue_genrated,
        total_trips: total_trips,
        user_name: user_name,
        vehicle_action: vehicle_action,
        vehicle_qr: vehicle_qr,
        vehicle_status: vehicle_status
    })

    const [errors, setErrors] = useState({
        vehicle_name: false,
        vehicle_type: false,
        fleet: false,
        qr_code: false,
        iot_type: false,
        iot_name: false,
        imei: false,
        bluetooth_flow: false,
    })

    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }

    const [value, setValue] = useState(0)
    const showSnackbar = useSnackbar()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchInput, setSearchInput] = useState("");
    const [status, setStatus] = useState("All")
    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        return new Date(today.getFullYear(), 0, 1);
    });
    const [endDate, setEndDate] = useState(new Date());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select") || value.includes("Need"))),
        }));
    };

    const handleFleetChange = (e) => {
        const value = e.target.value
        setFormData({
            ...formData,
            fleet: value
        })
        setErrors((prev) => ({
            ...prev,
            fleet: value === "" || (typeof value === "string" && value.includes("Select"))
        }))
    }

    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select") || value.includes("Need")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };

    const handleSubmit = () => {

        if (validateForm()) {
            console.log("Form data:", formData);
            dispatch(updatedVehicle(formData))
            showSnackbar("Vehicle edited successfully", "success");
            // navigate("/dashboard/vehicles")
        } else {
            console.log(errors, "Validation failed");
        }
    };

    const filterTrips = trips_table_data.filter((trip) => {
        const matchesSearchInput = searchInput ? trip.username.includes(searchInput) : true;
        const matchesStatus = status !== "All" ? trip.status === status : true;
        const filterDate = formatFilterDate(trip.date)
        if (endDate == null) {
            return matchesSearchInput && matchesStatus
        }
        else {
            const filterStartDate = new Date(startDate)
            const filterEndDate = new Date(endDate)
            const isWithinDateRange = (!filterStartDate || filterDate >= filterStartDate) && (!filterEndDate || filterDate <= filterEndDate);

            return matchesSearchInput && matchesStatus && isWithinDateRange
        }

    });
    console.log(formData)
    return (

        <>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    display: "flex",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", width: "-webkit-fill-available" }} >
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Active Users"
                            sx={{
                                bgcolor: '#F8F8F8',
                                width: "fit-content",
                                borderRadius: '12px',
                                border: '1px solid #DDDEE1',

                                '& .MuiTabs-indicator': {
                                    display: 'none',
                                },
                            }}
                        >
                            {['Vehicle Details', 'IOT Configuration', 'Trips Details', 'Timeline'].map((label, index) => (
                                <Tab
                                    key={label}
                                    disableRipple
                                    label={label}
                                    sx={tabStyles}
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Box>
                        {
                            value == 0 ?
                                (<Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
                                    <Box>
                                        <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600" }} >Vehicle  Configuration</Typography>
                                    </Box>

                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    Vehicle Name <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.vehicle_name}
                                                    onChange={handleInputChange}
                                                    name="vehicle_name"
                                                    placeholder={"Enter Vehicle Name"}
                                                    error={errors.vehicle_name}
                                                    helperText={errors.vehicle_name ? "Vehicle name is required" : ""}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>

                                                <InputLabel sx={inputLabel} >
                                                    Vehicle Type <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <FormControl error={errors.vehicle_type}>
                                                    <Select

                                                        value={formData.vehicle_type}
                                                        defaultValue={formData.vehicle_type}
                                                        onChange={handleInputChange}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        sx={{
                                                            borderRadius: "10px",
                                                            width: {
                                                                xs: "100px",
                                                                sm: "300px",
                                                                md: "400px",
                                                                lg: "300px",
                                                                xl: "400px"
                                                            },
                                                            height: '40px',
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#283246",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#283246",
                                                                fontSize: "14px",
                                                                fontWeight: 500,
                                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                            },


                                                        }}
                                                        name="vehicle_type"


                                                    >
                                                        <MenuItem value={"Select Vehicle Type"}>Select Vehicle Type</MenuItem>
                                                        <MenuItem value="Bike">Bike</MenuItem>
                                                        <MenuItem value="Truck">Truck</MenuItem>
                                                        <MenuItem value="Car">Car</MenuItem>
                                                    </Select>
                                                    {errors.vehicle_type && (
                                                        <FormHelperText>
                                                            Vehicle type is required
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>

                                                <FleetSelection
                                                    value={formData.fleet}
                                                    defaultValue={formData.fleet}
                                                    handleFleetChange={handleFleetChange}
                                                    error={errors.fleet}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    QR Code <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.qr_code}
                                                    onChange={handleInputChange}
                                                    name="qr_code"
                                                    placeholder={"Enter Vehicle QR Code "}
                                                    error={errors.qr_code}
                                                    helperText={errors.qr_code ? "QR code is required" : ""}

                                                />
                                            </Grid>
                                        </Grid>

                                    </Box>
                                    <Box sx={{ display: "flex", gap: "10px", marginBlock: "15px" }} >

                                        <Box
                                            onClick={() => handleSubmit()}
                                        >
                                            <FilledButton sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                                Save Changes
                                            </FilledButton>
                                        </Box>
                                        <Box>
                                            <FilledButton
                                                onClick={() => {
                                                    navigate("/dashboard/vehicles")
                                                }}
                                                sx={{
                                                    border: `1px solid #97A1B6`,
                                                    width: "150px",
                                                    color: "black",
                                                    background: "white",
                                                    boxShadow: 'none',
                                                    "&:hover": {
                                                        background: "white",
                                                    },
                                                }} >
                                                Cancel
                                            </FilledButton>
                                        </Box>
                                    </Box>
                                </Box>) :
                                (<></>)
                        }

                        {
                            value == 1 ?
                                (<Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
                                    <Box>
                                        <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600" }} >IOT  Details</Typography>
                                    </Box>

                                    <Box sx={{ flex: 1 }} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={4}>

                                                <InputLabel sx={inputLabel} >
                                                    IOT Type <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <FormControl error={errors.iot_type} >
                                                    <Select

                                                        value={formData.iot_type}
                                                        defaultValue={formData.iot_type}
                                                        onChange={handleInputChange}

                                                        sx={{
                                                            borderRadius: "10px",
                                                            width: {
                                                                xs: "100px",
                                                                sm: "300px",
                                                                md: "400px",
                                                                lg: "300px",
                                                                xl: "400px"
                                                            },
                                                            height: '40px',
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#283246",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#283246",
                                                                fontSize: "14px",
                                                                fontWeight: 500,
                                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                            },


                                                        }}
                                                        name="iot_type"


                                                    >
                                                        <MenuItem value={"Select IOT Type"}>Select IOT Type</MenuItem>
                                                        <MenuItem value="Axons Micro Pro">Axons Micro Pro</MenuItem>
                                                        <MenuItem value="Axons Micro">Axons Micro</MenuItem>

                                                    </Select>

                                                    {errors.iot_type && (
                                                        <FormHelperText>
                                                            IOT type is required
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    IOT Name <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.iot_name}
                                                    onChange={handleInputChange}
                                                    name="iot_name"
                                                    error={errors.iot_name}
                                                    helperText={errors.iot_name ? "IOT name is required" : ""}
                                                    placeholder={"Enter IOT Name "}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel sx={inputLabel}>
                                                    IMEI  <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <CustomTextField
                                                    value={formData.imei}
                                                    onChange={handleInputChange}
                                                    name="imei"
                                                    error={errors.imei}
                                                    helperText={errors.imei ? "IMEI is required" : ""}
                                                    placeholder={"Enter IMEI Number "}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>

                                                <InputLabel sx={inputLabel} >
                                                    Bluetooth flow enabled <span style={{ color: "#F99494" }}>*</span>
                                                </InputLabel>
                                                <FormControl error={errors.bluetooth_flow} >
                                                    <Select

                                                        value={formData.bluetooth_flow}
                                                        defaultValue={formData.bluetooth_flow}
                                                        onChange={handleInputChange}

                                                        sx={{
                                                            borderRadius: "10px",
                                                            width: {
                                                                xs: "100px",
                                                                sm: "300px",
                                                                md: "400px",
                                                                lg: "300px",
                                                                xl: "400px"
                                                            },
                                                            height: '40px',
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            color: "#283246",
                                                            "& .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#DDDEE1",
                                                            },
                                                            "& .MuiSelect-select": {
                                                                color: "#283246",
                                                                fontSize: "14px",
                                                                fontWeight: 500,
                                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                            },


                                                        }}
                                                        name="bluetooth_flow"


                                                    >
                                                        <MenuItem value={"Need Bluetooth Flow?"}>Need Bluetooth Flow?</MenuItem>
                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                        <MenuItem value="No">No</MenuItem>

                                                    </Select>
                                                    {errors.bluetooth_flow && (
                                                        <FormHelperText>
                                                            Bluetooth flow is required
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{ display: "flex", gap: "10px", marginBlock: "15px" }} >

                                        <Box onClick={() => handleSubmit()} >
                                            <FilledButton sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                                Save Changes
                                            </FilledButton>
                                        </Box>
                                        <Box>
                                            <FilledButton onClick={() => {
                                                navigate("/dashboard/vehicles")
                                            }}
                                                sx={{
                                                    border: `1px solid #97A1B6`,
                                                    width: "150px",
                                                    color: "black",
                                                    background: "white",
                                                    boxShadow: 'none',
                                                    "&:hover": {
                                                        background: "white",
                                                    },
                                                }}  >
                                                Cancel
                                            </FilledButton>
                                        </Box>
                                    </Box>
                                </Box>) :
                                (<></>)
                        }

                        {

                        }

                        {
                            value == 3 ?
                                (<Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                        backgroundColor: "#F8F8F8"
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: "#283246",
                                                fontSize: "24px",
                                                fontWeight: "600"
                                            }}
                                        >
                                            {vehicle_name}- {vehicle_type} Timeline

                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "14px",
                                                color: "#28324680",
                                                textAlign: "center",
                                                paddingInline: "150px",
                                                fontWeight: "500"
                                            }}
                                        >
                                            This is timeline for vechicle here you can see activity that done with vehicle like create and delete with time.
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }} >
                                        <Timeline position="alternate" >
                                            {
                                                activity.map((activity, key) => {
                                                    return (
                                                        <TimelineItem >
                                                            <TimelineSeparator
                                                                sx={{ paddingInline: key % 2 !== 0 ? "140px" : "5px" }}
                                                            >
                                                                <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                                                <TimelineConnector sx={{ background: "#97A1B6", width: "5px" }} />
                                                            </TimelineSeparator>
                                                            <TimelineContent

                                                            >

                                                                <Box
                                                                    sx={{
                                                                        width: key % 2 !== 0 ? "130%" : "93%",
                                                                        background: "#ffff",
                                                                        display: "flex",
                                                                        paddingBlock: "20px",
                                                                        flexDirection: "row",
                                                                        gap: {
                                                                            lg: "20px",
                                                                            xl: "33px"
                                                                        },
                                                                        paddingInline: "20px",
                                                                        borderRadius: "20px"
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            backgroundColor: "#F994941A",

                                                                            width: "fit-content",
                                                                            padding: {
                                                                                lg: "18px",
                                                                                xl: "20px"
                                                                            },
                                                                            borderRadius: "60px",

                                                                        }}

                                                                    >
                                                                        <img src={LinkTimeline} height={"30px"} width={"30px"} />
                                                                    </Box>
                                                                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }} >
                                                                        <Box>
                                                                            <Typography sx={{

                                                                                color: "#283246",
                                                                                fontSize: {
                                                                                    lg: "14px",
                                                                                    xl: "16px"
                                                                                },
                                                                                fontWeight: "600"

                                                                            }} >
                                                                                User ID : {activity.user} ({activity.userRole}) {activity.type == "update" ? ("updated") : ("created")} this vehicle.
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <Typography sx={{
                                                                                fontSize: "14px",
                                                                                color: '#97A1B6'

                                                                            }} >
                                                                                This vehicle  {activity.type == "update" ? ("updated") : ("created")}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>

                                                                    <Box>
                                                                        <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                            {activity.time.split(" ")[0]}
                                                                        </Typography>
                                                                        <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600", textAlign: "center" }}>
                                                                            {activity.time.split(" ")[1].replace("th,", "")}
                                                                        </Typography>
                                                                        <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                            {activity.time.split(" ")[2]}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </TimelineContent>
                                                        </TimelineItem>
                                                    )
                                                })
                                            }


                                        </Timeline>
                                    </Box>
                                </Box>) : (<></>)

                        }

                        {
                            value == 2 ?
                                (
                                    <Box>

                                        <Box sx=
                                            {{
                                                border: '1px solid #DDDEE1',
                                                background: '#F1F1F2',
                                                height: '42px',
                                                p: 1,

                                                borderRadius: '6px',
                                                mb: 5,
                                                display: "flex"
                                            }}>
                                            <Box sx={{ display: "flex", gap: "20px", flex: 1 }} >
                                                <Box sx=
                                                    {{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "fit-content",
                                                        gap: "10px",
                                                        background: "white",
                                                        padding: "5px",
                                                        borderRadius: "8px",
                                                        border: "1px solid #DDDEE1",
                                                    }} >
                                                    <Box>
                                                        <img src={StatusSelectIocn} />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: "fit-content"
                                                        }}
                                                    >
                                                        <FormControl variant="standard" >

                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={status}
                                                                onChange={(e) => handleStatusChange(e)}
                                                                disableUnderline={true}
                                                                IconComponent={KeyboardArrowDownIcon}
                                                                sx={MenuItemStyle}
                                                            >
                                                                <MenuItem sx={MenuItemStyle} value={"All"}>Status</MenuItem>
                                                                <MenuItem sx={MenuItemStyle} value={"Force Stop"}>Force Stop</MenuItem>
                                                                <MenuItem sx={MenuItemStyle} value={"Automatically ended"}>Automatically ended</MenuItem>
                                                                <MenuItem sx={MenuItemStyle} value={"Grace Period"}>Grace Period</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>

                                                </Box>
                                                <Box>
                                                    <CustomDatePicker
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        setStartDate={setStartDate}
                                                        setEndDate={setEndDate}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <SearchTextField
                                                    value={searchInput}
                                                    onChange={handleSearchChange}
                                                />
                                            </Box>


                                        </Box>
                                        <Box>
                                            <UserTableComponent
                                                tableHeaders={TripsTablesCellItems}
                                                data={filterTrips}
                                                showActions={true}
                                                handleActions={() => { }}
                                                actionTripsNavigation={true}
                                            />
                                        </Box>
                                    </Box>


                                ) : (<></>)
                        }
                    </Box>

                </Box>

            </Paper>

            {
                value == 0 ?
                    (<Box sx={{ display: "flex", gap: "20px" }} >

                        <Paper
                            sx={{
                                borderRadius: "1.25rem",
                                background: "#F8F8F8",
                                // padding: "1.563rem",

                                display: "flex",
                                boxShadow: "none",
                                // gap: 2,
                                flex: 1,
                                mt: 1,
                                mb: 2,
                            }}
                        >
                            <Box sx={{ width: "-webkit-fill-available", display: "flex", flexDirection: 'column', gap: "5px", borderRadius: "20px", padding: "10px" }} >
                                <Box>
                                    <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600", marginInline: '20px', marginTop: "10px" }} >
                                        Flags
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
                                    <Box sx={{ display: "flex", justifyContent: "space-around" }} >
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            backgroundColor: "#FFFFFF",
                                            padding: "10px",
                                            borderRadius: "10px"
                                        }} >
                                            <Box>
                                                <img src={ServiceStartEnd} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ color: "#283246", fontSize: "14px" }} >
                                                    Service Start / End
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IOSSwitch defaultChecked={service_start_end} />
                                            </Box>


                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            backgroundColor: "#FFFFFF",
                                            padding: "10px",
                                            borderRadius: "10px"
                                        }} >
                                            <Box>
                                                <img src={BatteryFlg} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ color: "#283246", fontSize: "14px" }} >
                                                    Battery Critical
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IOSSwitch defaultChecked={battery_critical} />
                                            </Box>


                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-around" }} >
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            backgroundColor: "#FFFFFF",
                                            padding: "10px",
                                            paddingInline: "35px",
                                            borderRadius: "10px"
                                        }} >
                                            <Box>
                                                <img src={MaintanceFlag} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ color: "#283246", fontSize: "14px" }} >
                                                    Maintenance
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IOSSwitch defaultChecked={maintenance} />
                                            </Box>


                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            backgroundColor: "#FFFFFF",
                                            padding: "10px",
                                            paddingInline: "35px",
                                            borderRadius: "10px"
                                        }} >
                                            <Box>
                                                <img src={IOTFLag} />
                                            </Box>
                                            <Box>
                                                <Typography sx={{ color: "#283246", fontSize: "14px" }} >
                                                    IOT Fault
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IOSSwitch defaultChecked={iot_fault} />
                                            </Box>


                                        </Box>
                                    </Box>
                                </Box>

                            </Box>


                        </Paper>
                        <Paper
                            sx={{
                                borderRadius: "1.25rem",
                                background: "#F8F8F8",
                                // padding: "1.563rem",
                                display: "flex",
                                boxShadow: "none",
                                // gap: 2,
                                flex: 1,
                                mt: 1,
                                mb: 2,
                            }}
                        >
                            <Box >
                                <Box  >
                                    <Box sx={{ marginInline: "20px", marginBlock: "10px" }} >
                                        <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }} >
                                            QR Code
                                        </Typography>
                                    </Box>
                                    <Box   >
                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", alignContent: "center", width: "40vw" }} >
                                            <Box>
                                                <img src={QRCODE} />
                                            </Box>
                                            <Box sx={{ display: "flex", gap: "20px" }} >
                                                <Box>
                                                    <Typography sx={{ textAlign: "center", backgroundColor: "#FFFFFF", color: "#283246", fontSize: "14px", fontWeight: "600" }} >{qr_code_link}</Typography>
                                                </Box>
                                                <Box sx={{ backgroundColor: "#FFFFFF" }} >
                                                    <img src={COPY} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>


                        </Paper>
                    </Box>
                    ) :

                    (<></>)
            }

        </>






    )
}

export default VehiclesForms
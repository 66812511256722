import { Box, Paper, MenuItem, FormControl, Select, Typography } from "@mui/material"
import SearchTextField from "../../../components/SearchTextField"
import FleetSelectIocn from "../../../assets/Icons/FleetSelectIocn.svg"
import StatusSelectIocn from "../../../assets/Icons/StatusSelectIcon.svg"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import Plus from "../../../assets/Icons/plus.svg"
import FilledButton from "../../../components/MainButtons/FilledButton"
import { ServiceZoneTablesCellItems, ServiceZoneTableData } from "../utills"
import UserTableComponent from "../../UserManagement/UserTable"
import { useDispatch, useSelector } from "react-redux"
import DeleteServiceZone from "../DeleteServiceZone/DeleteServiceZone"
import { deleteServiceZone } from "../../../slices/servicezoneSlice"
import { MenuItemStyle } from "../../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FleetFilter from "../../../components/FleetSelector/index";
import { deleteGeofence } from "../../../slices/geofenceSlice"
import DeleteModal from "../../../components/DeleteModal/DeleteModal"
import { useSnackbar } from "../../../hooks/Snackbar"


const ServiceZoneTable = () => {
    const navigate = useNavigate()
    const showSnackbar=useSnackbar()

    // const {servicezoneData}=useSelector((state)=>state.servicezoneManage)
    const { geofenceData } = useSelector((state) => state.geofenceManage)

    const servicezoneData = geofenceData.filter(data => data.zone_type == "Service Zone")
    console.log(servicezoneData, "this is dataser")
    const [searchInput, setSearchInput] = useState("");
    const [fleetOptions, setFleetOptions] = useState("All")
    const [status, setStatus] = useState("All")
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteServiceZoneId, setDeleteServiceZoneId] = useState()
    const [deleteServiceZoneName, setDeleteServiceZoneName] = useState()
    const dispatch = useDispatch()
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const handleFleetChange = (e) => {
        setFleetOptions(e.target.value)
    }
    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }

    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    }

    const handleDeleteServiceZone = () => {

        // dispatch(deleteFleet(deleteFleetId))
        dispatch(deleteGeofence(deleteServiceZoneId))
        setDeleteModal(false)
        showSnackbar("Deleted service zone successfully","success")

    }

    const OpenDeleteServiceZone = (id,name) => {
        setDeleteModal(true)
        setDeleteServiceZoneId(id)
        setDeleteServiceZoneName(name)
    }



    const filterServiceZoneTablesData = servicezoneData.filter((ServiceZone) => {
        const matchesSearchInput = searchInput ? ServiceZone.name.includes(searchInput) : true;
        const matchesFleetOption = fleetOptions.includes("All Fleet") || fleetOptions.includes(ServiceZone.fleet);
        const matchesStatus = status !== "All" ? ServiceZone.status === status : true;

        return matchesSearchInput && matchesFleetOption && matchesStatus
    });



    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", minHeight: "75vh" }} >
            <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                <Box  >
                    <Typography sx={{ display: "flex", gap: "10px", fontSize: "24px", fontWeight: "700", color: "#283246" }}> Service Zone</Typography>
                </Box>
            </Box>
            <Box sx=
                {{
                    border: '1px solid #DDDEE1',
                    background: '#F1F1F2',
                    height: '42px',
                    p: 1,
                    borderRadius: '6px',
                    mb: 2,
                    display: "flex"

                }}>

                <Box sx={{ display: "flex", gap: "20px", flex: 1 }} >

                    {/* <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={FleetSelectIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={fleetOptions}
                                    label="Fleet"
                                    onChange={(e) => handleFleetChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}

                                >
                                    <MenuItem sx={MenuItemStyle} value={"All"}>Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Shymal Cross Road Public Fleet"}>Shymal Cross Road Public Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Ranip Road Private Fleet"}>Ranip Road Private Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Shivranjini Cross Road Public Fleet"}>Shivranjini Cross Road Public Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Gujarat University Public Fleet"}>Gujarat University Public Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box> */}
                    <FleetFilter setFleetOptions={setFleetOptions} />
                    <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={StatusSelectIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    onChange={(e) => handleStatusChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}
                                >
                                    <MenuItem sx={MenuItemStyle} value={"All"}>Status</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Active"}>Active</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Inactive"}>Inactive</MenuItem>

                                </Select>
                            </FormControl>
                        </Box>

                    </Box>

                </Box>
                <Box>
                    <SearchTextField value={searchInput} onChange={handleSearchChange} />
                </Box>
                <Box
                    onClick={() => { navigate("/dashboard/geofence/service-zone-create") }}
                >
                    <FilledButton startIcon={<img src={Plus} style={{ width: "18px", height: "18px" }} />} sx={{ width: '200px', height: '40px', borderRadius: '6px', ml: 3, fontSize: "14px", fontWeight: "600" }}>
                        Add Service Zone
                    </FilledButton>
                </Box>
            </Box>
            <Box>
                <UserTableComponent
                    tableHeaders={ServiceZoneTablesCellItems}
                    data={filterServiceZoneTablesData}
                    showActions={true}
                    handleEdit={() => { }}
                    handleDelete={OpenDeleteServiceZone}
                    serviceZoneNavigation={true}
                />
            </Box>
            <DeleteModal
                open={deleteModal}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDeleteServiceZone}
                title={" Confirm Service Zone Removal"}
                description={
                    <>
                        Are you sure you want to permanently remove
                        <span style={{ fontWeight: 600 }}> {deleteServiceZoneName} </span>?
                        This action cannot be undone, and all associated data will be lost.
                    </>
                }
                buttonText={"Yes"}
            />
        </Box>
    )
}


export default ServiceZoneTable


import { Box, Typography, Paper, Grid, InputLabel, Select, MenuItem, FormControl, FormHelperText } from "@mui/material"
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import { useState } from "react"
import FilledButton from "../../../components/MainButtons/FilledButton"
import OutLinedButton from "../../../components/MainButtons/OutLinedButton"
import { createVehicle } from "../../../slices/vehicleManageSlice"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FormMenuItem, MenuPlaceholder } from "../../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FleetSelection from "../../../components/FleetSelector/FleetSelection"
import {useSnackbar} from "../../../hooks/Snackbar"



const AddVehicles = () => {

    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }

    const showSnackbar=useSnackbar()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        vehicle_name: "",
        vehicle_type: "Select Vehicle Type",
        fleet: "Select Fleet",
        qr_code: "",
        iot_type: "Select IOT Type",
        iot_name: "",
        imei: "",
        bluetooth_flow: "Need Bluetooth Flow?",
        user_name: `Mbyte - Vehicle${Math.floor(Math.random() * (1000000 - 10 + 1)) + 10}`,
        vehicle_qr: `Vehicle${Math.floor(Math.random() * (1000000 - 10 + 1)) + 10}`,
        battery: "100",
        id: `usr_Xyz${Math.floor(Math.random() * (1000000 - 10 + 1)) + 10}`,
        flags: "service",
        location: "mapicon",
        vehicle_action: "Lock",
        vehicle_status: "Connected",
        total_trips: "0",
        distance_travelled: "0 KM",
        revenue_genrated: "$ 0",
        qr_code_link: "https://dashboard.axonslab.io/",
        flag: "Service Start/End",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data: [
            { "trip_id": 1, "user_id": 101, "vehicle_qr": "vehicle001", "username": "user101", "duration": "01:05:25", "distance": "20.00 KM", "cost": "$80.00", "date": "Jul 12th, 2024 17:19:09", "ratings": 4.3, "pause_duration": "00:25:12", "vehicle_id": "01", "fleet": "Shyamal Fleet", "status": "Force Stop" },
            { "trip_id": 2, "user_id": 102, "vehicle_qr": "vehicle002", "username": "user102", "duration": "01:05:25", "distance": "20.00 KM", "cost": "$80.00", "date": "Jul 10th, 2024 17:19:09", "ratings": 2.1, "pause_duration": "00:10:00", "vehicle_id": "02", "fleet": "New Ranip Fleet", "status": "Automatically ended" },
            { "trip_id": 3, "user_id": 103, "vehicle_qr": "vehicle003", "username": "user103", "duration": "01:05:25", "distance": "20.00 KM", "cost": "$80.00", "date": "Jul 20th, 2024 17:19:09", "ratings": 0.8, "pause_duration": "00:10:12", "vehicle_id": "03", "fleet": "Shiv Ranjani Fleet", "status": "Grace Period" },
            { "trip_id": 4, "user_id": 104, "vehicle_qr": "vehicle004", "username": "user104", "duration": "01:05:25", "distance": "20.00 KM", "cost": "$80.00", "date": "Jul 01th, 2024 17:19:09", "ratings": 1.9, "pause_duration": "00:02:12", "vehicle_id": "04", "fleet": "Shyamal Fleet", "status": "Force Stop" },
            { "trip_id": 5, "user_id": 105, "vehicle_qr": "vehicle005", "username": "user105", "duration": "01:05:25", "distance": "20.00 KM", "cost": "$80.00", "date": "Jul 05th, 2024 17:19:09", "ratings": 3.7, "pause_duration": "00:12:12", "vehicle_id": "05", "fleet": "New Ranip Fleet", "status": "Automatically ended" },
        ],
        service_start_end: false,
        maintenance: false,
        battery_critical: false,
        iot_fault: false


    })

    const [errors, setErrors] = useState({
        vehicle_name: false,
        vehicle_type: false,
        fleet: false,
        qr_code: false,
        iot_type: false,
        iot_name: false,
        imei: false,
        bluetooth_flow: false,
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select") || value.includes("Need"))),
        }));
    };

    const handleFleetChange = (e) => {
        const value = e.target.value
        setFormData({
            ...formData,
            fleet: value
        })
        setErrors((prev) => ({
            ...prev,
            fleet: value === "" || (typeof value === "string" && value.includes("Select"))
        }))
    }

    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select") || value.includes("Need")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };

    const handleSubmit = () => {

        if (validateForm()) {
            console.log("Form data:", formData);
            dispatch(createVehicle(formData));
            showSnackbar("Vehicle added successfully", "success");
            navigate("/dashboard/vehicles");
        } else {
            console.log(errors, "Validation failed");
        }
    };

    console.log(formData)
    return (
        <>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "none",
                    gap: 3,
                    mt: 1,
                    mb: 2,
                    minHeight: "75vh"
                }}
            >

                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", minHeight: "75vh" }} >
                    <Box>
                        <Typography sx={{ fontSize: "24px", fontWeight: "600", color: "#283246" }}> Add Vehicle </Typography>
                    </Box>
                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
                            <Box>
                                <Typography sx={{ color: "#F99494", fontSize: "16px", fontWeight: "600" }} >Vehicle  Configuration</Typography>
                            </Box>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel sx={inputLabel}>
                                            Vehicle Name <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>
                                        <CustomTextField
                                            value={formData.vehicle_name}
                                            onChange={handleInputChange}
                                            name="vehicle_name"
                                            placeholder={"Enter Vehicle Name"}
                                            error={errors.vehicle_name}
                                            helperText={errors.vehicle_name ? "Vehicle name is required" : ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>

                                        <InputLabel sx={inputLabel} >
                                            Vehicle Type <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>

                                        <FormControl
                                            error={errors.vehicle_type}
                                        >
                                            <Select
                                                value={formData.vehicle_type}
                                                defaultValue={formData.vehicle_type}
                                                onChange={handleInputChange}
                                                IconComponent={KeyboardArrowDownIcon}
                                                error={errors.vehicle_type}
                                                helperText={errors.vehicle_type ? "Vehicle type is required" : ""}
                                                sx={{
                                                    borderRadius: "10px",
                                                    width: {
                                                        xs: "100px",
                                                        sm: "300px",
                                                        md: "400px",
                                                        lg: "300px",
                                                        xl: "400px"
                                                    },
                                                    height: '40px',
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    color: "#283246",
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#DDDEE1", 
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#DDDEE1", 
                                                    },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#DDDEE1", 
                                                    },
                                                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#DDDEE1", 
                                                    },
                                                    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "#DDDEE1", 
                                                    },
                                                    "& .MuiSelect-select": {
                                                        color: "#283246",
                                                        fontSize: "14px",
                                                        fontWeight: 500, 
                                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                    },


                                                }}
                                                name="vehicle_type">
                                                <MenuItem sx={MenuPlaceholder} value={"Select Vehicle Type"}>Select Vehicle Type</MenuItem>
                                                <MenuItem sx={FormMenuItem} value="Bike">Bike</MenuItem>
                                                <MenuItem sx={FormMenuItem} value="Truck">Truck</MenuItem>
                                                <MenuItem sx={FormMenuItem} value="Car">Car</MenuItem>
                                            </Select>
                                            {errors.vehicle_type && (
                                                <FormHelperText>
                                                    Vehicle type is required
                                                </FormHelperText>
                                            )}
                                        </FormControl>


                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FleetSelection
                                            value={formData.fleet}
                                            defaultValue={formData.fleet}
                                            handleFleetChange={handleFleetChange}
                                            error={errors.fleet}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel sx={inputLabel}>
                                            QR Code <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>
                                        <CustomTextField
                                            value={formData.qr_code}
                                            onChange={handleInputChange}
                                            name="qr_code"
                                            error={errors.qr_code}
                                            helperText={errors.qr_code ? "QR code is required" : ""}
                                            placeholder={"Enter Vehicle QR Code "}
                                        />
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", flex: 1 }} >
                        <Box>
                            <Typography sx={{ color: "#F99494", fontSize: "16px", fontWeight: "600" }} >IOT  Configuration</Typography>
                        </Box>
                        <Box  >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>

                                    <InputLabel sx={inputLabel} >
                                        IOT Type <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <FormControl error={errors.vehicle_type} >
                                        <Select
                                            value={formData.iot_type}
                                            defaultValue={formData.iot_type}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            error={errors.iot_type}
                                            helperText={errors.iot_type ? "IOT type is required" : ""}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="iot_type"
                                        >
                                            <MenuItem sx={FormMenuItem} value={"Select IOT Type"}>Select IOT Type</MenuItem>
                                            <MenuItem sx={FormMenuItem} value="Axons Micro Pro">Axons Micro Pro</MenuItem>
                                            <MenuItem sx={FormMenuItem} value="Axons Micro">Axons Micro</MenuItem>

                                        </Select>
                                        {errors.iot_type && (
                                            <FormHelperText>
                                                IOT type is required
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        IOT Name <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.iot_name}
                                        onChange={handleInputChange}
                                        name="iot_name"
                                        error={errors.iot_name}
                                        helperText={errors.iot_name ? "IOT name is required" : ""}
                                        placeholder={"Enter IOT Name "}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        IMEI  <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.imei}
                                        onChange={handleInputChange}
                                        name="imei"
                                        error={errors.imei}
                                        helperText={errors.imei ? "IMEI is required" : ""}
                                        placeholder={"Enter IMEI Number "}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>

                                    <InputLabel sx={inputLabel} >
                                        Bluetooth flow enabled <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <FormControl error={errors.vehicle_type} >
                                        <Select

                                            value={formData.bluetooth_flow}
                                            defaultValue={formData.bluetooth_flow}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            error={errors.bluetooth_flow}
                                            helperText={errors.bluetooth_flow ? "Bluetooth flow is required" : ""}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="bluetooth_flow"


                                        >
                                            <MenuItem sx={FormMenuItem} value={"Need Bluetooth Flow?"}>Need Bluetooth Flow?</MenuItem>
                                            <MenuItem sx={FormMenuItem} value="Yes">Yes</MenuItem>
                                            <MenuItem sx={FormMenuItem} value="No">No</MenuItem>

                                        </Select>
                                        {errors.bluetooth_flow && (
                                            <FormHelperText>
                                                Bluetooth flow is required
                                            </FormHelperText>
                                        )}
                                    </FormControl>

                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box  >
                        <Box sx={{ display: "flex", gap: "10px", marginBlock: "15px" }} >

                            <Box>
                                <FilledButton
                                    onClick={() => handleSubmit()}
                                    sx={{ width: '150px',  borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                    Save
                                </FilledButton>
                            </Box>
                            <Box
                                onClick={
                                    () => {
                                        navigate("/dashboard/vehicles")
                                    }
                                }

                            >
                                <FilledButton sx={{
                                     border: `1px solid #97A1B6`,
                                     width: "150px",
                                     color: "black",
                                     background: "white",
                                     boxShadow: 'none',
                                     "&:hover": {
                                       background: "white",
                                     },
                                }}  >
                                    Cancel
                                </FilledButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}

export default AddVehicles
import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts"
const TripsRevenueLineChart = () => {
  const series = [
    {
      name: 'Trips Revenue',
      data: [20,40,10,50,30,75,90,45,72],
    },
    
  ];

  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: [
        "2016","2017","2018","2019","2020","2021","2022","2023","2024"
      ],
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '14px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
        },
      }
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '14px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
        },
      }

    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    colors: ['#283246'],
    markers: {
      size: 5,
    },
    legend: {
      position: "right",
      floating: true,
      markers: {
        shape: "square"
      }
    },
  };
  return (
    <Box>
      <Box
        bgcolor="#F8F8F8"
        borderRadius="25px"
        display="flex"
        flexDirection="column">
        <Box>
        <Typography 
         sx={{
          padding:"20px",
          color: "black",
          fontSize: "16px",
          fontWeight: "600",
          display:"flex"
        }}>Trips Revenue 
        
        </Typography>
        </Box>
        <Box >
          <Chart
            options={options}
            series={series}
            type="line"
            height={"330px"}
            
          />
        </Box>
      </Box>
    </Box>
  )
}

export default TripsRevenueLineChart
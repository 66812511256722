
import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts"
import CustomDatePicker from "../../../Trips/TripsTable/CustomDatePicker";
import { useState } from "react";



const NumberOfTripsBarChart = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const series = [
    {
      name: "Number Of Trips",
      data: [150, 200, 300, 100, 250, 400, 350, 320, 270, 180, 120, 210], // Sample data for each month
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      },

    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        "Jan", "Feb", "March", "Apr", "May", "Jun",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '14px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
        },
      }

    },
    yaxis: {
      min: 0,
      max: 400,
      tickAmount: 8,
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '14px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
        },
      }

    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadiusApplication: "end",
        borderRadius: 15,
        columnWidth: "50%",
      },
    },
    colors: [
      "#67748F", "#F99494"
    ], // Colors for each bar
  };

  return (
    <Box
      bgcolor="#F8F8F8"
      borderRadius="25px"
      padding="5px"
    >
      <Box sx={{display:'flex',alignItems:"center",justifyContent:"space-between",marginInline:"20px"}} >
        <Box>
          <Typography
            sx={{
              paddingInline: "15px",
              paddingBlock: "10px",
              color: "#283246",
              fontSize: "16px",
              fontWeight: "600"
            }}>Number Of Trips</Typography>
        </Box>
        <Box  >
          {/* <CustomDatePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          /> */}
        </Box>
      </Box>
      <Box>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={"450px"}
        />
      </Box>
    </Box>
  )
}

export default NumberOfTripsBarChart
import { Box, Typography, Paper, Grid, InputLabel, Select, MenuItem, Chip, Input, FormControl, FormHelperText } from "@mui/material"
import FilledButton from "../../../components/MainButtons/FilledButton"
import OutLinedButton from "../../../components/MainButtons/OutLinedButton"
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBillingPlan } from "../../../slices/billingplanSlice";
import getCurrentTimeFormatted from "../../../utils/TimeFormat";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbar } from "../../../hooks/Snackbar";



const CreateBillingPlan = () => {
    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }

    const showSnackbar=useSnackbar()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        uuid: `Uuid ${Math.floor(Math.random() * (1000000 - 10 + 1)) + 10}`,
        name: "",
        desc: "",
        status: 'Select Plan Status',
        billing_unit_minutes: "",
        first_unit_fee: "",
        next_unit_fee: "",
        pause_trip_fee_same: "Select Pause Trip Fee",
        reservation_allowed: "Select Reservation",
        card_mandatory: "Select Card Requirment",
        max_billing_price_per_trip: "",
        tax: ["GST", "SGST", "CGST"],
        display_price_tex_include: "Select Tex Price Include",
        created_date: getCurrentTimeFormatted(),
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    })

    const [errors, setErrors] = useState({
        name: false,
        desc: false,
        status: false,
        billing_unit_minutes: false,
        first_unit_fee: false,
        reservation_allowed: false,
        card_mandatory: false,
        next_unit_fee: false,
        max_billing_price_per_trip: false,
        display_price_tex_include: false

    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
        }));
    }
    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };
    const handleSubmit = () => {

        if (validateForm()) {
            dispatch(createBillingPlan(formData))
            navigate("/dashboard/payments/billing-plan")
            showSnackbar("Created billing plan successfully","success")
        } else {
            console.log(errors, "Validation failed");
        }
    };
    const handleChipDelete = (chipToDelete) => () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            tax: prevFormData.tax.filter((chip) => chip !== chipToDelete),
        }));
    };

    useEffect(()=>{
        if(formData.pause_trip_fee_same=="Select Pause Trip Fee"){
            setFormData((prevFormData) => ({
                ...prevFormData,
                pause_trip_fee_same: "No",
            }));
        }
    },[validateForm])

    console.log(formData)
    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    // height: "70vh",
                    gap: 5,
                    mt: 1,
                    mb: 2,
                }}>

                <Box>
                    <Typography
                        sx={{
                            color: "#283246",
                            fontSize: "24px",
                            fontWeight: "600"
                        }}
                    > Add Billing Plan </Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {/* Row 1 */}
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Name <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder={"Enter Plan Name"}
                                error={errors.name}
                                helperText={errors.name ? "Name is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Description <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.desc}
                                onChange={handleInputChange}
                                name="desc"
                                placeholder={"Enter Description"}
                                error={errors.desc}
                                helperText={errors.desc ? "Description is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Status  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <FormControl error={errors.status} >
                                <Select
                                    value={formData.status}
                                    defaultValue={formData.status}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px',
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#283246",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#283246",
                                            fontSize: "14px",
                                            fontWeight: 500, 
                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                        },


                                    }}
                                    name="status"
                                >
                                    <MenuItem value={"Select Plan Status"}>Select Plan Status</MenuItem>
                                    <MenuItem value="Inactive">
                                        <Box>
                                            <Chip
                                                label="Inactive"
                                                variant="outlined"
                                                size="small"
                                                icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                                                sx={{
                                                    color: "#F99494",
                                                    background: "#FFF3F3",
                                                    borderColor: "#F99494",
                                                    fontSize: "12px"
                                                }}
                                            />
                                        </Box>
                                    </MenuItem>
                                    <MenuItem value="Active">
                                        <Box>
                                            <Chip
                                                label="Active"
                                                variant="outlined"
                                                icon={<CircleIcon color="#83C3A0" />}
                                                size="small"
                                                sx={{
                                                    color: "#83C3A0",
                                                    background: "#F1F7F3",
                                                    borderColor: "#83C3A0",
                                                    fontSize: "12px"
                                                }}
                                            />
                                        </Box>
                                    </MenuItem>
                                </Select>
                                {errors.status && (
                                    <FormHelperText>
                                        Status is required
                                    </FormHelperText>
                                )}

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Billing unit in minutes  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.billing_unit_minutes}
                                onChange={handleInputChange}
                                name="billing_unit_minutes"
                                placeholder={"Enter Billing Plan Units"}
                                error={errors.billing_unit_minutes}
                                helperText={errors.billing_unit_minutes ? "Billing units is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                First unit fee  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.first_unit_fee}
                                onChange={handleInputChange}
                                name="first_unit_fee"
                                placeholder={"Enter First Unit Fee"}
                                error={errors.first_unit_fee}
                                helperText={errors.first_unit_fee ? "First unit fee is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Pause trip fee same as above?
                            </InputLabel>
                            <Select
                                value={formData.pause_trip_fee_same}
                                defaultValue={formData.pause_trip_fee_same}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px',
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    color: "#283246",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#DDDEE1", 
                                    },
                                    "& .MuiSelect-select": {
                                        color: "#283246",
                                        fontSize: "14px",
                                        fontWeight: 500, 
                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                    },


                                }}
                                name="pause_trip_fee_same"
                            >
                                <MenuItem value={"Select Pause Trip Fee"}>Select Pause Trip Fee</MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Is reservation allowed  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <FormControl error={errors.reservation_allowed} >
                                <Select
                                    value={formData.reservation_allowed}
                                    defaultValue={formData.reservation_allowed}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px',
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#283246",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#283246",
                                            fontSize: "14px",
                                            fontWeight: 500, 
                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                        },


                                    }}
                                    name="reservation_allowed"
                                >
                                    <MenuItem value={"Select Reservation"}>Select Reservation</MenuItem>
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                                {errors.reservation_allowed && (
                                    <FormHelperText>
                                        Required Filed
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Card mandatory   <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <FormControl error={errors.card_mandatory} >
                                <Select
                                    value={formData.card_mandatory}
                                    defaultValue={formData.card_mandatory}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px',
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#283246",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#283246",
                                            fontSize: "14px",
                                            fontWeight: 500, 
                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                        },


                                    }}
                                    name="card_mandatory"
                                >
                                    <MenuItem value={"Select Card Requirment"}>Select Card Requirment</MenuItem>
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                                {errors.card_mandatory && (
                                    <FormHelperText>
                                        Required Filed
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Next unit fee  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.next_unit_fee}
                                onChange={handleInputChange}
                                name="next_unit_fee"
                                placeholder={"Enter Next Unit Fee"}
                                error={errors.next_unit_fee}
                                helperText={errors.next_unit_fee ? "Next unit fee is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Max billing price per trip <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.max_billing_price_per_trip}
                                onChange={handleInputChange}
                                name="max_billing_price_per_trip"
                                placeholder={"Enter Max Billing Price For Per Trip "}
                                error={errors.max_billing_price_per_trip}
                                helperText={errors.max_billing_price_per_trip ? "Max Billing Price fee is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Tax  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <Select
                                multiple
                                value={formData.tax}
                                onChange={handleInputChange}
                                input={<Input id="select-multiple-chip" />}
                                name="tax"
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={value}
                                                onDelete={handleChipDelete(value)}
                                                onMouseDown={(event) => event.stopPropagation()}
                                            />
                                        ))}
                                    </Box>
                                )}
                            >
                                {['GST', 'SGST', 'CGST', 'VAT', 'Service Tax'].map((tax) => (
                                    <MenuItem key={tax} value={tax}>
                                        {tax}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Display price with tax included <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <FormControl error={errors.display_price_tex_include} >
                                <Select
                                    value={formData.display_price_tex_include}
                                    defaultValue={formData.display_price_tex_include}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}

                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px',
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#283246",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#DDDEE1", 
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#283246",
                                            fontSize: "14px",
                                            fontWeight: 500, 
                                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                        },


                                    }}
                                    name="display_price_tex_include"
                                >
                                    <MenuItem value={"Select Tex Price Include"}>Select Tex Price Include</MenuItem>
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                                {errors.display_price_tex_include && (
                                                <FormHelperText>
                                                   Required Filed
                                                </FormHelperText>
                                            )}
                            </FormControl>
                        </Grid>

                    </Grid>
                </Box>
                <Box>
                    <Box sx={{ display: "flex", gap: "10px" }} >

                        <Box>
                            <FilledButton
                                onClick={() => handleSubmit()}
                                sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                Save
                            </FilledButton>
                        </Box>
                        <Box
                            onClick={() => {

                                navigate("/dashboard/payments/billing-plan")
                            }}
                        >
                            <FilledButton
                                sx={{
                                    border: `1px solid #97A1B6`,
                                    width: "150px",
                                    color: "black",
                                    background: "white",
                                    boxShadow: 'none',
                                    "&:hover": {
                                      background: "white",
                                    },
                                  }}>
                                Cancel
                            </FilledButton>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default CreateBillingPlan
import { Paper,Box } from "@mui/material"
import { MainMap } from "../../../components/MainMap/MainMap"

const TripsMap=()=>{
  return(

    <>
      <Box
         sx={{
          borderRadius: "10px",
        
        }}
      >
      <MainMap  largeHeight={"515px"} extraLargeHeight={"558px"} />
      </Box>
    </>
    
    
  )
}

export default TripsMap
import React, { useEffect, useState } from 'react';
import { GoogleMap, Polygon, Marker, useLoadScript, DrawingManager, Circle } from '@react-google-maps/api';
import parkingflag from "../../assets/Icons/ParkingZoneMapIcon.svg"
import restrictedflag from "../../assets/Icons/RestrictedZoneMapIcon.svg"
import slowspeedflag from "../../assets/Icons/SlowSpeedZoneMapIcon.svg"
import serviceflag from "../../assets/Icons/ServiceZoneMapIocn.svg"
import { Box } from '@mui/material';
import BikeIcon from "../../assets/Icons/BikeMapIcon.svg"
import TruckIcon from "../../assets/Icons/TruckMapIcon.svg"
import CarIcon from "../../assets/Icons/CarMapIcon.svg"


const nightModeStyle = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#212121' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#212121' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#181818' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#1b1b1b' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [{ color: '#2c2c2c' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#8a8a8a' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ color: '#373737' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#3c3c3c' }],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [{ color: '#4e4e4e' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#000000' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#3d3d3d' }],
  },
];


const zoneColors = {
  'Restricted Zone': '#FF000033',
  'Parking Zone': '#0077CC',
  'Service Zone': '#2D8C55',
  'Slow Speed Zone': '#6D00CC'
};

// Define icon URLs based on flag types
const zoneIcons = {
  'Parking Zone': parkingflag,
  'Restricted Zone': restrictedflag,
  'Service Zone': serviceflag,
  'Slow Speed Zone': slowspeedflag,
};

const VehicleIcons = {
  'Bike': BikeIcon,
  'Car': CarIcon,
  'Truck': TruckIcon,
};



const getPolygonCentroid = (coords) => {
  let x = 0, y = 0, n = coords.length;
  for (let i = 0; i < n; i++) {
    x += coords[i].lng();
    y += coords[i].lat();
  }
  return { lat: y / n, lng: x / n };
};



export const MainMap = ({
  largeHeight,
  extraLargeHeight,
  zoneType,
  selectedFleet,
  selectedFlag,
  markerlocation,
  livelocation = false,
  geofenceData,
  center = { lat: 23.0230, lng: 72.5720 },
  enablePin = false,
  onLocationSelected,
  enableDrawing = false,
  onPolygonComplete,
  onCircleComplete }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDiytvXP2dWy_3E-rJX2NWwnnFWZe4aSjU',
    libraries: ["drawing"]
  });


  const [markerPosition, setMarkerPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState(center)
  const [currentShape, setCurrentShape] = useState(null);


  console.log(markerlocation,"this all is marker location")

  useEffect(() => {
    if (enablePin) return
    if (center.lat !== mapCenter.lat || center.lng !== mapCenter.lng) {
      setMapCenter(center);
    }
  }, [center, mapCenter, enablePin]); // Include 


  if (!isLoaded) return <div>Loading...</div>;





  const getPolygonOptions = (zone) => (

    {

      fillColor: zoneColors[zone] || '#FF0000',  // Use the color specified for the zone
      fillOpacity: 0.75,  // Set a noticeable fill opacity
      strokeColor: zoneColors[zone] || '#FF0000',  // Use the same color for the border
      strokeOpacity: 1,  // Full opacity for the border
      strokeWeight: 2,  // Set the border width
      clickable: true,  // Make the polygon clickable
      editable: false,  // Prevent editing
      zIndex: 1  // Set the z-index if needed for layering
    });

  const getCircleOptions = (zone) => ({
    fillColor: zoneColors[zone] || '#FF0000',
    fillOpacity: 0.5,
    strokeColor: zoneColors[zone],  // Set the border color to white
    strokeOpacity: 1,        // Full opacity for the border
    strokeWeight: 2,
    strokePattern: [
      { icon: 'dot', offset: '0', repeat: '10px' }  // Define the pattern as dotted
    ],
    clickable: true,
    editable: false,
    zIndex: 1
  });



  const drawingOptions = {
    drawingControl: enableDrawing,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: ['polygon', 'circle']
    },
    polygonOptions: getPolygonOptions(zoneType), // Apply dynamic color based on zoneType
    circleOptions: getCircleOptions(zoneType)   // Apply the same for circles
  };


  const handlePolygonComplete = (polygon) => {
    if (currentShape) {
      currentShape.setMap(null);
    }
    setCurrentShape(polygon);

    const polygonPath = polygon.getPath().getArray();
    const centroid = getPolygonCentroid(polygonPath);
    setMarkerPosition(centroid);

    if (onPolygonComplete) {
      onPolygonComplete(polygon);
    }
  };

  const handleCircleComplete = (circle) => {
    if (currentShape) {
      currentShape.setMap(null);
    }
    setCurrentShape(circle);

    const circleCenter = circle.getCenter();
    setMarkerPosition({ lat: circleCenter.lat(), lng: circleCenter.lng() });

    if (onCircleComplete) {
      onCircleComplete(circle);
    }
  };



  const handleMapClick = (event) => {
    if (!enablePin) return; // Prevent pinning if the prop is false

    // Get lat and lng from the click event
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    //     // Update the marker position
    setMarkerPosition({ lat, lng });
    setMapCenter({ lat, lng });

    // Call the callback function passed from the parent to send lat/lng
    if (onLocationSelected) {
      onLocationSelected({ lat, lng });
    }
  };





  // Filter geofences based on fleet and flag
  const filteredGeofences = geofenceData?.filter(geofence => {


    return (!selectedFleet || geofence.fleet === selectedFleet) &&
      (!selectedFlag || geofence.flag === selectedFlag);
  });

  return (
    <Box sx={{
      height: {
        lg: `${largeHeight}`,
        xl: `${extraLargeHeight}`
      }, border: '1px solid #ccc', borderRadius: '1rem', mt: 1, mb: 1
    }}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '1rem' }}
        center={mapCenter}
        zoom={10}
        options={{
          styles: nightModeStyle, // Assuming you have night mode styles
          mapTypeControl: false,  // Hides Map/Satellite view options
          // streetViewControl: false, // Hides the Pegman (Street View control)
        }}
        onClick={enablePin ? handleMapClick : null}
      >
        {markerPosition && enablePin && (
          <>
            <Marker
              position={markerPosition}
            />
          </>
        )}
        {filteredGeofences?.map(geofence => {
          return (
            <React.Fragment key={geofence[0].id}>
              {geofence[0].type === "polygon" ? (
                <Polygon
                  paths={geofence[0].coordinates.path}
                  options={getPolygonOptions(geofence[0].zone)}
                />
              ) : geofence[0].type === "circle" ? (
                <Circle
                  center={geofence[0].coordinates.center}
                  radius={geofence[0].coordinates.radius}
                  options={getCircleOptions(geofence[0].zone)}
                />
              ) : null}
              <Marker
                position={geofence[0].coordinates.center}
                icon={{
                  url: zoneIcons[geofence[0].zone],
                  scaledSize: new window.google.maps.Size(30, 30)
                }}
                label={{
                  text: geofence[0].name,
                  color: '#FFFFFF',
                  fontSize: '12px',
                }}
              />
            </React.Fragment>
          );



        })}



        {enableDrawing && (
          <DrawingManager
            options={drawingOptions}
            onPolygonComplete={handlePolygonComplete}
            onCircleComplete={handleCircleComplete} />
        )}

        {markerPosition && enableDrawing && (
          <Marker
            position={markerPosition}
            icon={{
              url: zoneIcons[zoneType], 
              scaledSize: new window.google.maps.Size(30, 30)
            }}
          />
        )}

        {markerlocation && livelocation && (
          markerlocation.length == undefined ? (
            <>
              <Marker
                position={markerlocation}
              />
            </>
          ) : (
            <>
              {markerlocation && livelocation && (
                markerlocation.map((location, key) => {
                  // console.log(location,"this is location boi")
                  return (
                    <Marker
                      position={location}
                      icon={{
                        url:VehicleIcons[location.vehicle_type]
                       }
                      }
                    />
                  )
                })
              )}
            </>
          )
        )}

      </GoogleMap>
    </Box>
  );
};


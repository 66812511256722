import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Card, Paper, Typography, useTheme, FormControl, Select, MenuItem } from "@mui/material";
import { CSVLink } from "react-csv";
import FleetSelectIocn from "../../assets/Icons/FleetSelectIocn.svg"
import StatusSelectIocn from "../../assets/Icons/StatusSelectIcon.svg"
import SearchTextField from "../../components/SearchTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import csv from "../../assets/Icons/csv.svg";
import qrcode from "../../assets/Icons/qrcode.svg";
import ServiceZone from "../../assets/Icons/FlagsIcon.svg"
import { useNavigate } from "react-router-dom"
import UserTableComponent from "../UserManagement/UserTable";
import { vehicleData, vehicleTableCellItem, vehicleTableData } from "./utils";
import plus from "../../assets/Icons/plus.svg"
import ServiceStartEndFlag from "../../assets/Icons/ServiceStartEndFlag.svg"
import IOSswitch from "../../components/Switch/IOSswitch"
import BatteryCritical from "../../assets/Icons/BatteryCriticalFlag.svg"
import MaintenanceFlag from "../../assets/Icons/MaintaniceFlag.svg"
import IOTFlag from "../../assets/Icons/IOTFaultFlag.svg"
import DeleteVehicleModal from "./DeleteVehicles/DeleteVehicles";
import { deleteVehicle, vehicleAction } from "../../slices/vehicleManageSlice";
import VehicleLocationModal from "./VehicleLocation/VehicleLocation";
import { MenuItemStyle } from "../../styles/MenuItemStyle";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AnimatedNumber from "../../components/AnimateNumbers";
import FleetFilter from "../../components/FleetSelector/index";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import {useSnackbar} from "../../hooks/Snackbar"
import VehicleActionModal from "../../components/VehicleAction/VehicleAction";


export const Vehicles = () => {
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const { selectedVehicle } = useSelector((state) => state.selectedVehicleManage)
  const [FleetOptions, setFleetOptions] = useState("All Fleet")
  const [status, setStatus] = useState("All")
  const [flags, setFlags] = useState("All")
  const [deleteModal, setDeleteModal] = useState(false)
  const [vehicleDeleteId, setvehicleDeleteId] = useState()
  const [vehicleLocation,setVehicleLocation]=useState([])
  const [deleteVehicleName, setDeleteVehicleName] = useState()
  const [vehicleActionId,setVehicleActionId]=useState("")
  const [vehicleLocationModal, setVehicleLocationModal] = useState(false)
  const [vechicleActionName,setVehicleActionName]=useState("")
  const [vehicleStatus,setVehicleStatus]=useState("")
  const [vehicleActionModal,setVehicleActionModal]=useState(false)
  const { vehicles } = useSelector((state) => (state.vehicleManage))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme();
  const showSnackbar=useSnackbar()


  const handleLocationClick = (id,lat,lng,vehicle_type) => {
    setVehicleLocationModal(true)
    setVehicleLocation([{lat:lat,lng:lng,vehicle_type:vehicle_type}])

  }


  const handleActionModalClose=()=>{
    setVehicleActionModal(false)
  }

  const handleVechileActionClick=()=>{
    dispatch(vehicleAction(vehicleActionId))
    setVehicleActionModal(false)
    showSnackbar("Vehicle status change successfully", "success");
  }

  const handleVehicleAction = (id,name,vehicle_action) => {
    setVehicleActionModal(true)
    setVehicleActionId(id)
    setVehicleActionName(name)
    setVehicleStatus(vehicle_action)
  }

  const handleDeleteModalClose = () => {
    setDeleteModal(false)
  }

  const handleDeleteVehicle = () => {
    dispatch(deleteVehicle(vehicleDeleteId))
    setDeleteModal(false)
    showSnackbar("Vehicle deleted successfully", "success");

  }

  const OpenDeleteVehicle = (userId, vehicle_name) => {
    // console.log(uuid)
    console.log(vehicle_name, "thid is vech name")
    setDeleteModal(true)
    setvehicleDeleteId(userId)
    setDeleteVehicleName(vehicle_name)
  }

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleFleetChange = (e) => {
    setFleetOptions(e.target.value)
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  const handleFlagChange = (e) => {
    setFlags(e.target.value)
  }
  const filteredvehicles = vehicles.filter((trip) => {
    const matchesSearchInput = searchInput ? trip.user_name.includes(searchInput) : true;
    const matchesFleetOption = FleetOptions.includes("All Fleet") || FleetOptions.includes(trip.fleet);
    const matchesStatus = status !== "All" ? trip.vehicle_status === status : true;
    const matchesFlag = flags !== "All" ? trip.flag === flags : true
    // const matchesStartDate = startDate ? new Date(trip.date) >= new Date(startDate) : true;
    // const matchesEndDate = endDate ? new Date(trip.date) <= new Date(endDate) : true;

    return matchesSearchInput && matchesFleetOption && matchesStatus && matchesFlag
  });

  console.log("fleet options", FleetOptions)

  return (
    <>

      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          display: "flex",
          boxShadow: "none",
          gap: 4,
          mt: 1,
          mb: 2,
        }}
      >
        {vehicleData.map((user) => (
          <Card
            sx={{
              width: "20%",
              borderRadius: "1rem",
              padding: "1rem 1.438rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content horizontally
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                width: 55,  // Background size
                height: 55,
                backgroundColor: "#97A1B61A",  // Background color
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={user.icon} style={{ width: 35, height: 35 }} />
            </Box>





            <Typography
              sx={{
                fontSize: "16px",
                color: "#283246",
                fontWeight: 500,
                marginBottom: "0.5rem"
              }}
            >
              {user.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.375rem",
                color: "#283246",
                fontWeight: 700,
              }}
            >
              <AnimatedNumber value={user.count} />
            </Typography>
          </Card>
        ))}
      </Paper>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          minHeight: "75vh",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box>
            <Typography sx={{ color: "#283246", fontSize: '1.5rem', fontWeight: 600 }}>Vehicle Information</Typography>
          </Box>
          <Box onClick={() => navigate("/dashboard/vehicles/add-vehicle")} >
            <FilledButton startIcon={<img src={plus} />} sx={{ width: '8.313rem', height: '2.188rem', borderRadius: '6px', fontSize: "14px", fontWeight: "600" }}>Add Vehicle</FilledButton>
          </Box>
        </Box>

        <Box>
          <Box sx={{
            display: "flex",
            border: '1px solid #DDDEE1',
            background: '#F1F1F2',
            height: '42px', p: 1,
            borderRadius: '6px',
            mb: 3
          }}>

            <Box sx={{ display: "flex", gap: "10px", flex: 1 }} >
              <FleetFilter setFleetOptions={setFleetOptions} />
              <Box sx=
                {{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  gap: "10px",
                  background: "white",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid #DDDEE1",
                }} >
                <Box>
                  <img src={StatusSelectIocn} />
                </Box>
                <Box
                  sx={{
                    width: "fit-content"
                  }}
                >
                  <FormControl variant="standard" >

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      onChange={(e) => handleStatusChange(e)}
                      disableUnderline={true}
                      IconComponent={KeyboardArrowDownIcon}
                      sx={MenuItemStyle}
                    >
                      <MenuItem sx={MenuItemStyle} value={"All"}>Status</MenuItem>
                      <MenuItem sx={MenuItemStyle} value={"Connected"}>Connected</MenuItem>
                      <MenuItem sx={MenuItemStyle} value={"Not Connected"}>Not Connected</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

              </Box>
              <Box sx=
                {{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  gap: "10px",
                  background: "white",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid #DDDEE1",
                }} >
                <Box>
                  <img src={ServiceZone} />
                </Box>
                <Box
                  sx={{
                    width: "fit-content"
                  }}
                >
                  <FormControl variant="standard" >

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={flags}
                      onChange={(e) => handleFlagChange(e)}
                      disableUnderline={true}
                      IconComponent={KeyboardArrowDownIcon}
                      sx={MenuItemStyle}
                    >
                      <MenuItem sx={MenuItemStyle} value={"All"}>Flags</MenuItem>
                      <MenuItem sx={MenuItemStyle} value={"Service Start/End"}>Service Start/End</MenuItem>
                      <MenuItem sx={MenuItemStyle} value={"Maintenance"}>Maintenance</MenuItem>
                      <MenuItem sx={MenuItemStyle} value={"IOT Fault"}>IOT Fault</MenuItem>
                      <MenuItem sx={MenuItemStyle} value={"Battery Critical"}>Battery Critical</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

              </Box>
            </Box>

            <Box>
              <SearchTextField value={searchInput}
                onChange={handleSearchChange} />
              <CSVLink data={filteredvehicles}
                filename={"vehicles.csv"}>
                <FilledButton startIcon={<img src={csv} style={{ width: "18px", height: "18px" }} />} sx={{ width: '120px', height: '35px', borderRadius: '6px', ml: 3, mt: 0.3, fontSize: "14px", fontWeight: "600" }}>Get Csv</FilledButton>

              </CSVLink>
              <CSVLink data={filteredvehicles}
                filename={"vehicles.csv"}>
                <FilledButton startIcon={<img src={qrcode} style={{ width: "18px", height: "18px" }} />} sx={{ width: '150px', height: '35px', borderRadius: '6px', ml: 1, mt: 0.3, fontSize: "14px", fontWeight: "600" }}>QR Code PDF</FilledButton>

              </CSVLink>
            </Box>
          </Box>

          <UserTableComponent
            data={filteredvehicles}
            tableHeaders={vehicleTableCellItem}
            showActions={true}
            handleEdit={() => { }}
            handleLocationClick={handleLocationClick}
            handleDelete={OpenDeleteVehicle}
            handleLockClick={handleVehicleAction}
            vehicleNavigation={true}
            showCheckBox={true}
          />
        </Box>
        <DeleteModal
          open={deleteModal}
          handleClose={handleDeleteModalClose}
          handleDelete={handleDeleteVehicle}
          title={"Confirm Vehicle Removal"}
          description={
            <>
              Are you sure you want to permanently remove
              <span style={{ fontWeight: 600 }}> {deleteVehicleName} </span>?
              This action cannot be undone, and all associated data will be lost.
            </>
          }
          buttonText={"Yes"}
        />
        <VehicleLocationModal 
        vehicleLocationModal={vehicleLocationModal} 
        setVehicleLocationModal={setVehicleLocationModal}
        vehicleLocation={vehicleLocation}
        />

        <VehicleActionModal
          open={vehicleActionModal}
          handleClose={handleActionModalClose}
          title={"Confirm Vehicle Action"}
          buttonText={"Yes"}
          handleAction={handleVechileActionClick}
          status={vehicleStatus}
          description={
            <>
              Are you sure you want to chnage status of
              <span style={{ fontWeight: 600 }}> {vechicleActionName} </span>?
              
            </>
          }
        
        />
      </Paper>
      {
        selectedVehicle.length > 0 ? (<Paper
          sx={{
            borderRadius: "1.25rem",
            background: "#F8F8F8",
            padding: "1.563rem",
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
            gap: 2,
            mt: 1,
            mb: 2,
          }}
        >
          <Box>
            <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }} >Flags</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around" }} >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", backgroundColor: "#FFFFFF", padding: "20px", borderRadius: "10px" }} >
              <Box>
                <img src={ServiceStartEndFlag} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", color: "#283246" }} >Service Start / End</Typography>
              </Box>
              <Box>
                <IOSswitch />
              </Box>

            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", backgroundColor: "#FFFFFF", padding: "20px", borderRadius: "10px" }} >
              <Box>
                <img src={BatteryCritical} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", color: "#283246" }} >Battery Critical</Typography>
              </Box>
              <Box>
                <IOSswitch />
              </Box>

            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", backgroundColor: "#FFFFFF", padding: "20px", borderRadius: "10px" }} >
              <Box>
                <img src={MaintenanceFlag} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", color: "#283246" }} >Maintenance</Typography>
              </Box>
              <Box>
                <IOSswitch />
              </Box>

            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", backgroundColor: "#FFFFFF", padding: "20px", borderRadius: "10px" }} >
              <Box>
                <img src={IOTFlag} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", color: "#283246" }} >IOT Fault</Typography>
              </Box>
              <Box>
                <IOSswitch />
              </Box>

            </Box>
          </Box>
        </Paper>) : (<></>)
      }

    </>
  );
};





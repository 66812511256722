import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts"
import CustomDatePicker from "../../../Trips/TripsTable/CustomDatePicker";
import { useState } from "react";

const AverageTripLineChart = () => {

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const series = [
    {
      name: 'Today',
      data: [5, 3, 7, 8, 5, 9],
    },
    {
      name: 'Yesterday',
      data: [4, 6, 5, 7, 4, 6],
    },
  ];

  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: [
        '1-4', '5-8', '9-12', '13-16', '17-20', '21-24'
      ],
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '12px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
        }
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '12px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
        }
      },

    },
    grid: {
      padding: {
        top: 10,

      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    colors: ['#FF5733', '#283246'],
    markers: {
      size: 3,
    },
    legend: {
      position: "right",
      floating: true,
      offsetY: -25,
      markers: {
        shape: "square"
      },
      fontSize: '12px',
      fontFamily: 'Helvetica Neue, Arial',
      fontWeight: 500

    },
  };
  return (
    <Box sx={{}} >
      <Box
        bgcolor="#F8F8F8"
        borderRadius="25px"
        display="flex"
        flexDirection="column"
        gap="20px"

      >
        <Box  sx={{display:'flex',flexDirection:"column"}} >
          <Box>
            <Typography
              sx={{
                paddingInline: "15px",
                paddingBlock: "10px",
                color: "#283246",
                fontSize: "16px",
                fontWeight: "600"
              }}
            >Average Trips Per 4 Hours</Typography>
          </Box>
          <Box  >
             {/* <CustomDatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />  */}
          </Box>
        </Box>
        <Box  >
          <Chart
            options={options}
            series={series}
            type="line"
            height={"310px"}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AverageTripLineChart
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Card, Paper, Tab, Tabs, Typography, useTheme, FormControl, Select, MenuItem } from "@mui/material";
import { CSVLink } from "react-csv";
import { activeTableCellItem, deactivateTableCellItem, deletedTableCellItem, userData } from "./utils";
import SearchTextField from "../../components/SearchTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import csv from "../../assets/Icons/csv.svg";
import UserTableComponent from "./UserTable";
import DeleteUserModal from "./DeleteUser";
import { deleteActiveUser, deleteDeactivateUser } from "../../slices/userManageSlice";
import DeleteDeactivatedUser from "./DeleteDeactivatedUser";
import CustomDatePicker from "../Trips/TripsTable/CustomDatePicker";
import AnimatedNumber from "../../components/AnimateNumbers";
import { formatFilterDate } from "../../utils/TimeFormat";
import FleetFilter from "../../components/FleetSelector/index";
import DeleteModal from "../../components/DeleteModal/DeleteModal"
import {useSnackbar} from "../../hooks/Snackbar"

const tabStyles = {
  width: '191px',
  height: '40px',
  fontWeight: 600,
  fontSize: '1rem',
  textTransform: 'none',
  color: '#F99494',
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
    color: '#FFFFFF',
    borderRadius: '12px',
  },
};

export const UserManagement = () => {
  const [value, setValue] = useState(0)
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [userDelete, setUserDelete] = useState(false)
  const showSnackbar=useSnackbar()

  const [deactivatedUserDelete, setDeactivatedUserDelete] = useState(false)


  const [userToDelete, setUserToDelete] = useState('')
  const [deactivateUserToDelete, setDeactivateUserToDelete] = useState('')
  const [ActiveUserFleetOptions, setActiveUserFleetOptions] = useState("All")
  const [DeactiveUserFleetOptions, setDeactiveUserFleetOptions] = useState("All")
  const [DeletedUserFleetOptions, setDeletedUserFleetOptions] = useState("All")

  const[deleteActiveUserName,setDeleteActiveUserName]=useState()
  const[deleteDeactivatedUserName,setDeleteDeactivatedUserName]=useState()
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), 0, 1); 
  });
  const [endDate, setEndDate] = useState(new Date());

  const { activeUser, deactivatedUser, deletedUser } = useSelector((state) => state.userManage);

  const theme = useTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setSearchInput('')
    setStartDate(() => {
      const today = new Date();
      return new Date(today.getFullYear(), 0, 1); 
    })
    setEndDate(new Date())
    setValue(newValue);
  };
  const handleDeleteUser = (userId,name) => {
    console.log(userId,"this is ")
    setUserDelete(true)
    setUserToDelete(userId)
    setDeleteActiveUserName(name)
  }

  const handleDeactivateUserDelete = (userId,name) => {
    setDeactivatedUserDelete(true)
    setDeactivateUserToDelete(userId)
    setDeleteDeactivatedUserName(name)

  }


  const handleDeleteUserClose = () => {
    setUserDelete(false)
    setDeactivatedUserDelete(false)
  }


  const handleDeleteUserEdit = () => {
    setUserDelete(false);
    console.log(userToDelete,"thuis is iodfsa")
    dispatch(deleteActiveUser(userToDelete))
    showSnackbar("Deleted Active User successfully", "success");
  };

  const handleDeactivatedUserDeleteEdit = () => {
    setDeactivatedUserDelete(false);
    dispatch(deleteDeactivateUser(deactivateUserToDelete))
    showSnackbar("Deleted Deactive User successfully", "success");
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };


  const filteredActiveUsers = activeUser.filter((activeUser) => {
    const matchesSearchInput = searchInput ? activeUser.user_name.includes(searchInput) : true;
    const matchesFleetOption = ActiveUserFleetOptions.includes("All Fleet") || ActiveUserFleetOptions.includes(activeUser.fleet);
    const registerDate = formatFilterDate(activeUser.register_date);
  
    if(endDate==null){
      return matchesSearchInput && matchesFleetOption 
    }
    else{
      const filterStartDate=new Date(startDate)
      const filterEndDate=new Date(endDate)
      const isWithinDateRange = (!filterStartDate || registerDate >= filterStartDate) && (!filterEndDate || registerDate <= filterEndDate);
    
      return matchesSearchInput && matchesFleetOption && isWithinDateRange
    }
   
  
  });


  const filteredDeactiveUsers = deactivatedUser.filter((deactiveUser) => {
    const matchesSearchInput = searchInput ? deactiveUser.user_name.includes(searchInput) : true;
    const matchesFleetOption = DeactiveUserFleetOptions.includes("All Fleet") || DeactiveUserFleetOptions.includes(deactiveUser.fleet);
    const deactivatedate = formatFilterDate(deactiveUser.deactivated_date);

    if(endDate==null){
      return matchesSearchInput && matchesFleetOption
    }
    else{
      const filterStartDate=new Date(startDate)
      const filterEndDate=new Date(endDate)
      const isWithinDateRange = (!filterStartDate || deactivatedate >= filterStartDate) && (!filterEndDate || deactivatedate <= filterEndDate);
    
      return matchesSearchInput && matchesFleetOption && isWithinDateRange
    }


    
    
  });

  const filteredDeletedUsers = deletedUser.filter((deletedUser) => {
    const matchesSearchInput = searchInput ? deletedUser.user_name.includes(searchInput) : true;
    const matchesFleetOption = DeletedUserFleetOptions.includes("All Fleet") || DeletedUserFleetOptions.includes(deletedUser.fleet);
    const deleteDate = formatFilterDate(deletedUser.deleted_date);
    if(endDate==null){
      return matchesSearchInput && matchesFleetOption
    } 
    else{
      const filterStartDate=new Date(startDate)
      const filterEndDate=new Date(endDate)
      const isWithinDateRange = (!filterStartDate || deleteDate >= filterStartDate) && (!filterEndDate || deleteDate <= filterEndDate);
    
      return matchesSearchInput && matchesFleetOption && isWithinDateRange
    }

    
  });



  const handleEditUser = (userId) => {
    navigate(`/dashboard/user-edit/${userId}`);
  };

  return (
    <>

      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          display: "flex",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        {userData.map((user) => (
          <Card
            sx={{
              width: "20%",
              borderRadius: "1rem",
              padding: "1rem 3.438rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content horizontally
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                width: "52px",
                height: "52px",
                background: "#97A1B61A",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 1,
              }}
            >
              <Avatar src={user.icon} />
            </Box>

            <Typography
              sx={{
                fontSize: "16px",
                color: "#283246",
                fontWeight: 500,
                marginBottom: "0.5rem"
              }}
            >
              {user.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.375rem",
                color: theme.typography.text1.color,
                fontWeight: 700,
              }}
            >
              <AnimatedNumber value={user.count} />
            </Typography>
          </Card>
        ))}
      </Paper>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          minHeight: "75vh",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start", mb: 1 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Active Users"
            sx={{
              bgcolor: '#F8F8F8',
              borderRadius: '12px',
              border: '1px solid #DDDEE1',
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {['Active Users', 'Deactivated Users', 'Deleted Users'].map((label, index) => (
              <Tab
                key={label}
                disableRipple
                label={label}
                sx={tabStyles}
              />
            ))}
          </Tabs>
        </Box>

        <Box  >
          {value === 0 &&
            <Box>

              <Box
                sx={{
                  border: '1px solid #DDDEE1',
                  background: '#F1F1F2',
                  height: '42px',
                  p: 1,
                  borderRadius: '6px',
                  mb: 3,
                  display: "flex"
                }}>

                <Box sx={{ flex: 1, display: "flex", gap: "10px" }} >
                <FleetFilter setFleetOptions={setActiveUserFleetOptions}  />
                  <Box>
                    <CustomDatePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </Box>
                </Box>
                <Box>
                  <SearchTextField value={searchInput}
                    onChange={handleSearchChange} />
                  <CSVLink data={filteredActiveUsers}
                    filename={"activeuser.csv"}>
                    <FilledButton startIcon={<img src={csv} style={{ width: "18px", height: "18px" }} />} sx={{ width: '120px', height: '35px', borderRadius: '6px', ml: 3, mt: 0.3, fontSize: "14px", fontWeight: "600" }}>Get Csv</FilledButton>

                  </CSVLink>
                </Box>
              </Box>

              <UserTableComponent
                data={filteredActiveUsers}
                tableHeaders={activeTableCellItem}
                showActions={true}
                handleEdit={handleEditUser}
                handleDelete={handleDeleteUser}
                activeUserDelete={true}
                userNavigation={true}
              />
            </Box>
          }

          {value === 1 &&
            <>
              <Box sx={{
                border: '1px solid #DDDEE1',
                background: '#F1F1F2',
                height: '42px',
                p: 1,
                borderRadius: '6px',
                mb: 3,
                display: "flex"
              }}>
                <Box sx={{ flex: 1, display: "flex", gap: "10px" }} >
                  <FleetFilter setFleetOptions={setDeactiveUserFleetOptions} />
                  <Box>
                    <CustomDatePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </Box>
                </Box>


                <Box>
                  <SearchTextField value={searchInput}
                    onChange={handleSearchChange} />
                  <CSVLink data={filteredDeactiveUsers}
                    filename={"deactivateduser.csv"}>
                    <FilledButton startIcon={<img src={csv} style={{ width: "18px", height: "18px" }} />} sx={{ width: '120px', height: '35px', borderRadius: '6px', ml: 3, mt: 0.3, fontSize: "14px", fontWeight: "600" }}>Get Csv</FilledButton>
                  </CSVLink>
                </Box>
              </Box>
              <UserTableComponent
                data={filteredDeactiveUsers}
                tableHeaders={deactivateTableCellItem}
                showActions={true}
                deactiveUserDelete={true}
                handleDelete={handleDeactivateUserDelete}

              />
            </>
          }

          {value === 2 &&
            <>
              <Box sx={{
                border: '1px solid #DDDEE1',
                background: '#F1F1F2',
                height: '42px',
                p: 1,
                borderRadius: '6px',
                mb: 3,
                display: "flex"
              }}>
                <Box sx={{ flex: 1, display: "flex", gap: "10px" }} >
                  <FleetFilter setFleetOptions={setDeletedUserFleetOptions} />
                  <Box>
                    <CustomDatePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </Box>
                </Box>
                <Box>
                  <SearchTextField value={searchInput}
                    onChange={handleSearchChange} />
                  <CSVLink data={filteredDeletedUsers}
                    filename={"deleteduser.csv"}>
                    <FilledButton startIcon={<img src={csv} style={{ width: "18px", height: "18px" }} />} sx={{ width: '120px', height: '35px', borderRadius: '6px', ml: 3, mt: 0.3, fontSize: "14px", fontWeight: "600" }}>Get Csv</FilledButton>
                  </CSVLink>
                </Box>
              </Box>
              <UserTableComponent
                data={filteredDeletedUsers}
                tableHeaders={deletedTableCellItem}
                showActions={false}
                handleEdit={() => { }}
                handleDelete={() => { }}
              />
            </>
          }
        </Box>

        <DeleteModal
          open={userDelete}
          handleClose={handleDeleteUserClose} 
          handleDelete={handleDeleteUserEdit} 
          title={"Confirm Active User Removal"}
          description={
              <>
                  Are you sure you want to permanently remove
                  <span style={{ fontWeight: 600 }}> {deleteActiveUserName} </span>?
                  This action cannot be undone, and all associated data will be lost.
              </>
          }
          buttonText={"Yes"}
          />
        <DeleteModal 
          open={deactivatedUserDelete} 
          handleClose={handleDeleteUserClose} 
          handleDelete={handleDeactivatedUserDeleteEdit} 
          title={"Confirm Deactive User Removal"}
          description={
              <>
                  Are you sure you want to permanently remove
                  <span style={{ fontWeight: 600 }}> {deleteDeactivatedUserName} </span>?
                  This action cannot be undone, and all associated data will be lost.
              </>
          }
          buttonText={"Yes"}
          />
      </Paper>
    </>
  );
};





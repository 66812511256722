
export const PaymentBillingPlanTablesCellItems = [
    { label: "U UID", key: "uuid" },
    { label: "Name", key: "name" },
    { label:"Created Date",key:"created_date" },
    { label: "Status", key: "status" },
  
    
]


export const PaymentBillingPlanTablesData = [
    {
        "uuid": "Uuid 1",
        "name": "Public Billing Plan",
        "status":"Active",
        "first_unit_fee":"100",
        "next_unit_fee":"10",
        "created_date":"Sep 5th, 2024 18:33:45",
        "billing_unit_minutes":"10",
        "pause_trip_fee_same":"Yes",
        "reservation_allowed":"Yes",
        "first_unit_fee":"100",
        "max_billing_price_per_trip":"10",
        "desc":"Payment Billing Plan Description 1",
        "card_mandatory":"Yes",
        "tax":["SCGST","CGST"],
        "display_price_tex_include":"Yes",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 2",
        "name": "Private Billing Plan",
        "status":"Inactive",
        "billing_unit_minutes":"3",
        "first_unit_fee":"200",
        "next_unit_fee":"20",
        "pause_trip_fee_same":"No",
        "card_mandatory":"No",
        "first_unit_fee":"200",
        "max_billing_price_per_trip":"5",
        "created_date":"Sep 1th, 2024 18:33:45",
        "reservation_allowed":"No",
        "desc":"Payment Billing Plan Description 2",
        "display_price_tex_include":"No",
        "tax":["SCGST","GST"],
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 3",
        "name": "Billing Plan 1",
        "status":"Active",
        "first_unit_fee":"50",
        "billing_unit_minutes":"12",
        "first_unit_fee":"300",
        "next_unit_fee":"30",
        "card_mandatory":"Yes",
        "created_date":"Sep 2th, 2024 18:33:45",
        "max_billing_price_per_trip":"20",
        "reservation_allowed":"Yes",
        "pause_trip_fee_same":"Yes",
        "desc":"Payment Billing Plan Description 3",
        "display_price_tex_include":"Yes",
        "tax":["SCGST"],
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 4",
        "name": "Billig Plan 2",
        "status":"Inactive",
        "billing_unit_minutes":"2",
        "first_unit_fee":"120",
        "next_unit_fee":"40",
        "card_mandatory":"Yes",
        "max_billing_price_per_trip":"25",
        "created_date":"Sep 3th, 2024 18:33:45",
        "reservation_allowed":"No",
        "pause_trip_fee_same":"No",
        "desc":"Payment Billing Plan Description 4",
        "display_price_tex_include":"No",
        "tax":["SCGST"],
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 5",
        "name": "Billing Plan 3",
        "status":"Active",
        "billing_unit_minutes":"20",
        "pause_trip_fee_same":"Yes",
        "reservation_allowed":"Yes",
        "first_unit_fee":"100",
        "next_unit_fee":"60",
        "max_billing_price_per_trip":"21",
        "created_date":"Sep 4th, 2024 18:33:45",
        "card_mandatory":"No",
        "desc":"Payment Billing Plan Description 5",
        "display_price_tex_include":"Yes",
        "tax":["SCGST","CGST"],
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 6",
        "name": "Billing Plan 6",
        "status":"Inactive",
        "first_unit_fee":"100",
        "next_unit_fee":"10",
        "created_date":"Sep 5th, 2024 18:33:45",
        "billing_unit_minutes":"10",
        "pause_trip_fee_same":"Yes",
        "reservation_allowed":"Yes",
        "first_unit_fee":"100",
        "max_billing_price_per_trip":"10",
        "desc":"Payment Billing Plan Description 6",
        "card_mandatory":"Yes",
        "tax":["SCGST","CGST"],
        "display_price_tex_include":"Yes",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 7",
        "name": "Billing Plan 7",
        "status":"Active",
        "first_unit_fee":"100",
        "next_unit_fee":"10",
        "created_date":"Sep 5th, 2024 18:33:45",
        "billing_unit_minutes":"10",
        "pause_trip_fee_same":"No",
        "reservation_allowed":"No",
        "first_unit_fee":"100",
        "max_billing_price_per_trip":"10",
        "desc":"Payment Billing Plan Description 7",
        "card_mandatory":"Yes",
        "tax":["SCGST","CGST"],
        "display_price_tex_include":"Yes",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 8",
        "name": "Public Billing Plan",
        "status":"Inactive",
        "first_unit_fee":"100",
        "next_unit_fee":"10",
        "created_date":"Sep 5th, 2024 18:33:45",
        "billing_unit_minutes":"10",
        "pause_trip_fee_same":"No",
        "reservation_allowed":"Yes",
        "first_unit_fee":"100",
        "max_billing_price_per_trip":"10",
        "desc":"Payment Billing Plan Description 8",
        "card_mandatory":"Yes",
        "tax":["SCGST","CGST"],
        "display_price_tex_include":"Yes",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
   
   

]

import totalvehicle from "../../assets/Icons/VehicleUpdatedColorIcon.svg"
import availablevehicle from "../../assets/Icons/VehicleAvliableUpdatedColorIcon.svg"
import inusevehicle from "../../assets/Icons/InUseUpdatedIcon.svg"
import maintenance from "../../assets/Icons/UnassignedColorIcon.svg"
import service from "../../assets/Icons/serviceflag.svg"
import mapicon from "../../assets/Icons/location.svg"





export const vehicleData = [
    { name: "Total Vehicles", count: "78", icon: totalvehicle },
    { name: "Available", count: "50", icon: availablevehicle },
    { name: "In Use", count: "20", icon: inusevehicle },
    { name: "Unassigned Vehicles", count: "08", icon: maintenance },
];

export const TripsTablesCellItems=[
    {label:"Trip ID",key:"trip_id"},
    {label:"User ID",key:"user_id"},
    {label:"Username",key:"username"},
    {label:"Duration",key:"duration"},
    {label:"Distance(KM)",key:"distance"},
    {label:"Cost",key:"cost"},
    {label:"Status",key:"status"},
    {label:"Date",key:"date"},
    {label:"Rating",key:"ratings"},
]


  export const vehicleTableCellItem = [{label:"Name",key:"user_name"},{label:"Vehicle QR Code",key:"vehicle_qr"},{label:"IMEI",key:"imei"},{label:"Battery",key:"battery"},{label:"Flags",key:"flags"},{label:"Location",key:"location"},{label:"Vehicle Action",key:"vehicle_action"}];


  export const vehicleTableData = [
    {
        vehicle_name:"Vehicle 1",
        vehicle_type:"Bike",
        fleet:"Shyamal Road Public Fleet",
        qr_code:"Vehicle 01",
        iot_type:"Axons Micro",
        iot_name:"IOT_01",
        imei: "e00fce68a0ad7b4549",
        bluetooth_flow:"Yes",
        user_name: "Mbyte - Vehicle1",
        vehicle_qr: "Vehicle001",
        lat:23.02963617279668,
        lng:72.53015961799163,
        battery: "90",
        id: "usr_Xyz1234abcDEF2",
        flags: service,
        location: mapicon,
        vehicle_action: "Lock",
        vehicle_status:"Connected",
        total_trips:"12",
        distance_travelled:"1.5 KM",
        revenue_genrated:"$ 10",
        qr_code_link:"https://dashboard.axonslab.io/",
        flag:"Service Start/End",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:false,
        maintenance:true,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle2",
        vehicle_qr: "Vehicle002",
        imei: "d35bfc78e9bc8d4453",
        battery: "85",
        id: "usr_Yty4567defGHI3",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        lat:23.039560027600096,
        lng:72.53019666212153,
        vehicle_name:"Vehicle 2",
        vehicle_type:"Truck",
        fleet:"New Ranip Private Fleet",
        qr_code:"Vehicle 02",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_02",
        vehicle_status:"Not Connected",
        total_trips:"20",
        distance_travelled:"2.0 KM",
        revenue_genrated:"$ 8",
        bluetooth_flow:"No",
        flag:"Maintenance",
        qr_code_link:"https://dashboard.axonslab.io/",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle3",
        vehicle_qr: "Vehicle003",
        imei: "a23fd459df4bb67534",
        battery: "75",
        id: "usr_Abc9876ghiJKL4",
        flags: service,
        location: mapicon,
        lat: 23.03042622271345,
        lng:72.541205194501,
        vehicle_action: "Lock",
        vehicle_name:"Vehicle 3",
        vehicle_type:"Car",
        fleet:"Shivranjani Private Fleet",
        qr_code:"Vehicle 03",
        iot_type:"Axons Micro",
        iot_name:"IOT_03",
        vehicle_status:"Not Connected",
        total_trips:"26",
        distance_travelled:"3.0 KM",
        flag:"IOT Fault",
        revenue_genrated:"$ 25",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:false,
        maintenance:true,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle4",
        vehicle_qr: "Vehicle004",
        imei: "f45eab78ca2d5c7834",
        battery: "60",
        id: "usr_Jkl5432mnoPQR5",
        flags: service,
        lat:23.028968372151596,
        lng:72.50660860785726,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 4",
        vehicle_type:"Bike",
        fleet:"Shyamal Road Public Fleet",
        qr_code:"Vehicle 04",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_04",
        vehicle_status:"Connected",
        total_trips:"50",
        flag:"Battery Critical",
        distance_travelled:"153.0 KM",
        revenue_genrated:"$ 250",
        bluetooth_flow:"No",
        qr_code_link:"https://dashboard.axonslab.io/",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle5",
        vehicle_qr: "Vehicle005",
        imei: "c67bfa789bcd1d3542",
        battery: "50",
        id: "usr_Qrs1290pqrSTU6",
        flags: service,
        location: mapicon,
        lat:23.075189980714786,
        lng:72.57897969695935,
        vehicle_action: "Lock",
        vehicle_name:"Vehicle 5",
        vehicle_type:"Truck",
        fleet:"New Ranip Private Fleet",
        qr_code:"Vehicle 05",
        iot_type:"Axons Micro",
        iot_name:"IOT_05",
        vehicle_status:"Connected",
        total_trips:"2",
        distance_travelled:"1.0 KM",
        revenue_genrated:"$2",
        flag:"Battery Critical",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:true,
        iot_fault:false
    },
    {
        user_name: "Mbyte - Vehicle6",
        vehicle_qr: "Vehicle006",
        imei: "g23acd579e2b3f6745",
        battery: "95",
        id: "usr_Wxy6789stuVWX7",
        lat:23.078418604544982,
        lng:72.52593563889958,
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 6",
        vehicle_type:"Car",
        fleet:"Iskcon temple Public Fleet",
        qr_code:"Vehicle 06",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_06",
        vehicle_status:"Not Connected",
        total_trips:"10",
        distance_travelled:"13.0 KM",
        revenue_genrated:"$ 50",
        flag:"IOT Fault",
        bluetooth_flow:"No",
        qr_code_link:"https://dashboard.axonslab.io/",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle7",
        vehicle_qr: "Vehicle007",
        imei: "h56efg790b4e2d8794",
        battery: "70",
        id: "usr_Uvw4321xyzABC8",
        flags: service,
        location: mapicon,
        vehicle_action: "Lock",
        vehicle_name:"Vehicle 7",
        lat:23.068788162389385,
        lng:72.58152883034862,
        vehicle_type:"Bike",
        fleet:"Shivranjani Private Fleet",
        qr_code:"Vehicle 07",
        iot_type:"Axons Micro",
        iot_name:"IOT_07",
        vehicle_status:"Not Connected",
        total_trips:"75",
        distance_travelled:"175.0 KM",
        revenue_genrated:"$ 300",
        bluetooth_flow:"No",
        flag:"Service Start/End",
        qr_code_link:"https://dashboard.axonslab.io/",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:false,
        maintenance:false,
        battery_critical:true,
        iot_fault:false
    },
    {
        user_name: "Mbyte - Vehicle8",
        vehicle_qr: "Vehicle008",
        imei: "i78hij890d5f2b9803",
        battery: "40",
        id: "usr_Zab0987defDEF9",
        flags: service,
        location: mapicon,
        lat:23.045486,
        lng:72.515322,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 8",
        vehicle_type:"Truck",
        fleet:"Iskcon temple Public Fleet",
        qr_code:"Vehicle 08",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_08",
        vehicle_status:"Not Connected",
        total_trips:"10",
        distance_travelled:"15.0 KM",
        revenue_genrated:"$ 25",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        flag:"Battery Critical",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle9",
        vehicle_qr: "Vehicle009",
        imei: "j12klm891c6g3h0912",
        battery: "80",
        id: "usr_Ghi8765jklGHI0",
        flags: service,
        flag:"Service Start/End",
        location: mapicon,
        vehicle_action: "Lock",
        lat:23.055628142972175,
        lng:72.4912138117435,
        vehicle_name:"Vehicle 9",
        vehicle_type:"Car",
        fleet:"Shyamal Road Public Fleet",
        qr_code:"Vehicle 09",
        iot_type:"Axons Micro",
        iot_name:"IOT_09",
        vehicle_status:"Not Connected",
        total_trips:"15",
        distance_travelled:"12.0 KM",
        revenue_genrated:"$ 25",
        bluetooth_flow:"No",
        qr_code_link:"https://dashboard.axonslab.io/",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:false,
        maintenance:false,
        battery_critical:false,
        iot_fault:false
    },
    {
        user_name: "Mbyte - Vehicle10",
        vehicle_qr: "Vehicle010",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP12",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 10",
        vehicle_type:"Bike",
        lat:23.133214992145508,
        lng:72.54418583788434,
        fleet:"Iskcon temple Public Fleet",
        qr_code:"Vehicle 10",
        iot_type:"Axons Micro",
        iot_name:"IOT_10",
        vehicle_status:"Not Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 120",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle11",
        vehicle_qr: "Vehicle011",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP13",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 11",
        vehicle_type:"Bike",
        lat:23.129725603210353,
        lng: 72.49908100455768,
        fleet:"Shivranjani Private Fleet",
        qr_code:"Vehicle 11",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_11",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 120",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle19",
        vehicle_qr: "Vehicle019",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP14",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 19",
        vehicle_type:"Bike",
        fleet:"Shyamal Road Public Fleet",
        qr_code:"Vehicle 19",
        iot_type:"Axons Micro Pro",
        lat:23.12159871865781,
        lng:72.53818516404048,
        iot_name:"IOT_11",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 120",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle11",
        vehicle_qr: "Vehicle011",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP15",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 11",
        vehicle_type:"Bike",
        fleet:"Iskcon temple Public Fleet",
        qr_code:"Vehicle 11",
        iot_type:"Axons Micro Pro",
        lat:23.110279430298572,
        lng:72.46912642327905,
        iot_name:"IOT_11",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 120",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle12",
        vehicle_qr: "Vehicle012",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP16",
        flags: service,
        location: mapicon,
        lat:23.097985940939353,
        lng:72.44320345731327,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 12",
        vehicle_type:"Bike",
        fleet:"New Ranip Private Fleet",
        qr_code:"Vehicle 12",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_11",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 150",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle13",
        vehicle_qr: "Vehicle013",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP17",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        lat: 23.080879407157404,
        lng: 72.49581457374438,
        vehicle_name:"Vehicle 13",
        vehicle_type:"Bike",
        fleet:"Shivranjani Private Fleet",
        qr_code:"Vehicle 13",
        iot_type:"Axons Pro",
        iot_name:"IOT_11",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 90",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle14",
        vehicle_qr: "Vehicle014",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP18",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 14",
        vehicle_type:"Bike",
        fleet:"Shyamal Road Public Fleet",
        lat:23.091873853032567,
        lng:72.59746349830962,
        qr_code:"Vehicle 14",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_14",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 170",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle15",
        vehicle_qr: "Vehicle015",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP19",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 15",
        vehicle_type:"Bike",
        fleet:"Iskcon temple Public Fleet",
        qr_code:"Vehicle 15",
        lat:  23.098471222530137,
        lng: 72.58823935026969,
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_15",
        vehicle_status:"Not Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 600",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle16",
        vehicle_qr: "Vehicle011",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP110",
        flags: service,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 16",
        vehicle_type:"Bike",
        lat:23.119432108212564,
        lng:72.61021835546546,
        fleet:"New Ranip Private Fleet",
        qr_code:"Vehicle 16",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_11",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 120",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle17",
        vehicle_qr: "Vehicle017",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP111",
        flags: service,
        location: mapicon,
        lat:23.14771522475379,
        lng:72.58746657562075,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 17",
        vehicle_type:"Bike",
        fleet:"Shivranjani Private Fleet",
        qr_code:"Vehicle 17",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_11",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 120",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    },
    {
        user_name: "Mbyte - Vehicle18",
        vehicle_qr: "Vehicle018",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP125",
        flags: service,
        lat:23.045528960070445,
        lng:72.54204726561387,
        location: mapicon,
        vehicle_action: "Unlock",
        vehicle_name:"Vehicle 18",
        vehicle_type:"Bike",
        fleet:"Iskcon temple Public Fleet",
        qr_code:"Vehicle 18",
        iot_type:"Axons Micro Pro",
        iot_name:"IOT_18",
        vehicle_status:"Connected",
        total_trips:"75",
        distance_travelled:"25.0 KM",
        flag:"Battery Critical",
        revenue_genrated:"$ 120",
        bluetooth_flow:"Yes",
        qr_code_link:"https://dashboard.axonslab.io/",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        trips_table_data:[
            {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Private Fleet","status":"Automatically ended"},
            {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shivranjani Private Fleet","status":"Grace Period"},
            {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Road Public Fleet","status":"Force Stop"},
            {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Private Fleet","status":"Automatically ended"  },
        ],
        service_start_end:true,
        maintenance:false,
        battery_critical:false,
        iot_fault:true
    }
];

import { Box, Paper, Tab, Tabs, InputLabel, MenuItem, FormControl, Select } from "@mui/material"
import UserTableComponent from "../../UserManagement/UserTable"
import { CompletedTripsTableData, CompletedTripsTablesCellItems, OngoingTripsTablesCellItems, OngoingTripsTableData } from "../utills"
import { CSVLink } from "react-csv";
import csv from "../../../assets/Icons/csv.svg";
import qrcode from "../../../assets/Icons/qrcode.svg";
import SearchTextField from "../../../components/SearchTextField";
import plus from "../../../assets/Icons/plus.svg"
import FilledButton from "../../../components/MainButtons/FilledButton";
import FleetSelectIocn from "../../../assets/Icons/FleetSelectIocn.svg"
import StatusSelectIocn from "../../../assets/Icons/StatusSelectIcon.svg"
import { useState } from "react";
import CustomDatePicker from "./CustomDatePicker";
import { MenuItemStyle } from "../../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatFilterDate } from "../../../utils/TimeFormat";
import FleetFilter from "../../../components/FleetSelector/index";



const tabStyles = {
    width: '191px',
    height: '40px',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#F99494',
    '&.Mui-selected': {
        background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
        color: '#FFFFFF',
        borderRadius: '12px',
    },
};

const TripsTable = () => {

    const [searchInput, setSearchInput] = useState("");
    const [value, setValue] = useState(0)
    const [completedFleetOptions, setCompletedFleetOptions] = useState("All")
    const [ongoingFleetOptions, setOngoingFleetOptions] = useState("All Fleet")
    const [status, setStatus] = useState("All")
    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        return new Date(today.getFullYear(), 0, 1); 
      });
    const [endDate, setEndDate] = useState(new Date());

    const handleChange = (event, newValue) => {
        setSearchInput('')
        setValue(newValue);
    };
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const handleCompletedFleetChange = (e) => {
        setCompletedFleetOptions(e.target.value)
    }
    const handleOngoingFleetChange = (e) => {
        setOngoingFleetOptions(e.target.value)
    }
    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }

  
    const filterCompletedTrips = CompletedTripsTableData.filter((trip) => {
        const matchesSearchInput = searchInput ? trip.username.includes(searchInput) : true;
        const matchesFleetOption = completedFleetOptions.includes("All Fleet") || completedFleetOptions.includes(trip.fleet);
        const matchesStatus = status !== "All" ? trip.status === status : true;
        const completeDate=formatFilterDate(trip.date)
        if(endDate==null){
            return matchesSearchInput && matchesFleetOption && matchesStatus
        }
        else{
            
            const filterStartDate=new Date(startDate)
            const filterEndDate=new Date(endDate)
            const isWithinDateRange = (!filterStartDate || completeDate >= filterStartDate) && (!filterEndDate || completeDate <= filterEndDate); 
            return matchesSearchInput && matchesFleetOption && matchesStatus && isWithinDateRange
        }

        
    });

    const filterOngoingTrips = OngoingTripsTableData.filter((trip) => {
        const matchesSearchInput = searchInput ? trip.username.includes(searchInput) : true;
        const matchesFleetOption = ongoingFleetOptions.includes("All Fleet") || ongoingFleetOptions.includes(trip.fleet);


        return matchesSearchInput && matchesFleetOption
    });

    console.log(filterOngoingTrips,"this is filter ")
    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    minHeight: "75vh",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        marginBlock: "20px"
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Active Users"
                        sx={{
                            bgcolor: '#F8F8F8',
                            width: "fit-content",
                            borderRadius: '12px',
                            border: '1px solid #DDDEE1',
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}
                    >
                        {['Completed Trips', 'Ongoing Trips'].map((label, index) => (
                            <Tab
                                key={label}
                                disableRipple
                                label={label}
                                sx={tabStyles}
                            />
                        ))}
                    </Tabs>
                </Box>

                {
                    value == 0 ?
                        (<Box>

                            <Box sx=
                                {{
                                    border: '1px solid #DDDEE1',
                                    background: '#F1F1F2',
                                    height: '42px',
                                    p: 1,
                                    borderRadius: '6px',
                                    mb: 5,
                                    display: "flex"

                                }}
                            >
                                <Box sx={{ display: "flex", gap: "20px", flex: 1 }} >
                                    <FleetFilter setFleetOptions={setCompletedFleetOptions} />

                                    <Box sx=
                                        {{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "fit-content",
                                            gap: "10px",
                                            background: "white",
                                            padding: "5px",
                                            borderRadius: "8px",
                                            border: "1px solid #DDDEE1",
                                        }} >
                                        <Box>
                                            <img src={StatusSelectIocn} />
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "fit-content"
                                            }}
                                        >
                                            <FormControl variant="standard" >

                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={status}
                                                    onChange={(e) => handleStatusChange(e)}
                                                    disableUnderline={true}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    sx={MenuItemStyle}
                                                >
                                                    <MenuItem sx={MenuItemStyle} value={"All"}>Status</MenuItem>
                                                    <MenuItem sx={MenuItemStyle} value={"Force Stop"}>Force Stop</MenuItem>
                                                    <MenuItem sx={MenuItemStyle} value={"Automatically ended"}>Automatically ended</MenuItem>
                                                    <MenuItem sx={MenuItemStyle} value={"Grace Period"}>Grace Period</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>

                                    </Box>
                                    <Box>
                                        <CustomDatePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            setStartDate={setStartDate}
                                            setEndDate={setEndDate}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <SearchTextField
                                        value={searchInput}
                                        onChange={handleSearchChange}
                                    />
                                    <CSVLink
                                        data={filterCompletedTrips}
                                        filename={"CompletedTrips.csv"}>
                                        <FilledButton
                                            startIcon={<img src={csv} style={{ width: "18px", height: "18px" }} />}
                                            sx={{ width: '120px', height: '35px', borderRadius: '6px', ml: 3, mt: 0.3, fontSize: "14px", fontWeight: "600" }}>
                                            Get Csv
                                        </FilledButton>

                                    </CSVLink>
                                </Box>
                            </Box>

                            <UserTableComponent
                                tableHeaders={CompletedTripsTablesCellItems}
                                data={filterCompletedTrips}
                                showActions={true}
                                handleActions={() => { }}
                                actionTripsNavigation={true}
                            />
                        </Box>) :
                        (<Box>

                            <Box>
                                <Box sx={{
                                    border: '1px solid #DDDEE1',
                                    background: '#F1F1F2',
                                    height: '42px',
                                    p: 1,
                                    borderRadius: '6px',
                                    mb: 5,
                                    display: "flex",
                                    justifyContent:"space-between"
                                }}>

                                    {/* <Box sx={{ flex: 1 }} > */}
                                        <FleetFilter setFleetOptions={setOngoingFleetOptions}  />
                                    {/* </Box> */}
                                    <Box  >
                                        <SearchTextField
                                            value={searchInput}
                                            onChange={handleSearchChange}
                                        />
                                        <CSVLink
                                            data={filterOngoingTrips}
                                            filename={"OngoingTrips.csv"}>
                                            <FilledButton
                                                startIcon={<img src={csv} style={{ width: "18px", height: "18px" }} />}
                                                sx={{ width: '120px', height: '35px', borderRadius: '6px', ml: 3, mt: 0.3, fontSize: "14px", fontWeight: "600" }}>
                                                Get Csv
                                            </FilledButton>

                                        </CSVLink>
                                    </Box>
                                </Box>

                                <UserTableComponent
                                    tableHeaders={OngoingTripsTablesCellItems}
                                    data={filterOngoingTrips}
                                    showActions={true}
                                    handleActions={() => { }}
                                    actionTripsNavigation={true}
                                />
                            </Box>
                        </Box>)
                }


            </Paper>
        </Box>
    )
}
export default TripsTable
import { IconButton, Paper, Box, Typography, InputLabel, Select, MenuItem, Chip, Grid, Tab, Tabs, FormControl } from "@mui/material"
import React, { useEffect, useState } from "react";
import SearchTextField from "../../components/SearchTextField";
import ServiceZone from "../../assets/Icons/FlagsIcon.svg"
import FleetSelectIocn from "../../assets/Icons/FleetSelectIocn.svg"
import VehicleConnectedIcon from "../../assets/Icons/VehicleConnectedIcon.svg"
import { MainMap } from "../../components/MainMap/MainMap";
import CustomDatePicker from "../Trips/TripsTable/CustomDatePicker";
import { flagDetails } from "../../utils/MapIocns";
import DropDownIcon from "../../assets/Icons/downgunmetal.svg"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItemStyle } from "../../styles/MenuItemStyle";
import FleetFilter from "../../components/FleetSelector/index";
import { useSelector } from "react-redux";





const tabStyles = {
  width: '191px',
  height: '40px',
  fontWeight: 600,
  fontSize: '1rem',
  textTransform: 'none',
  color: '#F99494',
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
    color: '#FFFFFF',
    borderRadius: '12px',
  },
};


export const MapPage = () => {

  const [value, setValue] = useState(0)
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), 0, 1);
  });
  const [endDate, setEndDate] = useState(new Date());
  const [searchInput, setSearchInput] = useState("");
  const [Fleet, setFleet] = useState("All Fleet")
  const [zone, setZone] = useState("All Zone")
  const [VehicleConnected, setVehicleConnected] = useState("All Connected Vehicle")
  const [fleetLocation, setFleetLocation] = useState(null)

  const handleChange = (event, newValue) => {
    setFleet("All Fleet")
    setZone("All Zone")
    setVehicleConnected("All Connected Vehicle")
    setValue(newValue);
  };

  const { geofenceData } = useSelector((state) => state.geofenceManage)
  const { fleetData } = useSelector((state) => state.fleetManage)
  const { vehicles } = useSelector((state) => state.vehicleManage)


  const VehicleLocations = vehicles.map((vehicle) => {
    return {
      lat: vehicle.lat,
      lng: vehicle.lng,
      vehicle_type: vehicle.vehicle_type
    }
  })

  console.log(VehicleLocations, "this is vehicles redux data")

  const AllfleetLocations = fleetData.map((fleet) => {
    return {
      name: fleet.fleet_name,
      lat: fleet.lat,
      lng: fleet.lng
    }
  })




  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleVehicleConnectedChange = (e) => {
    setVehicleConnected(e.target.value)
  }

  const handleZoneChange = (e) => {
    setZone(e.target.value)
  }

  const handleFleetChange = (e) => {
    const value = e.target.value
    setFleet(value)

    const SelectedFleetData = AllfleetLocations.filter((fleet) => fleet.name == value)
    console.log(SelectedFleetData, "this is data show")

    if (SelectedFleetData.length) {
      setFleetLocation({ lat: SelectedFleetData[0].lat, lng: SelectedFleetData[0].lng })
    }
    else {
      setFleetLocation({ lat: 23.0230, lng: 72.5720 })
    }

  }

  console.log(fleetLocation, "this is fleet data")
  const filterGeofence = geofenceData.filter((geofence) => {
    const matchFleetOptions = Fleet.includes("All Fleet") || Fleet.includes(geofence.fleet)
    const matchSearchInput = searchInput ? geofence.name.includes(searchInput) : true
    const matchZone = zone.includes("All Zone") || zone.includes(geofence.zone_type)
    return matchFleetOptions && matchSearchInput && matchZone
  })

  const filterGeofenceData = filterGeofence.map((data) => data.geofenceData)


  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}>
        <Box>
          <Box sx={{ marginBottom: "20px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Active Users"
              sx={{
                bgcolor: '#F8F8F8',
                width: "fit-content",
                borderRadius: '12px',
                border: '1px solid #DDDEE1',
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              {['Map', 'HeatMap'].map((label, index) => (
                <Tab
                  key={label}
                  disableRipple
                  label={label}
                  sx={tabStyles}
                />
              ))}
            </Tabs>
          </Box>

          <Box>
            {
              value == 0 ?
                (
                  <>
                    <Box sx={{
                      display: "flex",
                      border: '1px solid #DDDEE1',
                      background: '#F1F1F2',
                      height: '42px', p: 1,
                      borderRadius: '6px',
                      mb: 3
                    }}>

                      <Box sx={{ display: "flex", gap: "10px", flex: 1 }} >
                        {/* <FleetFilter setFleetOptions={setFleetOptions} /> */}

                        <Box sx=
                          {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            border: "1px solid #DDDEE1",
                            padding: "5px",
                            borderRadius: "8px"
                          }} >
                          <Box>
                            <img src={FleetSelectIocn} />
                          </Box>
                          <Box
                            sx={{
                              width: "fit-content"
                            }}
                          >
                            <FormControl variant="standard" >

                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={Fleet}
                                onChange={(e) => handleFleetChange(e)}
                                disableUnderline={true}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={MenuItemStyle}
                              >
                                <MenuItem sx={MenuItemStyle} value={"All Fleet"}>All Fleet</MenuItem>
                                {
                                  AllfleetLocations.map((fleet, key) => {
                                    return (
                                      <MenuItem sx={MenuItemStyle} value={fleet.name} key={key} > {fleet.name} </MenuItem>
                                    )
                                  })
                                }
                              </Select>
                            </FormControl>
                          </Box>

                        </Box>

                        <Box sx=
                          {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            border: "1px solid #DDDEE1",
                            padding: "5px",
                            borderRadius: "8px"
                          }} >
                          <Box>
                            <img src={ServiceZone} />
                          </Box>
                          <Box
                            sx={{
                              width: "fit-content"
                            }}
                          >
                            <FormControl variant="standard" >

                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={zone}
                                onChange={(e) => handleZoneChange(e)}
                                disableUnderline={true}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={MenuItemStyle}
                              >
                                <MenuItem sx={MenuItemStyle} value={"All Zone"}>All Zone</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Parking Zone"}>Parking Zone</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Restricted Zone"}>Restricted Zone</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Service Zone"}>Service Zone</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Slow Speed Zone"}>Slow Speed Zone</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                        </Box>

                        {/* <Box sx=
                          {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                          }} >
                          <Box>
                            <img src={VehicleConnectedIcon} />
                          </Box>
                          <Box
                            sx={{
                              width: "fit-content"
                            }}
                          >
                            <FormControl variant="standard" >

                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={VehicleConnected}
                                onChange={(e) => handleVehicleConnectedChange(e)}
                                disableUnderline={true}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={MenuItemStyle}
                              >
                                <MenuItem sx={MenuItemStyle} value={"All Connected Vehicle"}>All Connected Vehicle</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"3 Hours"}>3 Hours</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"6 Hours"}>6 Hours</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"9 Hours"}>9 Hours</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"12 Hours"}>12 Hours</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                        </Box> */}
                      </Box>

                      <Box>
                        <SearchTextField value={searchInput}
                          onChange={handleSearchChange} />

                      </Box>

                    </Box>
                    <Box  >
                      <MainMap
                        largeHeight={"400px"}
                        extraLargeHeight={"500px"}
                        geofenceData={filterGeofenceData}
                        center={fleetLocation == null ? { lat: 23.0230, lng: 72.5720 } : fleetLocation}
                        livelocation={true}
                        markerlocation={VehicleLocations}

                      />
                    </Box>
                  </>
                ) : (
                 <Box
                  
                 >
                   <Box
                    sx={{
                      display: "flex",
                      border: '1px solid #DDDEE1',
                      background: '#F1F1F2',
                      height: '42px', p: 1,
                      borderRadius: '6px', mb: "15px",gap:"10px",
                      alignItems: 'center'
    
                    }}>
    
                    <Box sx={{ display: "flex", padding: "5px" }} >
                      <Box sx=
                        {{
                          display: "flex",
                          alignItems: "center",
                          width: "fit-content",
                          gap: "10px",
                          background: "white",
                          border: "1px solid #DDDEE1",
                          padding: "5px",
                          borderRadius: "8px"
                        }} >
                        <Box>
                          <img src={FleetSelectIocn} />
                        </Box>
                        <Box
                          sx={{
                            width: "fit-content"
                          }}
                        >
                          <FormControl variant="standard" >
    
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={Fleet}
                              onChange={(e) => handleFleetChange(e)}
                              disableUnderline={true}
                              IconComponent={KeyboardArrowDownIcon}
                              sx={MenuItemStyle}
                            >
                              <MenuItem sx={MenuItemStyle} value={"All Fleet"}>All Fleet</MenuItem>
                              {
                                AllfleetLocations.map((fleet, key) => {
                                  return (
                                    <MenuItem sx={MenuItemStyle} value={fleet.name} key={key} > {fleet.name} </MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl>
                        </Box>
    
                      </Box>
                    </Box>
    
                    <Box >
                      <CustomDatePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </Box>
    
    
                  </Box>
    
                  <Box>
                    <MainMap largeHeight={"400px"} extraLargeHeight={"500px"} />
                  </Box>
    
                 </Box>
                )
            }

          </Box>
        </Box>

      </Paper>
      {
        value == 0 ?
          (<Paper
            sx={{
              borderRadius: '1.25rem',
              background: '#F8F8F8',
              padding: '1.563rem',
              boxShadow: 'none',
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <Typography sx={{ color: "#283246", fontWeight: "600", fontSize: "24px" }}>
              Flags
            </Typography>
            <Grid container spacing={4}>
              {flagDetails.map((flag, i) => (
                <>
                  <Grid item xs={2.4}>
                    <Box display="flex" alignItems="center">
                      <Box sx={{ cursor: "pointer" }}>
                        <img src={flag.icon} alt="flag-icon" />
                      </Box>
                      <Typography sx={{ ml: 1, color: '#283246', fontSize: '14px', fontWeight: "600" }}>{flag.name}</Typography>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </Paper>





          ) : (<></>)
      }

      {
        value == 1 ?
          (<>
           
          </>) :
          (<>

          </>)
      }


    </>
  )
};

import { Box,Paper } from "@mui/material"
import FilledButton from "../../../components/MainButtons/FilledButton"
import Plus from "../../../assets/Icons/plus.svg"
import SearchTextField from "../../../components/SearchTextField"
import { useState } from "react"
import UserTableComponent from "../../UserManagement/UserTable"
import {FleetTablesCellItems,FleetTablesData} from "./utills"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import DeleteFleetModal from "../DeleteFleet/DeleteFleet"
import { deleteFleet } from "../../../slices/fleetSlice"
import DeleteModal from "../../../components/DeleteModal/DeleteModal"
import { useSnackbar } from "../../../hooks/Snackbar"




const FleetTable = () => {

    const {fleetData}=useSelector((state)=>state.fleetManage)
    console.log(fleetData,"this is data")
    const dispatch=useDispatch()
    const[deleteModal,setDeleteModal]=useState(false)
    const[deleteFleetId,setDeleteFleetId]=useState()
    const[deleteFleetName,setDeleteFleetName]=useState()

    const handleDeleteModalClose=()=>{
        setDeleteModal(false)
    }
    const showSnackbar=useSnackbar()
    const handleDeleteFleet=()=>{
        
        dispatch(deleteFleet(deleteFleetId))
        setDeleteModal(false)
        showSnackbar("Deleted fleet successfully","success")
    
    }

    const OpenDeleteFleet=(fleet_id,fleet_name)=>{
        setDeleteModal(true)
        setDeleteFleetId(fleet_id)
        setDeleteFleetName(fleet_name)
    }
    const [searchInput, setSearchInput] = useState("");
    const navigate=useNavigate()
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    const handleCreateFleet=()=>{
        navigate("/dashboard/create-fleet")
    }   

    const filteredFleet = searchInput? fleetData.filter((fleet) =>fleet?.fleet_name?.toLowerCase().includes(searchInput?.toLowerCase())): fleetData
     
    return (
        <Paper
        sx={{
            borderRadius: "1.25rem",
            background: "#F8F8F8",
            padding: "1.563rem",
            boxShadow: "none",
            gap: 2,
            mt: 1,
            mb: 2,
            minHeight:"78vh"
        }}>
            <Box sx={{
               border: '1px solid #DDDEE1',
               background: '#F1F1F2',
               height: '42px', 
               p: 1, 
               borderRadius: '6px', 
               mb: 5,
               display:"flex"
            }} 
            >

                <Box sx={{flex:1}}  > 
                    <SearchTextField
                        value={searchInput}
                        onChange={handleSearchChange}
                        
                    />
                </Box>


                <Box 
                onClick={()=>handleCreateFleet()}

                >
                    <FilledButton
                        startIcon={<img src={Plus} style={{ width: "18px", height: "18px" }} />}
                        sx={{ width: '8.313rem', height: '2.188rem', borderRadius: '6px', fontSize: "14px", fontWeight: "600" }}

                        >
                        Add Fleet
                    </FilledButton>
                </Box>


            </Box>
            <Box  > 
                <UserTableComponent
                tableHeaders={FleetTablesCellItems}
                data={filteredFleet}
                showActions={true}
                handleEdit={()=>{}}
                handleDelete={OpenDeleteFleet}
                fleetLocation={true}
                fleetNavigation={true}
                />
            </Box>
            <DeleteModal 
            open={deleteModal} 
            handleClose={handleDeleteModalClose} 
            handleDelete={handleDeleteFleet} 
            title={"Confirm Fleet Removal"}
            description={
                <>
                  Are you sure you want to permanently remove 
                  <span style={{ fontWeight: 600 }}> {deleteFleetName} </span>? 
                  This action cannot be undone, and all associated data will be lost.
                </>
              }
            
            buttonText={"Yes"}
            />
        </Paper>
    )
}

export default FleetTable

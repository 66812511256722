import { Box, Typography } from "@mui/material";
import { useState } from "react";
import Chart from "react-apexcharts"
import CustomDatePicker from "../../../Trips/TripsTable/CustomDatePicker";

const TotalUserPieChart = () => {

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)

  const series = [45, 25, 30];
  const options = {
    chart: {
      type: "pie",
    },
    labels: ["Active Users", "Deactive Users", "Deleted Users"],
    colors: ["#F99494", "#283246", "#67748F"],
    legend: {
      position: "right",
      // floating: true,
      // offsetY: -25,
      markers: {
        size: 8,
        shape: "square",
        strokeWidth: 1
      },
      labels: {
        colors: "#030229",
        fontSize: "10px",
      },
      fontSize: '12px',
      fontFamily: 'Helvetica Neue, Arial',
      fontWeight: 500
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false, // Disable drop shadow to keep labels clear
      },
      offsetY: 0, // Center the labels vertically
      textAnchor: 'middle',
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        padding: "20px",
        colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
      },
    },

    plotOptions: {
      pie: {
        expandOnClick: false, // Disable expanding on click
        dataLabels: {
          offset: -20, // Center the labels
          minAngleToShowLabel: 10, // Ensure labels are visible for all angles
        },
      },
    },
  };

  return (
    <Box
      bgcolor="#F8F8F8"
      borderRadius="25px"
      display="flex"
      flexDirection="column"
      gap="35px"
      
    >

      <Box sx={{ display: 'flex', justifyContent: "space-between",alignItems:"center", marginInline: "20px" }}>
        <Box sx={{display:"flex",gap:"5px"}} >
          <Typography

            sx={{
              paddingBlock: "10px",
              // paddingLeft: "15px",
              color: "#283246",
              fontSize: "16px",
              fontWeight: "600"
            }} >
            Total Users:
          </Typography>
          <Typography
            sx={{
              paddingBlock: "10px",
              // paddingInline: "15px",
              color: "#F99494",
              fontSize: "16px",
              fontWeight: "600"
            }}
          >
            100
          </Typography>
        </Box>
        <Box sx={{
         
         
        }} >
          {/* <CustomDatePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          /> */}
        </Box>
      </Box>

      <Box sx={{
        minHeight:{
          lg:"250px"
        }
      }} >
        <Chart

          options={options}
          series={series}
          type="pie"
          height={"250px"}


        />
      </Box>
    </Box>
  )
}

export default TotalUserPieChart
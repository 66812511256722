import React, { useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import info from "../assets/Icons/info.svg";
import alert from "../assets/Icons/alert.svg";
import analytics from "../assets/Icons/analytics.svg";
import mapicon from "../assets/Icons/mapicon.svg";
import vehicleicon from "../assets/Icons/vehicleicon.svg";
import usericon from "../assets/Icons/userIcon.svg";
import tripsicon from "../assets/Icons/trips.svg";
import fleeticon from "../assets/Icons/fleet.svg";
import geofencing from "../assets/Icons/geofence.svg";
import payment from "../assets/Icons/payment.svg";
import settings from "../assets/Icons/settings.svg";
import axonsimg from "../assets/Images/axons.svg";
import parking from "../assets/Icons/parking.svg";
import restricted from "../assets/Icons/restricted.svg";
import servicezone from "../assets/Icons/servicezone.svg";
import slowspeed from "../assets/Icons/slowspeed.svg";
import tax from "../assets/Icons/tax.svg";
import billingplan from "../assets/Icons/billingplan.svg";
import transaction from "../assets/Icons/transaction.svg";
import paymentgateway from "../assets/Icons/paymentgateway.svg";
import axonslab from "../assets/Images/axonslab.svg";
import backicon from "../assets/Icons/backicon.svg"
import chevron from "../assets/Icons/chevron.svg";
import NotificationDrawer from "./NotificationDrawer"
import AnalyticsNavIcon from "../assets/Icons/AnalyticsNavIcon.svg"
import MapNavIcon from "../assets/Icons/MapColorNavIcon.svg"
import VehiclesNavIcon from "../assets/Icons/VehicleNavIcon.svg"
import UserNavIcon from "../assets/Icons/UserColorNavIcon.svg"
import TripsNavIcon from "../assets/Icons/TripsNavIcon.svg"
import FleetNavIcon from "../assets/Icons/FleetNavIcon.svg"
import GeofenceNavIcon from "../assets/Icons/GeofenceNavIcon.svg"
import PaymentsNavIcon from "../assets/Icons/PaymentNavIcon.svg"
import SettingsNavIcon from "../assets/Icons/SettingsNavIcon.svg"
import { useNavigate } from "react-router-dom";
import OpenTabIcon from "../assets/Icons/OpenTab.svg"
import CloseTabIcon from "../assets/Icons/CloseTabIcon.svg"
import ParkingNavIcon from "../assets/Icons/ParkingZoneNavIcon.svg"
import RestrictedZoneNavIcon from "../assets/Icons/RestrictedZoneNavIcon.svg"
import ServiceZoneNavIcon from "../assets/Icons/ServiceZoneNavIcon.svg"
import SlowSpeedZoneNavIcon from "../assets/Icons/SlowSpeedZoneNavIcon.svg"
import PaymnetGatewayNavIcon from "../assets/Icons/PaymnetGatewayNavIcon.svg"
import TaxNavIcon from "../assets/Icons/TaxNavIcon.svg"
import BillingPlanNavIocn from "../assets/Icons/BillingPlanNavIocn.svg"
import transactionNavIcon from "../assets/Icons/transactionNavIcon.svg"
import TripsUpdatedIcon from "../assets/Icons/TripsUpdatedIcon.svg"
import VehicleUpdatedIcon from "../assets/Icons/VehicleUpdatedIcon.svg"
import MenuIcon from "@mui/icons-material/Menu";  // For open/close button icon
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";  // Close icon
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IocnSmall from "../assets/Icons/LogoSmallIcon.svg"
import PaymentOverView from "../assets/Icons/PaymentOverviewIcon.svg"
import PaymentOverViewNavIcon from "../assets/Icons/PaymentOverViewNavIcon.svg"
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Avatar,
  Collapse,
  Tooltip,
  Divider,
  IconButton,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FilledButton from "../components/MainButtons/FilledButton";
import NotificationDrwaer from "./NotificationDrawer";

const drawerWidth = 250;

const DashboardLayout = () => {
  const [open, setOpen] = useState(true);
  const [openGeofencing, setOpenGeofencing] = useState(false);
  const [openPayments, setOpenPayments] = useState(false);
  const [profileDrawer, setProfileDrawer] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("Dashboard"); // Add state for header title
  const [notificationDrawer, setNotificatioDrawer] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();
  const handleClickGeofencing = () => {
    setOpenGeofencing(!openGeofencing);
    setOpenPayments(false)
    // navigate("geofence/parking-zone")
  };

  const handleClickPayments = () => {
    setOpenPayments(!openPayments);
    setOpenGeofencing(false)
    // navigate("payments/overview")
  };

  const handleProfile = () => {
    setProfileDrawer(true);
  };

  const handleMenuClick = (text) => {
    setOpenGeofencing(false)
    setOpenPayments(false)
    setHeaderTitle(text); // Update header title when a menu item is clicked
  };
  const handleNotifcation = () => {
    setNotificatioDrawer(true)
  }
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuItems = [
    {
      text: "Analytics",
      icon: (
        <img
          src={analytics}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={AnalyticsNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "analytics",
    },
    {
      text: "Map",
      icon: (
        <img
          src={mapicon}
          alt="map"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={MapNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "map",
    },
    {
      text: "Vehicles",
      icon: (
        <img
          src={VehicleUpdatedIcon}
          alt="vehicle"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={VehiclesNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "vehicles",
    },
    {
      text: "User",
      icon: (
        <img
          src={usericon}
          alt="user"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={UserNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "user-management",
    },
    {
      text: "Trips",
      icon: (
        <img
          src={TripsUpdatedIcon}
          alt="trips"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={TripsNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "trips",
    },
    {
      text: "Fleet",
      icon: (
        <img
          src={fleeticon}
          alt="Fleet"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={FleetNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "fleet",
    },
    {
      text: "Geofencing",
      icon: (
        <img
          src={geofencing}
          alt="Geofencing"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={GeofenceNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      onClick: handleClickGeofencing,
      // path: "geofence/parking-zone"
    },
    {
      text: "Payments",
      icon: (
        <img
          src={payment}
          alt="payments"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={PaymentsNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      onClick: handleClickPayments,
      // path: "payments/overview"
    },
    {
      text: "Settings",
      icon: (
        <img
          src={settings}
          alt="Settings"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      hoverIcon: (
        <img
          src={SettingsNavIcon}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "settings",
    },
  ];

  return (
    <Box sx={{ display: "flex", overflowX: "hidden" }}>
      <Drawer
        sx={{
          width: open ? 250 : 80,

          flexShrink: 0,
          transition: "width 0.3s",
          "& .MuiDrawer-paper": {
            width: open ? 250 : 100,
            paddingInline: "10px",
            boxSizing: "border-box",
            background: "#283246",
            border: "none",
            transition: "width 0.3s",
            overflowX: "hidden"
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            visibility: "visible",
            width: "8.375rem",
            height: "5.625rem",
            mt: "1.563rem",
            ml: !open ? "0" : "1.563rem",
            mb: "0.313rem",

          }}
        >
          <img

            src={open ? axonsimg : IocnSmall}
          />
        </Box>

        <IconButton onClick={toggleDrawer} sx={{ color: "white", display: "flex", justifyContent: "start", gap: open ? "40px" : "" }}>
          {open ? <KeyboardDoubleArrowLeftIcon sx={{ ml: 1 }} /> : <KeyboardDoubleArrowRightIcon />}
          <Typography sx={{ fontWeight: "700", fontSize: "16px" }} > Slide </Typography>
        </IconButton>
        <List sx={{
          paddingTop: "0px",
          paddingBottom: "0px",
          overflow:"visible",
          display:"flex",
          flexDirection:"column",
          gap:"15px"

        }}   >
          {menuItems.map((item) => {
            let isActive = location.pathname.includes(item.path) || (item.onClick && location.pathname.includes(item.text.toLowerCase()));
            if(item.text=="Payments"){
              isActive=false
            } 
            return (
              <React.Fragment key={item.text}  >
                <ListItem
                  component={item.path ? Link : "div"}

                  to={item.path}
                  onClick={() => {
                    handleMenuClick(item.text);
                    item.onClick && item.onClick();
                  }}

                  sx={{
                    width: open ? "200px" : "55px",
                    borderRadius: open ? "15px" : "15px",
                    display: "flex",
                    alignItems: "center",
                    gap: 0,
                    background: isActive ? "white" : "inherit",
                    justifyContent: open ? "flex-start" : "center",
                    overflowX: "hidden",
                    paddingTop: open ? "8px" : "0px",
                    paddingBottom: open ? "8px" : "0px",
                    ":hover": {
                      cursor: "pointer"
                    }

                  }}
                >
                  {
                    open ? (
                      <>
                        <ListItemIcon sx={{
                          color: isActive ? "black" : "white",
                          minWidth: open ? "56px" : "20px",
                          marginBlock: {
                            lg: open ? "0px" : "5px",
                            xl: open ? "0px" : "10px"

                          }
                        }}>{isActive ? (item.hoverIcon) : (item.icon)}</ListItemIcon>
                      </>
                    ) : (
                      <>
                        <Tooltip
                          title={item.text}
                          placement="right"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#F99494",
                                color: "white",
                                fontWeight: "600",
                                fontSize: "14px",
                                padding: "10px 15px",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                              }
                            },
                            arrow: {
                              sx: {
                                color: "#F99494",
                              }
                            }
                          }}

                        >
                          <ListItemIcon sx={{
                            color: isActive ? "black" : "white",
                            minWidth: open ? "56px" : "20px",
                            marginBlock: {
                              lg: open ? "0px" : "5px",
                              xl: open ? "0px" : "10px"

                            }
                          }}>{isActive ? (item.hoverIcon) : (item.icon)}</ListItemIcon>
                        </Tooltip>
                      </>
                    )
                  }
                  <ListItemText sx={{ display: open ? "flex" : "none" }}  >
                    <Typography sx={{ color: isActive ? "#283246" : "white", fontSize: "14px", fontWeight: "600" }} >{item.text} </Typography>
                  </ListItemText>
                  {
                    item.text == "Geofencing" ? (
                      <Box>
                        {openGeofencing ? (<KeyboardArrowUpIcon sx={{ color: "white", mr: open ? "0px" : "-20px", }} />):(<KeyboardArrowDownIcon sx={{ color: "white", mr: open ? "0px" : "-20px",}} />)}
                      </Box>
                    ) : (<></>)
                  }
                  {
                    item.text == "Payments" ? (
                      <>
                        {
                          openPayments ?
                            (<KeyboardArrowUpIcon sx={{ color: "white",mr: open ? "0px" : "-20px", }} />) :
                            (<KeyboardArrowDownIcon sx={{ color: "white", mr: open ? "0px" : "-20px" }} />)
                        }
                      </>
                    ) : (<></>)
                  }

                </ListItem>
                <Collapse
                  in={item.text === "Geofencing" ? openGeofencing : openPayments}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" sx={{ display:'flex',flexDirection:"column",gap:"10px",marginLeft:open?"-18px":"-4px",  }} disablePadding>
                    {item.text === "Geofencing" && (
                      <>
                        <ListItem
                          component={Link}
                          to="geofence/parking-zone"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "210px" : "50px",
                            backgroundColor: location.pathname.includes("parking-zone") ? "white" : "",
                          }}>

                          {
                            open ? (<>
                              <ListItemIcon sx={{ color: "white" }}>
                                {
                                  <img
                                    src={location.pathname.includes("parking-zone") ? ParkingNavIcon : parking}
                                    alt="parking"
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                }
                              </ListItemIcon>
                            </>) : (<>
                              <Tooltip title="Parking Zone"
                                placement="right"
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#F99494",
                                      color: "white",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      padding: "10px 15px",
                                      borderRadius: "8px",
                                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                    }
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#F99494",
                                    }
                                  }
                                }}
                              >
                                <ListItemIcon sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("parking-zone") ? ParkingNavIcon : parking}
                                      alt="parking"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon>
                              </Tooltip>
                            </>)
                          }


                          <ListItemText
                            primary="Parking Zone"
                            sx={{
                              color: location.pathname.includes("parking-zone") ? "black" : "white",
                              display: open ? "flex" : "none",
                              fontSize:"16px"
                            }}
                          />
                        </ListItem>
                        <ListItem
                          component={Link}
                          to="geofence/restricted-zone"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "210px" : "50px",
                            backgroundColor: location.pathname.includes("restricted-zone") ? "white" : ""
                          }}>

                          {
                            open ? (<>
                              <ListItemIcon sx={{ color: "white" }}>
                                {
                                  <img
                                    src={location.pathname.includes("restricted-zone") ? RestrictedZoneNavIcon : restricted}
                                    alt="restricted"
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                }
                              </ListItemIcon>
                            </>) : (<>
                              <Tooltip title="Restricted Zone"
                                placement="right"
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#F99494",
                                      color: "white",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      padding: "10px 15px",
                                      borderRadius: "8px",
                                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                    }
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#F99494",
                                    }
                                  }
                                }}

                              >
                                <ListItemIcon sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("restricted-zone") ? RestrictedZoneNavIcon : restricted}
                                      alt="restricted"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon>
                              </Tooltip>
                            </>)
                          }
                          <ListItemText
                            primary="Restricted zone"
                            sx={{
                              color: location.pathname.includes("restricted-zone") ? "black" : "white",
                              display: open ? "flex" : "none",
                               fontSize:"16px"
                            }}
                          />
                        </ListItem>
                        <ListItem
                          component={Link}
                          to="geofence/service-zone"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "210px" : "50px",
                            backgroundColor: location.pathname.includes("service-zone") ? "white" : ""
                          }}
                        >

                          {
                            open ? (<>
                              <ListItemIcon sx={{ color: "white" }}>
                                {
                                  <img
                                    src={location.pathname.includes("service-zone") ? ServiceZoneNavIcon : servicezone}
                                    alt="service-zone"
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                }
                              </ListItemIcon>
                            </>) : (<>
                              <Tooltip title="Service Zone"
                                placement="right"
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#F99494",
                                      color: "white",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      padding: "10px 15px",
                                      borderRadius: "8px",
                                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                    }
                                  },
                                  arrow: {
                                    sx: {
                                      color: "#F99494",
                                    }
                                  }
                                }}
                              >
                                <ListItemIcon sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("service-zone") ? ServiceZoneNavIcon : servicezone}
                                      alt="service-zone"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon>
                              </Tooltip>
                            </>)
                          }
                          <ListItemText
                            primary="Service zone"
                            sx={{
                              display: open ? "flex" : "none",
                              color: location.pathname.includes("service-zone") ? "black" : "white",
                               fontSize:"16px"
                            }}
                          />
                        </ListItem>
                        <ListItem
                          component={Link}
                          to="geofence/slow-speed-zone"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "230px" : "50px",
                            backgroundColor: location.pathname.includes("slow-speed-zone") ? "white" : ""
                          }}
                        >

                          {open ? (<>
                            <ListItemIcon sx={{ color: "white" }}>
                              {
                                <img
                                  src={location.pathname.includes("slow-speed-zone") ? SlowSpeedZoneNavIcon : slowspeed}
                                  alt="slowspeed-zone"
                                  style={{ width: "25px", height: "25px" }}
                                />
                              }
                            </ListItemIcon>
                          </>) : (<>
                            <Tooltip title="Slow Speed Zone"

                              placement="right"
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: "#F99494",
                                    color: "white",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    padding: "10px 15px",
                                    borderRadius: "8px",
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                  }
                                },
                                arrow: {
                                  sx: {
                                    color: "#F99494",
                                  }
                                }
                              }}
                            >
                              <ListItemIcon sx={{ color: "white" }}>
                                {
                                  <img
                                    src={location.pathname.includes("slow-speed-zone") ? SlowSpeedZoneNavIcon : slowspeed}
                                    alt="slowspeed-zone"
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                }
                              </ListItemIcon>
                            </Tooltip>
                          </>)}


                          <ListItemText
                            primary="Slow speed zone"
                            sx={{
                              display: open ? "flex" : "none",
                              color: location.pathname.includes("slow-speed-zone") ? "black" : "white",
                               fontSize:"16px"
                            }}
                          />
                        </ListItem>
                      </>
                    )}
                    {item.text === "Payments" && (
                      <>
                       <ListItem
                          component={Link}
                          to="payments/overview"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "231px" : "50px",
                            backgroundColor: location.pathname.includes("overview") ? "white" : ""
                          }}>


                          {
                            open ?
                              (<>

                                <ListItemIcon
                                  component={Link}
                                  to="payments/overview"
                                  sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("overview") ? PaymentOverViewNavIcon : PaymentOverView}
                                      alt="paymentgateway"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon></>) :
                              (<>
                                <Tooltip title="Payment Overview"

                                  placement="right"
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#F99494",
                                        color: "white",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        padding: "10px 15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                      }
                                    },
                                    arrow: {
                                      sx: {
                                        color: "#F99494",
                                      }
                                    }
                                  }}>
                                  <ListItemIcon
                                    component={Link}
                                    to="payments/overview"
                                    sx={{ color: "white" }}>
                                    {
                                      <img
                                        src={location.pathname.includes("overview") ? PaymnetGatewayNavIcon : PaymentOverView}
                                        alt="paymentoverview"
                                        style={{ width: "25px", height: "25px" }}
                                      />
                                    }
                                  </ListItemIcon>
                                </Tooltip>
                              </>)
                          }

                          <ListItemText
                            primary="Payment Overview"
                            sx={{
                              display: open ? "flex" : "none",
                              color: location.pathname.includes("overview") ? "black" : "white",
                               fontSize:"16px"
                            }}
                          />
                        </ListItem>
                        <ListItem
                          component={Link}
                          to="payments/payment-gateway"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "230px" : "50px",
                            backgroundColor: location.pathname.includes("payment-gateway") ? "white" : ""
                          }}>


                          {
                            open ?
                              (<>

                                <ListItemIcon
                                  component={Link}
                                  to="payments/payment-gateway"
                                  sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("payment-gateway") ? PaymnetGatewayNavIcon : paymentgateway}
                                      alt="paymentgateway"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon></>) :
                              (<>
                                <Tooltip title="Payment Gateway"

                                  placement="right"
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#F99494",
                                        color: "white",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        padding: "10px 15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                      }
                                    },
                                    arrow: {
                                      sx: {
                                        color: "#F99494",
                                      }
                                    }
                                  }}>
                                  <ListItemIcon
                                    component={Link}
                                    to="payments/payment-gateway"
                                    sx={{ color: "white" }}>
                                    {
                                      <img
                                        src={location.pathname.includes("payment-gateway") ? PaymnetGatewayNavIcon : paymentgateway}
                                        alt="paymentgateway"
                                        style={{ width: "25px", height: "25px" }}
                                      />
                                    }
                                  </ListItemIcon>
                                </Tooltip>
                              </>)
                          }

                          <ListItemText
                            primary="Payment Gateway"
                            sx={{
                              display: open ? "flex" : "none",
                              color: location.pathname.includes("payment-gateway") ? "black" : "white",
                               fontSize:"16px"
                            }}
                          />
                        </ListItem>
                        <ListItem
                          component={Link}
                          to="payments/tex"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "210px" : "50px",
                            backgroundColor: location.pathname.includes("tex") ? "white" : ""
                          }}
                        >
                          {
                            open ?
                              (<>
                                <ListItemIcon sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("tex") ? TaxNavIcon : tax}
                                      alt="tax-zone"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon>
                              </>) :
                              (<>
                                <Tooltip title={"Tax"}

                                  placement="right"
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#F99494",
                                        color: "white",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        padding: "10px 15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                      }
                                    },
                                    arrow: {
                                      sx: {
                                        color: "#F99494",
                                      }
                                    }
                                  }}>
                                  <ListItemIcon sx={{ color: "white" }}>
                                    {
                                      <img
                                        src={location.pathname.includes("tex") ? TaxNavIcon : tax}
                                        alt="tax-zone"
                                        style={{ width: "25px", height: "25px" }}
                                      />
                                    }
                                  </ListItemIcon>
                                </Tooltip>
                              </>)
                          }
                          <ListItemText primary="Tax" sx={{
                            display: open ? "flex" : "none",
                            color: location.pathname.includes("tex") ? "black" : "white",
                             fontSize:"16px"
                          }} />
                        </ListItem>
                        <ListItem
                          component={Link}
                          to="payments/billing-plan"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "210px" : "50px",
                            backgroundColor: location.pathname.includes("billing-plan") ? "white" : ""
                          }}
                        >
                          {
                            open ?
                              (<>
                                <ListItemIcon sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("billing-plan") ? BillingPlanNavIocn : billingplan}
                                      alt="billing-plan"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon>
                              </>) :
                              (<>
                                <Tooltip title={"Billing Plan"}
                                  placement="right"
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#F99494",
                                        color: "white",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        padding: "10px 15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                      }
                                    },
                                    arrow: {
                                      sx: {
                                        color: "#F99494",
                                      }
                                    }
                                  }}>
                                  <ListItemIcon sx={{ color: "white" }}>
                                    {
                                      <img
                                        src={location.pathname.includes("billing-plan") ? BillingPlanNavIocn : billingplan}
                                        alt="billing-plan"
                                        style={{ width: "25px", height: "25px" }}
                                      />
                                    }
                                  </ListItemIcon>
                                </Tooltip>
                              </>)
                          }
                          <ListItemText
                            primary="Billing Plan"
                            sx={{
                              display: open ? "flex" : "none",
                              color: location.pathname.includes("billing-plan") ? "black" : "white",
                               fontSize:"16px"
                            }}
                          />
                        </ListItem>

                        <ListItem
                          component={Link}
                          to="payments/transaction"
                          sx={{
                            pl: open ? 3 : "11px",
                            ml: open ? "8px" : 1,
                            borderRadius: open ? "20px" : "15px",
                            width: open ? "210px" : "50px",
                            backgroundColor: location.pathname.includes("transaction") ? "white" : ""
                          }}
                        >
                          {
                            open ?
                              (<>
                                <ListItemIcon sx={{ color: "white" }}>
                                  {
                                    <img
                                      src={location.pathname.includes("transaction") ? transactionNavIcon : transaction}
                                      alt="transaction-plan"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  }
                                </ListItemIcon></>) :
                              (<>
                                <Tooltip title={"Transaction"}

                                  placement="right"
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        backgroundColor: "#F99494",
                                        color: "white",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        padding: "10px 15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                                      }
                                    },
                                    arrow: {
                                      sx: {
                                        color: "#F99494",
                                      }
                                    }
                                  }}>
                                  <ListItemIcon sx={{ color: "white" }}>
                                    {
                                      <img
                                        src={location.pathname.includes("transaction") ? transactionNavIcon : transaction}
                                        alt="transaction-plan"
                                        style={{ width: "25px", height: "25px" }}
                                      />
                                    }
                                  </ListItemIcon>
                                </Tooltip>
                              </>)
                          }
                          <ListItemText
                            primary="Transaction"
                            sx={{
                              display: open ? "flex" : "none",
                              color: location.pathname.includes("transaction") ? "black" : "white",
                               fontSize:"16px"
                            }}
                          />
                        </ListItem>
                      </>
                    )}
                  </List>
                </Collapse>
              </React.Fragment>
            )
          })}
        </List>
      </Drawer>
      <Drawer
        sx={{
          width: { lg: 600, xl: 800 },
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: { lg: 600, xl: 800 },
            boxSizing: "border-box",
            border: "none",
          },
        }}
        anchor={"right"}
        open={notificationDrawer}
        onClose={() => setNotificatioDrawer(false)}
      >
        <NotificationDrawer setNotificatioDrawer={setNotificatioDrawer} />
      </Drawer>
      <Drawer
        sx={{
          width: 400,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 400,
            boxSizing: "border-box",
            border: "none",
          },
        }}
        anchor={"right"}
        open={profileDrawer}
        onClose={() => setProfileDrawer(false)}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2, p: 1 }}>
          <IconButton onClick={() => setProfileDrawer(false)}>
            <img src={backicon} alt="back-icon" />
          </IconButton>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              ml: 2,
              fontSize: "1.375rem",
              color: "#283246",
            }}
          >
            User Profile
          </Typography>
        </Box>
        <Divider style={{ border: "2px solid #F99494" }} />

        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}> {/* Ensures the Box takes full height of the Drawer */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "30px",
              alignItems: "center",
              mt: 10,
              mb: 3,
            }}>
            <Box
              sx={{
                width: "142px",
                height: "142px",
                border: "1px solid red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Center the content inside the circle
                background: "#F99494",
                borderRadius: "50%",
                color: "white",
                border: "1px solid #283246",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // Center the text inside the Box
                  color: "white",
                  fontSize: '3.5rem',
                  fontWeight: "400"
                }}
              >
                CE
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: "600",
                  textAlign: "center",
                  color: "#283246",
                }}
              >
                Chris Evans
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  fontWeight: 600,
                  textAlign: "center",
                  color: "#283246",
                }}
              >
                chrisevans@axons.com
              </Typography>
            </Box>

            <Box>
              <FilledButton sx={{ width: "170x", justifyContent: "center" }}>
                Change Password
              </FilledButton>
            </Box>
          </Box>



          {/* This Box takes up 50% of the Drawer height */}
          <Box
            sx={{
              mt: 10,
              background: "#D9D9D9",
              display: "flex",
              flexDirection: "column", // Stack content within this Box
              justifyContent: "center", // Align content vertically in the center
              alignItems: "center", // Align content horizontally in the center
              height: "50%", // Set to half of the Drawer height
            }}
          >
            <Box sx={{ mb: 5 }}>
              <img src={axonslab} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Centers the button horizontally
                mt: 2, // Optional: Add margin-top to space it from other content
              }}
            >
              <FilledButton sx={{ width: "170px", justifyContent: "center" }}>
                Log out
              </FilledButton>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Box sx={{ width: "100%", p: 2 }}>
        <Box
          sx={{
            width: "98%",
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: "1.625rem" }}>
              {headerTitle}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100px",
              justifyContent: "space-around",
            }}
          >
            <Box>
              <Tooltip title="notification" onClick={handleNotifcation} >
                <Avatar
                  src={alert}
                  style={{ width: "35px", height: "35px", cursor: "pointer" }}
                />
              </Tooltip>
            </Box>

            <Box  >
              <Tooltip title="CE"  >
                <Avatar onClick={handleProfile} sx={{ background: "#F99494", width: "35px", height: "35px", fontSize: "15px" }}   >CE</Avatar>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;

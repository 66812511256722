import { Box, Typography, Paper, Grid, InputLabel } from "@mui/material"
import FilledButton from "../../../components/MainButtons/FilledButton"
import OutLinedButton from "../../../components/MainButtons/OutLinedButton"
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import { useState } from "react"
import getCurrentTimeFormatted from "../../../utils/TimeFormat"
import { useDispatch } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { createTax } from "../../../slices/taxSlice"
import { useSnackbar } from "../../../hooks/Snackbar"



const CreateTax = () => {

    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }

    const dispatch=useDispatch()
    const navigate=useNavigate()
    const showSnackbar=useSnackbar()

    const [formData, setFormData] = useState({
        uuid:`Uuid ${Math.floor(Math.random() * (1000000 - 10 + 1)) + 10}`,
        name: "",
        per: "",
        desc: "",
        created_date:getCurrentTimeFormatted(),
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    })

    const [errors, setErrors] = useState({
        name: false,
        per: false,
        desc: false,
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string"  && (value.includes("Select")) ),
        }));
    }
    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string"  && (value.includes("Select")) );
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };
    const handleSubmit = () => {

        if (validateForm()) {
            dispatch(createTax(formData))
            navigate("/dashboard/payments/tex")
            showSnackbar("Created Tax successfully","success")
        } else {
            console.log(errors, "Validation failed");
        }
    };
    

   
    
    console.log(formData)
    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    height: "75vh",
                    gap: 5,
                    mt: 1,
                    mb: 2,
                }}>

                <Box>
                    <Typography
                        sx={{
                            color: '#283246',
                            fontSize: "24px",
                            fontWeight: "600"
                        }}
                    > Add Tax </Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        {/* Row 1 */}
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Name <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder={"Enter Tax Name"}
                                error={errors.name}
                                helperText={errors.name ? "Name is required" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Percentage <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.per}
                                onChange={handleInputChange}
                                name="per"
                                placeholder={"Enter Percantage"}
                                error={errors.per}
                                helperText={errors.per ? "Percenrage is required" : ""}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Description  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.desc}
                                onChange={handleInputChange}
                                name="desc"
                                placeholder={"Enter Description"}
                                error={errors.desc}
                                helperText={errors.desc ? "Description is required" : ""}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Box sx={{ display: "flex", gap: "20px" }} >

                        <Box>
                            <FilledButton 
                                sx={{ width: '150px',borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}
                                onClick={()=>handleSubmit()}
                            >
                                Save
                            </FilledButton>
                        </Box>
                        <Box>
                            <FilledButton
                            onClick={()=>{navigate("/dashboard/payments/tex")}}
                            sx={{
                                border: `1px solid #97A1B6`,
                                width: "150px",
                                color: "black",
                                background: "white",
                                boxShadow: 'none',
                                "&:hover": {
                                  background: "white",
                                },
                        }} >
                                Cancel
                            </FilledButton>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default CreateTax
import { Box, Typography, Paper, Grid, InputLabel, Select, MenuItem, FormControl } from "@mui/material"
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import { useEffect, useState } from "react"
import IOSSwitch from "../../../components/Switch/IOSswitch"
import FilledButton from "../../../components/MainButtons/FilledButton"
import OutLinedButton from "../../../components/MainButtons/OutLinedButton"
import { MainMap } from "../../../components/MainMap/MainMap"
import { useDispatch, useSelector } from "react-redux"
import { createParkingZone } from "../../../slices/parkingzoneSlice"
import { useNavigate } from "react-router-dom"
import getCurrentTimeFormatted, { getTodayDate } from "../../../utils/TimeFormat"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FleetSelection from "../../../components/FleetSelector/FleetSelection"
import { createGeofence } from "../../../slices/geofenceSlice"
import { QrCodeScannerOutlined } from "@mui/icons-material"
import { useSnackbar } from "../../../hooks/Snackbar"


const CreateParkingZone = () => {

    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedFleetLocation, setSelectedFleetLocation] = useState(null)

    const [formData, setFormData] = useState({
        id: Math.floor(Math.random() * (1000000 - 10 + 1)) + 10,
        name: "",
        zone_type: "Parking Zone",
        fleet: "Select Fleet",
        desc: "",
        vehicle_exits_this_zone: [],
        lat: "",
        lng: "",
        minimum_vehicles: "10",
        maximum_vehicles: "0",
        status: "Active",
        show_oprator_app: false,
        created_by: "User1",
        created_date: getCurrentTimeFormatted(),
        updated_date: getCurrentTimeFormatted(),
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
        geofenceData: [
            {
                id: Math.floor(Math.random() * (1000000 - 10 + 1)) + 10,
                geofence_name: "",
                fleet: "",
                zone: 'Parking Zone',
                type: '',
                status: 'Active',
                throttle: 'off',
                alarm: 'on',
                create_date: getCurrentTimeFormatted(),
                create_by: "Admin",
                updated_date: getCurrentTimeFormatted(),
                coordinates: ""
            }
        ]

    })
    const showSnackbar=useSnackbar()
    console.log(formData, "this is formdata")
    const { fleetData } = useSelector((state) => state.fleetManage)
    const { geofenceData } = useSelector((state) => state.geofenceManage)

    const geofenceDetials = geofenceData.map((data) => data.geofenceData)
    console.log(geofenceDetials, "thhis is data")

    const fleetOptions = fleetData.map((fleet) => {
        return {
            name: fleet.fleet_name,
            lat: fleet.lat,
            lng: fleet.lng
        }
    })

    const handlePolygonComplete = (polygon) => {
        // Extracting vertices from the polygon
        const vertices = polygon.getPath().getArray();

        // Calculating the center of the polygon by averaging the latitudes and longitudes
        const center = vertices.reduce((acc, vertex) => {
            acc.lat += vertex.lat();
            acc.lng += vertex.lng();
            return acc;
        }, { lat: 0, lng: 0 });

        center.lat /= vertices.length;
        center.lng /= vertices.length;

        setFormData((prevFormData) => ({
            ...prevFormData,
            geofenceData: prevFormData.geofenceData.map((geofence, index) =>
                index === 0 // Assuming you want to update the first geofence
                    ? {
                        ...geofence,
                        type: 'polygon',
                        coordinates: {
                            path: vertices.map(p => ({ lat: p.lat(), lng: p.lng() })),
                            center: center
                        }
                    }
                    : geofence
            )
        }));
    };

    const handleCircleComplete = (circle) => {

        setFormData((prevFormData) => ({
            ...prevFormData,
            geofenceData: prevFormData.geofenceData.map((geofence, index) =>
                index === 0 // Assuming you want to update the first geofence
                    ? {
                        ...geofence,
                        type: 'circle',
                        coordinates: {
                            center: { lat: circle.getCenter().lat(), lng: circle.getCenter().lng() },
                            radius: circle.getRadius()
                        }
                    }
                    : geofence
            )
        }));
    };

    const [errors, setErrors] = useState({
        name: false,
        desc: false,
        zone_type: false
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
        }));
    }
    const handleFleetChange = (e) => {
        const value = e.target.value

        const selectedFleet = fleetOptions.find(fleet => fleet.name === value);

        if (selectedFleet) {
            setSelectedFleetLocation({ lat: selectedFleet.lat, lng: selectedFleet.lng })
            setFormData((prevFormData) => ({
                ...prevFormData,
                fleet: value,
                lat: selectedFleet.lat,
                lng: selectedFleet.lng,
                geofenceData: prevFormData.geofenceData.map((geofence, index) =>
                    index === 0 // Assuming you want to update the first geofence
                        ? {
                            ...geofence,
                            fleet: value,
                        }
                        : geofence
                )
            }));
        }
        else {
            setSelectedFleetLocation(null)
            setFormData({
                ...formData,
                fleet: value,
            })
        }
        setErrors((prev) => ({
            ...prev,
            fleet: value === "" || (typeof value === "string" && value.includes("Select"))
        }))

    }
    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };

    const setGeofenceName = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            geofenceData: prevFormData.geofenceData.map((geofence, index) =>
                index === 0 // Assuming you want to update the first geofence
                    ? {
                        ...geofence,
                        geofence_name: prevFormData.name, // Setting geofence_name to formData.name
                    }
                    : geofence
            )
        }));
    }


    const handleSubmit = () => {


        setGeofenceName()

        if (validateForm()) {
            console.log(formData, "this is data")
            dispatch(createGeofence(formData))
            navigate("/dashboard/geofence/parking-zone")
            showSnackbar("Parking zone created successfully","success")
        } else {
            console.log(errors, "Validation failed");
        }
    };
    const handleSwitchChange = (zoneType) => {
        setFormData(prevState => {
            const updatedExitZones = prevState.vehicle_exits_this_zone.includes(zoneType)
                ? prevState.vehicle_exits_this_zone.filter(zone => zone !== zoneType) // remove if exists
                : [...prevState.vehicle_exits_this_zone, zoneType]; // add if doesn't exist

            return {
                ...prevState,
                vehicle_exits_this_zone: updatedExitZones
            };
        });
    };
    return (
        <Paper
            sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 1,
                mb: 2,
            }}>
            <Box>
                <Typography
                    sx={{
                        color: "#283246",
                        fontSize: "24px",
                        fontWeight: "800"
                    }}
                >Add Parking Zone</Typography>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>
                            Name <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.name}
                            onChange={handleInputChange}
                            name="name"
                            error={errors.name}
                            helperText={errors.name ? "Name is required" : ""}
                            placeholder={"Enter Parking Zone Name"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl sx={{ mt: "24px" }} disabled >
                            <InputLabel sx={{ fontSize: "12px", fontWeight: 700, mb: 1, color: "#283246 !important", margin: "-13px" }}>
                                Zone Type  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <Select
                                value={formData.zone_type}
                                defaultValue={formData.zone_type}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px',
                                    color: "#97A1B6",
                                    "& .MuiSelect-select": {
                                        color: "#97A1B6",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                    },

                                    "&.Mui-disabled .MuiSelect-select": {
                                        color: "red", 
                                        zIndex:"100"
                                    },

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        backgroundColor: "#EBEBEB",
                                    },


                                }}
                                name="zone_type">
                                <MenuItem value={"Select Zone Type"}>Select Zone Type</MenuItem>
                                <MenuItem value="Parking Zone">Parking Zone</MenuItem>
                                <MenuItem value="Restricted Zone">Restricted Zone</MenuItem>
                                <MenuItem value="Service Zone">Service Zone</MenuItem>
                                <MenuItem value="Slow Speed Zone">Slow Speed Zone</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FleetSelection
                            value={formData.fleet}
                            defaultValue={formData.fleet}
                            handleFleetChange={handleFleetChange}
                            error={errors.fleet}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>
                            Description <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.desc}
                            onChange={handleInputChange}
                            placeholder={"Enter Description of Parking Zone"}
                            name="desc"
                            error={errors.desc}
                            helperText={errors.desc ? "Description is required" : ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ mt: "12px" }} >
                        <InputLabel sx={inputLabel}>
                            When a vehicle exits this zone, the following will be activated
                        </InputLabel>
                        <Box sx={{ display: "flex", gap: "15px" }} >
                            <Box sx={{ display: "flex", gap: "10px" }} >
                                <IOSSwitch onChange={() => handleSwitchChange("Throttle")} defaultChecked={formData.vehicle_exits_this_zone.includes("Throttle")} />
                                <Typography sx={{ color: "#283246", fontSize: "15px", fontWeight: "700" }} >Throttle</Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "10px" }} >
                                <IOSSwitch onChange={() => handleSwitchChange("Alert")} defaultChecked={formData.vehicle_exits_this_zone.includes("Alarm")} />
                                <Typography sx={{ color: "#283246", fontSize: "15px", fontWeight: "700" }} >Alarm</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <MainMap
                    largeHeight={"400px"}
                    extraLargeHeight={"450px"}
                    zoneType="Parking Zone"
                    enableDrawing={true}
                    geofenceData={geofenceDetials}
                    onPolygonComplete={handlePolygonComplete}
                    onCircleComplete={handleCircleComplete}
                    center={selectedFleetLocation != null ? selectedFleetLocation : { lat: 23.0230, lng: 72.5720 }}
                />
            </Box>
            <Box sx={{ marginBlock: "20px" }} >
                <Box sx={{ display: "flex", gap: "10px" }} >

                    <Box onClick={() => handleSubmit()} >
                        <FilledButton sx={{ width: '150px',  borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                            Create
                        </FilledButton>
                    </Box>
                    <Box>
                        <FilledButton

                            onClick={() => { navigate("/dashboard/geofence/parking-zone") }}
                            sx={{
                                border: `1px solid #97A1B6`,
                                width: "150px",
                                color: "black",
                                background: "white",
                                boxShadow: 'none',
                                "&:hover": {
                                  background: "white",
                                },
                            }} >
                            Cancel
                        </FilledButton>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default CreateParkingZone
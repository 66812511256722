import { Typography,Box } from "@mui/material"
import PaymentGateWayRevenurBarChart from "./Graphs/PaymentGateWayRevenueBarChart"
import FleetRevenueLineChart from "./Graphs/FleetRevenueLineChart"
import TripsRevenueLineChart from "./Graphs/TripsRevenueLineChart"


const PaymentsGraphs=()=>{
    return(
        <Box 
            sx={{
                display:"flex",
                flexDirection:"column",
                justifyContent:"space-between",
                gap:"20px"
                
            }}
        >
            <Box  >
                <PaymentGateWayRevenurBarChart/>
            </Box>
            <Box sx={{
                display:"flex",
                gap:"20px"
            }} >
                <Box sx={{width:"50%"}} >
                    <FleetRevenueLineChart/>
                </Box>
                <Box sx={{width:"50%"}} >
                    <TripsRevenueLineChart/>
                </Box>
            </Box>
        </Box>
    )
}
export default PaymentsGraphs
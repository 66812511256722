import { Box, Paper, Typography } from "@mui/material"
import FilledButton from "../../../components/MainButtons/FilledButton"
import Plus from "../../../assets/Icons/plus.svg"
import SearchTextField from "../../../components/SearchTextField"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import UserTableComponent from "../../UserManagement/UserTable"
import { TexTablesData, TaxTablesCellItems } from "../utils"
import { useDispatch, useSelector } from "react-redux"
import DeleteTaxModal from "../DeleteTax/DeleteTax"
import { deleteTax } from "../../../slices/taxSlice"
import DeleteModal from "../../../components/DeleteModal/DeleteModal"
import { useSnackbar } from "../../../hooks/Snackbar"





const TaxTable = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const showSnackbar=useSnackbar()
    const { taxData } = useSelector((state) => state.taxManage)
    console.log(taxData, "THIS IS TAX DATA")
    const [searchInput, setSearchInput] = useState("");
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteTaxId, setDeleteTaxId] = useState()
    const [deleteTaxName,setDeleteTaxName]=useState()
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const handleDeleteModalClose = () => {
        setDeleteModal(false)
    }

    const handleDeleteTax = () => {

        dispatch(deleteTax(deleteTaxId))
        setDeleteModal(false)
        showSnackbar("Created tax successfully","success")

    }

    const OpenDeleteTax = (uuid,name) => {
        // console.log(uuid)
        setDeleteModal(true)
        setDeleteTaxId(uuid)
        setDeleteTaxName(name)
    }
    const filteredTexData = searchInput ? taxData.filter((tex) => tex?.name?.toLowerCase().includes(searchInput?.toLowerCase())) : taxData
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", minHeight: "75vh" }} >
            <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                <Box  >
                    <Typography
                        sx={{ display: "flex", gap: "10px", fontSize: "24px", fontWeight: "600", color: "#283246" }}
                    >Tax
                        
                    </Typography>
                </Box>
                <Box
                    onClick={() => { navigate("/dashboard/payments/create-tex") }}
                >
                    <FilledButton
                        startIcon={<img src={Plus} style={{ width: "18px", height: "18px" }} />}
                        sx={{ width: '150px', height: '35px', borderRadius: '6px', ml: 3, fontSize: "14px", fontWeight: "600" }}


                    >
                        Add Tax
                    </FilledButton>
                </Box>
            </Box>
            <Paper
                sx={{
                    background: "#DDDEE1",
                    boxShadow: "none",
                    justifyContent: "end",
                    border: '1px solid #DDDEE1',
                    background: '#F1F1F2',
                    height: '42px',
                    p: 1,
                    borderRadius: '6px',
                    mb: 2,
                    display: "flex"

                }}>

                <Box>
                    <SearchTextField sx={{ marginBottom: 0 }} onChange={handleSearchChange} />
                </Box>
            </Paper>
            <Box>
                <UserTableComponent
                    tableHeaders={TaxTablesCellItems}
                    data={filteredTexData}
                    showActions={true}
                    handleEdit={() => { }}
                    handleDelete={OpenDeleteTax}
                    taxNavigation={true}

                />
            </Box>
            <DeleteModal
                open={deleteModal}
                handleClose={handleDeleteModalClose}
                handleDelete={handleDeleteTax}
                title={"Confirm Tax Removal"}
                description={
                    <>
                        Are you sure you want to permanently remove
                        <span style={{ fontWeight: 600 }}> {deleteTaxName} </span>?
                        This action cannot be undone, and all associated data will be lost.
                    </>
                }
                buttonText={"Yes"}
            />
        </Box>
    )
}

export default TaxTable
import { createSlice } from "@reduxjs/toolkit";
import { GeofenceData } from "../features/Geofence/utils";

const initialState={
    geofenceData:GeofenceData||[]
}

const GeofenceManageSlice=createSlice({
    name:'Geofence',
    initialState,
    reducers:{
        createGeofence:(state,action)=>{
            const NewGeofenceData=action.payload
            if(NewGeofenceData){
                state.geofenceData=[...state.geofenceData,NewGeofenceData]
            }
        },
        updateGeofence:(state,action)=>{
            console.log(state.geofenceData,"undo")
            const updatedGeofenceData = action.payload; 
            const GeofenceIndex = state.geofenceData.findIndex(geofence => geofence.id === updatedGeofenceData.id);
            console.log(GeofenceIndex,"fleet index")
            if (GeofenceIndex !== -1) {
                // Update the specific fleet data
                state.geofenceData[GeofenceIndex] = {
                    ...state.geofenceData[GeofenceIndex],
                    ...updatedGeofenceData
                };
            }
        },
        deleteGeofence:(state,action)=>{
            const geofenceId=action.payload
            console.log(geofenceId,"this is delete id")
            const geofenceToDelete=state.geofenceData.find(geofence=>geofence.id===geofenceId)

            if(geofenceToDelete){
                state.geofenceData=state.geofenceData.filter(geofence=>geofence.id!==geofenceId)
            }

        },
    }
})

export const {createGeofence,updateGeofence,deleteGeofence}=GeofenceManageSlice.actions
export default GeofenceManageSlice.reducer
import { Box, Paper, Typography } from "@mui/material"
import FilledButton from "../../../components/MainButtons/FilledButton"
import Plus from "../../../assets/Icons/plus.svg"
import SearchTextField from "../../../components/SearchTextField"
import {PaymentGatewayTablesCellItems,PaymentGatewayTablesData} from "../utils"
import { useState } from "react"
import {useNavigate} from "react-router-dom"
import UserTableComponent from "../../UserManagement/UserTable"
import { useDispatch, useSelector } from "react-redux"
import { deletePaymentGateway } from "../../../slices/paymentgatewaySlice"
import DeletePaymentGatewayModal from "../DeletePaymentGateway/DeletePaymentGateway"
import DeleteModal from "../../../components/DeleteModal/DeleteModal"
import { useSnackbar } from "../../../hooks/Snackbar"



const PaymentGatewayTable = () => {
    const navigate=useNavigate()
    const {paymentgatewayData}=useSelector((state)=>state.paymentgatewayManage)
    const [searchInput, setSearchInput] = useState("");
    const showSnackbar=useSnackbar()
    const[deleteModal,setDeleteModal]=useState(false)
    const[deletePaymentGateWayId,setDeletePaymentGateWayId]=useState()
    const [deletePaymentGatewayName,setdeletePaymentGatewayName]=useState()
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    const dispatch=useDispatch()
    const handleDeleteModalClose=()=>{
        setDeleteModal(false)
    }

    const handleDeletePaymentGateway=()=>{
        
        dispatch(deletePaymentGateway(deletePaymentGateWayId))
        setDeleteModal(false)
        showSnackbar("Deleted payment gateway successfully","success")
    
    }

    const OpenDeletePaymentGateway=(uuid,name)=>{
        // console.log(uuid)
        setDeleteModal(true)
        setDeletePaymentGateWayId(uuid)
        setdeletePaymentGatewayName(name)
    }

    const filteredPaymentGateways = searchInput? paymentgatewayData.filter((payment) =>payment?.name?.toLowerCase().includes(searchInput?.toLowerCase())): paymentgatewayData
    return (
        <Box sx={{display:"flex",flexDirection:"column",gap:"20px",minHeight:"75vh"}} > 
            <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                <Box  >
                    <Typography
                        sx={{ display: "flex", gap: "10px",fontSize:"24px",fontWeight:"600",color:"#283246" }}
                    >Payment Gateway
                     
                    </Typography>
                </Box>
                <Box 
                     onClick={()=>{navigate("/dashboard/payments/create-payment-gateway")}}
                >
                    <FilledButton
                        startIcon={<img src={Plus} style={{ width: "18px", height: "18px" }} />}
                        sx={{ width: '220px', height: '35px', borderRadius: '6px', ml: 3,fontSize: "14px", fontWeight: "600" }}
                        

                    >
                        Add Payment Gateway
                    </FilledButton>
                </Box>
            </Box>
            <Paper
                sx={{
                    boxShadow: "none",
                    padding:"5px",
                    display:"flex",
                    justifyContent:"end",
                    border: '1px solid #DDDEE1',
                    background: '#F1F1F2',
                    height: '42px', 
                    p: 1, 
                    borderRadius: '6px', 
                    mb: 2,
                    display:"flex"
                    
                }}>

                  <Box>
                      <SearchTextField sx={{marginBottom:0}} onChange={handleSearchChange} />
                  </Box>
                </Paper>
            <Box>
                <UserTableComponent
                tableHeaders={PaymentGatewayTablesCellItems}
                data={filteredPaymentGateways}
                showActions={true}
                handleEdit={()=>{}}
                handleDelete={OpenDeletePaymentGateway}
                paymentGatewayNavigation={true}
                
                />
            </Box>
            <DeleteModal
            open={deleteModal}
            handleClose={handleDeleteModalClose}
            handleDelete={handleDeletePaymentGateway}
            title={"Confirm Payment Gateway Removal"}
            description={
                <>
                    Are you sure you want to permanently remove
                    <span style={{ fontWeight: 600 }}> {deletePaymentGatewayName} </span>?
                    This action cannot be undone, and all associated data will be lost.
                </>
            }
            buttonText={"Yes"}
            />
        </Box>
    )
}

export default PaymentGatewayTable
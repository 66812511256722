import React from "react";
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Divider,
    useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


import DeleteIcon from "../../assets/Icons/delete.svg"

import FilledButton from "../MainButtons/FilledButton";






const DeleteModal = ({ open, handleClose, handleDelete,title,description,buttonText }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "576px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 1.5,
                    borderRadius: "15px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                        id="modal-title"
                        sx={{ color: "#283246", fontSize: '24px', fontWeight: 600 }}
                    >
                      {title}
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ fontSize: "24px", fontWeight: "600", color: "#283246" }} >
                        <CloseIcon />
                    </IconButton>
                </Box>
    
                <Box>
                    <Divider />
                </Box>
                <Box
                    sx={{
                        background: "#283246",
                        width: "2.75rem",
                        height: "2.75rem",
                        padding: "0.438rem",
                        borderRadius: "1.25rem",
                        cursor: "pointer",
                        margin: 'auto',
                        mt: 2,
    
                    }}
                >
                    <img src={DeleteIcon} alt="delete-icon" style={{ width: '42px', height: '42px' }} />
                </Box>
    
    
                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center", gap: "10px" }} >
                    <Typography
                        id="modal-description"
                        sx={{ color: '#283246', width: '80%', margin: 'auto', p: 2, fontSize: '1rem', fontWeight: 500 }}
                    >
                        {description}
                    </Typography>
    
                </Box>
    
                <Box sx={{display:'flex',justifyContent:"center",gap:"10px",mb:"20px"}} >
    
                    <Box>
                        <FilledButton
                            sx={{ width: '150px',borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}
                            onClick={handleDelete}
                        >
                            {buttonText}
                        </FilledButton>
                    </Box>
    
    
                    <Box onClick={handleClose}>
                        <FilledButton sx={{
                    border: `1px solid #97A1B6`,
                    width: "150px",
                    color: "black",
                    background: "white",
                    boxShadow: 'none',
                    "&:hover": {
                      background: "white",
                    },
                  }}  >
                            Cancel
                        </FilledButton>
                    </Box>
                </Box>
    
            </Box>
        </Modal>
    );
};

export default DeleteModal;

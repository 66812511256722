import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Typography, Paper, IconButton, Box, Chip ,Card,Avatar,useTheme} from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';
import TotalTripsColorIocn from "../../../assets/Icons/TotalTripsUpdatedIcon.svg"
import DistanceIcon from "../../../assets/Icons/DistanceIcon.svg"
import RevenueGenrated from "../../../assets/Icons/AverageRevnueIcon.svg"
import AnimatedNumber from '../../../components/AnimateNumbers';
import { useNavigate } from 'react-router-dom';


const VehiclesCards = ({ vehicle_type, vehicle_name, vehicle_status, total_trips, distance_travelled, revenue_genrated }) => {

    const navigate=useNavigate()
    
    const CardData = [
        { name: "Total Trips", value: total_trips, icon: TotalTripsColorIocn },
        { name: 'Distance Travelled', value: distance_travelled, icon: DistanceIcon },
        { name: "Revenue Generated", value: revenue_genrated, icon: RevenueGenrated }
    ]
    return (
        <Paper
            sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                display: "flex",
                flexDirection: "column",
                boxShadow: "none",
                gap: 2,
                mt: 1,
                mb: 2,
            }}
        >

            <Box sx={{ display: "flex", alignItems: "center" }} >
                <IconButton onClick={()=>navigate(-1)} >
                    <KeyboardBackspaceIcon sx={{color:"#535A6A",fontSize:"30px"}} />
                </IconButton>
                <Typography sx={{ color: "#283246", fontSize: '1.5rem', fontWeight: 600 }} >
                    {vehicle_type} -
                </Typography>
                <Typography sx={{ color: "#283246", fontSize: '1.5rem', fontWeight: 600 }}  >
                    {vehicle_name}
                </Typography>
            </Box>
            <Box  >
                {
                    vehicle_status == "Connected" ?
                        (<>
                            <Chip
                                label="Vehicle Connected"
                                variant="outlined"
                                icon={<CircleIcon color="#83C3A0" sx={{ width:"12px" }} />}
                                size="small"
                                sx={{
                                    color: "#83C3A0",
                                    background: "#F1F7F3",
                                    border:"none",
                                    fontSize: "16px",
                                    fontWeight:"600"
                                }}
                            />
                        </>) :
                        (<>
                            <Chip
                                label="Vehicle not Connected"
                                variant="outlined"
                                size="small"
                                icon={<CircleIcon color="#F99494" sx={{ width:"12px" }} />}
                                sx={{
                                    color: "#F99494",
                                    background: "#FFF3F3",
                                    border:"none",
                                    fontSize: "16px",
                                    fontWeight:"600"
                                }}
                            />
                        </>)
                }
            </Box>
          <Box sx={{display:"flex",justifyContent:"space-between"}} > 
          {CardData.map((user) => (
                <Card
                    sx={{
                        width: "20%",
                        borderRadius: "1rem",
                        padding: "1rem 2.438rem",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Center content horizontally
                        boxShadow: "none",
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: "50%",
                            width: "70px",
                            height: "70px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mb: 1,
                            backgroundColor:'#97A1B61A'
                        }}
                    >
                        <img src={user.icon} height="60px" />
                    </Box>

                    <Typography
                        sx={{
                            fontSize: "16px",
                            color: "#283246",
                            fontWeight: 500,
                            marginBottom: "0.5rem"
                        }}
                    >
                        {user.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "1.375rem",
                            color: "#283246",
                            fontWeight: 700,
                        }}
                    >
                        
                        <AnimatedNumber value={user.value} />
                    </Typography>
                </Card>
            ))}
          </Box>
        </Paper>
    )
}

export default VehiclesCards
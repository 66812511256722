import { Box, Grid, Typography, Paper, Divider, Card, CardContent, Avatar } from "@mui/material"
import UserIcon from "../../../assets/Icons/UserNewIcon.svg"
import VehicleIcon from "../../../assets/Icons/VehicleNewIcon.svg"
import TripsIcon from "../../../assets/Icons/TripsNewIcon.svg"
import { useState } from "react"
import AnimatedNumber from "../../../components/AnimateNumbers"
import { AvTimer } from "@mui/icons-material"



export const AnalyticsCards = () => {

    return (
        <Box sx={{ display: "flex", gap: "20px" }} >
            <Card sx={{
                flex: "1 1 30%",
                borderRadius: "30px",
                boxShadow: 2,
                padding: 1,
                backgroundColor: '#fff'
            }}>
                <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 2,
                    }}>
                        <Box sx={{ flex: "1 1 40%" }} >
                            <Avatar src={UserIcon} sx={{height:"43px"}} />
                        </Box>
                        <Box sx={{ flex: "1 1 50%" }} >
                            <Typography sx={{ color: '#283246', fontWeight: "600", 
                            fontSize:{
                                lg:"16px",
                                xl: "20px"
                            } }}>
                                User
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container alignItems="stretch" sx={{ paddingLeft: {lg:"10px",xl:"20px"} } } >
                        <Grid item xs={5}>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",fontSize:{ lg:"20px",xl:"28px" },textAlign:"center"  }} component="div" gutterBottom>
                                    <AnimatedNumber value={"100"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",fontSize:{ lg:"16px",xl:"20px" },textAlign:"center" }} >Total User</Typography>
                            </Box>
                            <Divider sx={{ marginY: 2 }} />
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",fontSize:{ lg:"20px",xl:"28px" },textAlign:"center"  }} component="div">
                                <AnimatedNumber value={"70"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246", fontSize:{ lg:"16px",xl:"20px" }, textAlign: 'center' }} >Deactivated Users</Typography>
                            </Box>
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                        <Grid item xs={5}>
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",fontSize:{ lg:"20px",xl:"28px" },textAlign:"center"  }} component="div" gutterBottom>
                                <AnimatedNumber value={"20"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246", fontSize:{ lg:"16px",xl:"20px" },textAlign:"center"  }} >Activate</Typography>
                            </Box>
                            <Divider sx={{ marginY: 2 }} />
                            <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",fontSize:{ lg:"20px",xl:"28px" },textAlign:"center"  }} component="div">
                                <AnimatedNumber value={"50"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",fontSize:{ lg:"16px",xl:"20px" },textAlign:"center"  }}  >Active on Trips</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{
                flex: "1 1 30%",
                borderRadius: "30px",
                boxShadow: 2,
                padding: 1,
                backgroundColor: '#fff'
            }}>
                <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 2,
                    }}>
                        <Box sx={{ flex: "1 1 40%" }} >
                            <Avatar src={VehicleIcon}  sx={{height:"43px"}}  />
                        </Box>
                        <Box sx={{ flex: "1 1 50%" }} >
                            <Typography sx={{ color: '#283246', fontWeight: "600", fontSize:{
                                lg:"16px",
                                xl: "20px"
                            } }}>
                                Vehicle
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container alignItems="stretch" sx={{ paddingLeft: {lg:"10px",xl:"20px"}}} >
                        <Grid item xs={5}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div" gutterBottom>
                                    <AnimatedNumber value={"78"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",textAlign:"center",fontSize:{ lg:"16px",xl:"20px" },  }} >Total Vehicles</Typography>
                            </Box>
                            <Divider sx={{ marginY: 2 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div">
                                <AnimatedNumber value={"20"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",textAlign:"center",fontSize:{ lg:"16px",xl:"20px" },  }} >In Use</Typography>
                            </Box>
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                        <Grid item xs={5}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div" gutterBottom>
                                <AnimatedNumber value={"50"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",textAlign:"center",fontSize:{ lg:"16px",xl:"20px" },  }} >Total idle</Typography>
                            </Box>
                            <Divider sx={{ marginY: 2 }} />
                           <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                           <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div">
                           <AnimatedNumber value={"8"} />
                            </Typography>
                            <Typography color="text.secondary" sx={{ color: "#283246",textAlign:'center',fontSize:{ lg:"16px",xl:"20px" }, }}  >Unassigned Vehicle</Typography>
                           </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{
                flex: "1 1 30%",
                borderRadius: "30px",
                boxShadow: 2,
                padding: {lg:0,xl:1},
                backgroundColor: '#fff'
            }}>
                <CardContent sx={{ "&:last-child": { paddingBottom: 2 } }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 2,
                    }}>
                        <Box sx={{ flex: "1 1 40%" }} >
                            <Avatar src={TripsIcon}   sx={{height:"43px"}}  />
                        </Box>
                        <Box sx={{ flex: "1 1 50%" }} >
                            <Typography sx={{ color: '#283246', fontWeight: "600",fontSize:{
                                lg:"16px",
                                xl: "20px"
                            } }}>
                                Trips
                            </Typography>
                        </Box>
                    </Box>
                    <Grid container alignItems="stretch" sx={{ paddingLeft: {lg:"10px",xl:"20px"} }} >
                        <Grid item xs={5}   >
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div" gutterBottom>
                                <AnimatedNumber value={"300"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",textAlign:"center",fontSize:{ lg:"16px",xl:"20px" },  }} >Total Trips</Typography>
                            </Box>
                            <Divider sx={{ marginY: 2 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}  >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div">
                                <AnimatedNumber value={"300"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",textAlign:"center",fontSize:{ lg:"16px",xl:"20px" },  }} >Trips Per Vehicle</Typography>
                            </Box>
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                        <Grid item xs={5}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div" gutterBottom>
                                <AnimatedNumber value={"50"} />
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",textAlign:"center",fontSize:{ lg:"16px",xl:"20px" },  }} >Today Trips</Typography>
                            </Box>
                            <Divider sx={{ marginY: 2 }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h4" sx={{ color: '#F99494', fontWeight: "600",display:'flex',gap:"5px",textAlign:"center",fontSize:{ lg:"20px",xl:"28px" },  }} component="div">
                                <AnimatedNumber value={"8"} /> min
                                </Typography>
                                <Typography color="text.secondary" sx={{ color: "#283246",textAlign:"center",fontSize:{ lg:"16px",xl:"20px" }, }}  >Avg Trip Duration</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}
import { Box } from "@mui/material"
import UsersAreaChart from "./Graphs/UsersAreaChart"
import TotalUserPieChart from "./Graphs/TotalUserPieChart"
import AverageTripLineChart from "./Graphs/AverageTripLineChart"
import TripsRatingsSemiPieChart from "./Graphs/TripsRatingsSemiPieChart"
import TripDurationPieChart from "./Graphs/TripDurationPieChart"
import VechicleConnectedPieChart from "./Graphs/VechicleConnectedPieChart"
import VechiclesLineChart from "./Graphs/VehiclesLineChart"
import NumberOfTripsBarChart from "./Graphs/NumberOfTripsBarChart"


export const AnalyticsGraphs=()=>{
    return(
        <Box sx={{display:"flex",flexDirection:"column",gap:"24px"}}>
            <Box sx={{display:"flex",gap:"24px",flexDirection:"row"}}>
                <Box 
                sx={{flex:1}} 
                
                >
                    <UsersAreaChart/>
                </Box>
                <Box 
               
               sx={{minWidth:{
                lg:"330px",
                xl:"490px"
            }}}
               
                
                >
                    <TotalUserPieChart/>
                </Box>
                
            </Box>
            <Box >
                <Box>
                    <NumberOfTripsBarChart/>
                </Box>
            </Box>
            <Box sx={{display:"flex",gap:"24px"}}>
                <Box sx={{flex:1}} >
                    <AverageTripLineChart/>
                </Box>
                <Box   >
                    <TripsRatingsSemiPieChart/>
                </Box>
                <Box  sx={{flex:1 }} > 
                    <TripDurationPieChart/>
                </Box>
            </Box>
            <Box sx={{display:"flex",gap:"24px"}}>
                <Box sx={{
                    
                }} >
                    <VechicleConnectedPieChart/>
                </Box>
                <Box sx={{flex:1}} >
                    <VechiclesLineChart/>
                </Box>
            </Box>
        </Box>
    )
}

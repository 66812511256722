import { IconButton, Paper, Box, Typography, InputLabel, Select, MenuItem, Chip, Grid, Tab, Tabs, FormControl, FormHelperText } from "@mui/material"
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useState } from "react";
import CustomTextField from "../../../components/MainTextField/CustomTextField";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LinkTimeline from "../../../assets/Icons/LinkTimeLine.svg"
import { useNavigate } from "react-router-dom"
import FilledButton from "../../../components/MainButtons/FilledButton";
import OutLinedButton from "../../../components/MainButtons/OutLinedButton";
import { updateFleet } from "../../../slices/fleetSlice";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MainMap } from "../../../components/MainMap/MainMap";
import {useSnackbar} from "../../../hooks/Snackbar"

const tabStyles = {
    width: '191px',
    height: '40px',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#F99494',
    '&.Mui-selected': {
        background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
        color: '#FFFFFF',
        borderRadius: '12px',
    },
};
const EditFleetForm = ({
    fleet_id,
    address,
    alert_email,
    auto_close_vehicle,
    auto_end_ride_critical_battery_vehicle,
    auto_end_ride_ideal_vehicle,
    auto_end_ride_paused_vehicle,
    billing_plan,
    distance_lock_unlock_vehicle,
    fleet_name,
    fleet_type,
    lat,
    lng,
    location,
    low_battery_level,
    parking_requirment,
    pause_ride,
    support_email,
    support_phone_number,
    activity
}) => {
    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }
    const [value, setValue] = useState(0)
    const [markerPosition, setMarkerPosition] = useState(null);
    const showSnackbar=useSnackbar()
    const dispatch = useDispatch()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.log(activity, "this is updated")

    const [formData, setFormData] = useState({
        fleet_id: fleet_id,
        fleet_name: fleet_name,
        support_email: support_email,
        alert_email: alert_email,
        fleet_type: fleet_type,
        billing_plan: billing_plan,
        address: address,
        lat: lat,
        lng: lng,
        support_phone_number: support_phone_number,
        parking_requirment: parking_requirment,
        auto_end_ride_critical_battery_vehicle: auto_end_ride_critical_battery_vehicle,
        distance_lock_unlock_vehicle: distance_lock_unlock_vehicle,
        auto_end_ride_ideal_vehicle: auto_end_ride_ideal_vehicle,
        low_battery_level: low_battery_level,
        pause_ride: pause_ride,
        auto_end_ride_paused_vehicle: auto_end_ride_paused_vehicle,
        auto_close_vehicle: auto_close_vehicle
    })
    
    const handleLocationSelected = (location) => {
        // Update the marker position state with the selected lat/lng
        setMarkerPosition(location);
        console.log("Selected Location:", location);  // Logs the latitude and longitude

        setFormData({...formData,lat:location.lat,lng:location.lng})
    };
    
    const [errors, setErrors] = useState({
        fleet_name: false,
        support_email: false,
        alert_email: false,
        fleet_type: false,
        billing_plan: false,
        address: false,
        lat: false,
        lng: false,
        parking_requirment: false,
        support_phone_number: false,
        auto_end_ride_paused_vehicle: false,
        auto_end_ride_critical_battery_vehicle: false,
        low_battery_level: false,
        distance_lock_unlock_vehicle: false

    })



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors((prev) => ({
            ...prev,
            [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
        }));
    };

    const validateForm = () => {
        let hasErrors = false;
        const newErrors = Object.keys(formData).reduce((acc, key) => {
            const value = formData[key];
            acc[key] = value === "" || (typeof value === "string" && (value.includes("Select")));
            if (acc[key]) hasErrors = true;
            return acc;
        }, {});

        setErrors(newErrors);
        return !hasErrors;
    };

    const handleSubmit = () => {
        if (validateForm()) {
            dispatch(updateFleet(formData))
            navigate("/dashboard/fleet")
            showSnackbar("Edited fleet successfully", "success");
        }
        else {
            console.log(errors, "Validation failed");
        }
    }

    useEffect(() => {
        if (formData.parking_requirment === "Select Parking Requirment") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                parking_requirment: "Not Required",
            }));
        }

        if (formData.auto_end_ride_ideal_vehicle === "Select Auto End Ride Requirment") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                auto_end_ride_ideal_vehicle: "Not Required",
            }));
        }

        if (formData.auto_close_vehicle === "Select Auto close vehicle") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                auto_close_vehicle: "No",
            }));
        }

        if (formData.pause_ride === "Select Ride Pause") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                pause_ride: "No",
            }));
        }
    }, [validateForm])

    console.log(formData, "this is fleet form data")
    const navigate = useNavigate()
    return (
        <Box>
            <Box sx={{ marginBlock: "20px" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Active Users"
                    sx={{
                        bgcolor: '#F8F8F8',
                        width: "fit-content",
                        borderRadius: '12px',
                        border: '1px solid #DDDEE1',
                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                    }}
                >
                    {['Fleet Information', 'Advance Settings', 'Timeline'].map((label, index) => (
                        <Tab
                            key={label}
                            disableRipple
                            label={label}
                            sx={tabStyles}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box>
                {
                    value == 0 ? (
                        <Box>
                            <Grid container spacing={2}>
                                {/* Row 1 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Name <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.fleet_name}
                                        onChange={handleInputChange}
                                        name="fleet_name"
                                        error={errors.fleet_name}
                                        helperText={errors.fleet_name ? "Fleet Name is required" : ""}
                                        placeholder={"Enter Fleet Name"}
                                    />
                                </Grid>

                                {/* Row 2 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Support Email <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.support_email}
                                        onChange={handleInputChange}
                                        name="support_email"
                                        error={errors.support_email}
                                        helperText={errors.support_email ? "Support Email is required" : ""}
                                        placeholder={"Enter Support Email"}
                                    />
                                </Grid>

                                {/* Row 3 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Alert Email <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.alert_email}
                                        onChange={handleInputChange}
                                        name="alert_email"
                                        error={errors.alert_email}
                                        helperText={errors.alert_email ? "Alert Email is required" : ""}
                                        placeholder={"Enter Alert Email"}
                                    />
                                </Grid>

                                {/* Row 4 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Fleet Type <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <FormControl error={errors.fleet_type} >
                                        <Select
                                            value={formData.fleet_type}
                                            defaultValue={formData.fleet_type}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="fleet_type"

                                        >
                                            <MenuItem value={"Select Fleet Type"}>Select Fleet Type</MenuItem>
                                            <MenuItem value="Private">Private</MenuItem>
                                            <MenuItem value="Public">Public</MenuItem>
                                        </Select>
                                        {errors.fleet_type && (
                                            <FormHelperText>
                                                Fleet type is required
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                {/* Row 5 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Billing Plan <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <FormControl error={errors.billing_plan} >
                                        <Select
                                            value={formData.billing_plan}
                                            defaultValue={formData.billing_plan}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="billing_plan"

                                        >
                                            <MenuItem value={"Select Billing Plan"}>Select Billing Type</MenuItem>
                                            <MenuItem value="Private">
                                                <Box>
                                                    <Chip
                                                        label="Private Billing Plan"
                                                        variant="outlined"
                                                        size="small"
                                                        icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                                                        sx={{
                                                            color: "#F99494",
                                                            background: "#FFF3F3",
                                                            borderColor: "#F99494",
                                                            fontSize: "16px",
                                                            fontWeight: "600"
                                                        }}
                                                    />
                                                </Box>
                                            </MenuItem>
                                            <MenuItem value="Public">
                                                <Box>
                                                    <Chip
                                                        label="Public Billing Plan"
                                                        variant="outlined"
                                                        icon={<CircleIcon color="#83C3A0" />}
                                                        size="small"
                                                        sx={{
                                                            color: "#83C3A0",
                                                            background: "#F1F7F3",
                                                            borderColor: "#83C3A0",
                                                            fontSize: "16px",
                                                            fontWeight: "600"
                                                        }}
                                                    />
                                                </Box>
                                            </MenuItem>
                                        </Select>
                                        {errors.billing_plan && (
                                            <FormHelperText>
                                                Billing Plan is required
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                {/* Row 6 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Address <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        name="address"
                                        placeholder={"Enter Fleet Address"}
                                        error={errors.address}
                                        helperText={errors.address ? "Addess is required" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Location Latitude  <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.lat}
                                        onChange={handleInputChange}
                                        name="lat"
                                        placeholder={"Enter Fleet Latitude"}
                                        error={errors.lat}
                                        helperText={errors.lat ? "Latitude is required" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Location Longitude  <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.lng}
                                        onChange={handleInputChange}
                                        name="lng"
                                        placeholder={"Enter Fleet Longitude"}
                                        error={errors.lng}
                                        helperText={errors.lng ? "Longitude is required" : ""}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Support Phone Number <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.support_phone_number}
                                        onChange={handleInputChange}
                                        name="support_phone_number"
                                        error={errors.support_phone_number}
                                        helperText={errors.support_phone_number ? "Support Phone Number is required" : ""}
                                        placeholder={"Enter Support Phone Number"}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ flex: 1, marginBlock: "20px" }} >
                                <MainMap
                                    largeHeight={"550px"}
                                    extraLargeHeight={"550px"}
                                    enablePin={true}                         
                                    onLocationSelected={handleLocationSelected}  
                                    markerlocation={{lat:formData.lat,lng:formData.lng}}            
                                    livelocation={true}    
                                    center={{lat:formData.lat,lng:formData.lng}}  
                                />
                            </Box>
                            <Box sx={{ marginBlock: "20px" }} >
                                <Box sx={{ display: "flex", gap: "10px" }} >

                                    <Box onClick={() => handleSubmit()} >
                                        <FilledButton sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                            Save Changes
                                        </FilledButton>
                                    </Box>
                                    <Box>
                                        <FilledButton
                                            onClick={() => { navigate("/dashboard/fleet") }}
                                            sx={{
                                                border: `1px solid #97A1B6`,
                                                width: "150px",
                                                color: "black",
                                                background: "white",
                                                boxShadow: 'none',
                                                "&:hover": {
                                                  background: "white",
                                                },
                                            }} >
                                            Cancel
                                        </FilledButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    ) : (<></>)
                }
                {
                    value == 1 ? (
                        <Box>
                            <Grid container spacing={2}>
                                {/* Row 1 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Parking requirement</InputLabel>
                                    <Select
                                        value={formData.parking_requirment}
                                        defaultValue={formData.parking_requirment}
                                        onChange={handleInputChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            borderRadius: "10px",
                                            width: {
                                                xs: "100px",
                                                sm: "300px",
                                                md: "400px",
                                                lg: "300px",
                                                xl: "400px"
                                            },
                                            height: '40px',
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#283246",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "& .MuiSelect-select": {
                                                color: "#283246",
                                                fontSize: "14px",
                                                fontWeight: 500, 
                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                            },


                                        }}
                                        name="parking_requirment"
                                    >
                                        <MenuItem value={"Select Parking Requirment"}>Select Parking requirement</MenuItem>
                                        <MenuItem value="Required">Required</MenuItem>
                                        <MenuItem value="Not Required">Not Required</MenuItem>
                                    </Select>
                                </Grid>

                                {/* Row 2 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Auto end ride - idle vehicle </InputLabel>
                                    <Select
                                        value={formData.auto_end_ride_ideal_vehicle}
                                        defaultValue={formData.auto_end_ride_ideal_vehicle}
                                        onChange={handleInputChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            borderRadius: "10px",
                                            width: {
                                                xs: "100px",
                                                sm: "300px",
                                                md: "400px",
                                                lg: "300px",
                                                xl: "400px"
                                            },
                                            height: '40px',
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#283246",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "& .MuiSelect-select": {
                                                color: "#283246",
                                                fontSize: "14px",
                                                fontWeight: 500, 
                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                            },


                                        }}
                                        name="auto_end_ride_ideal_vehicle"
                                    >
                                        <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                                        <MenuItem value="Required">Required</MenuItem>
                                        <MenuItem value="Not Required">Not Required</MenuItem>
                                    </Select>
                                </Grid>

                                {/* Row 3 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Auto end ride - paused vehicle  <span style={{ color: "#F99494" }}>*</span></InputLabel>
                                    <FormControl error={errors.auto_end_ride_paused_vehicle} >
                                        <Select
                                            value={formData.auto_end_ride_paused_vehicle}
                                            defaultValue={formData.auto_end_ride_paused_vehicle}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="auto_end_ride_paused_vehicle"
                                        >
                                            <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                                            <MenuItem value="Required">Required</MenuItem>
                                            <MenuItem value="Not Required">Not Required</MenuItem>
                                        </Select>
                                        {errors.auto_end_ride_paused_vehicle && (
                                            <FormHelperText>
                                                Required Filed
                                            </FormHelperText>
                                        )}

                                    </FormControl>
                                </Grid>

                                {/* Row 4 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Auto end ride - critical battery vehicle  <span style={{ color: "#F99494" }}>*</span> </InputLabel>
                                    <FormControl error={errors.auto_end_ride_critical_battery_vehicle} >
                                        <Select
                                            value={formData.auto_end_ride_critical_battery_vehicle}
                                            defaultValue={formData.auto_end_ride_critical_battery_vehicle}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="auto_end_ride_critical_battery_vehicle"
                                        >
                                            <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                                            <MenuItem value="Applicable">Applicable</MenuItem>
                                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                                        </Select>
                                        {errors.auto_end_ride_critical_battery_vehicle && (
                                            <FormHelperText>
                                                Required Filed
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                {/* Row 5 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Low battery level <span style={{ color: "#F99494" }}>*</span> </InputLabel>

                                    <FormControl error={errors.low_battery_level} >
                                        <Select
                                            value={formData.low_battery_level}
                                            defaultValue={formData.low_battery_level}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="low_battery_level"
                                        >
                                            <MenuItem value={"Select Low battery level"}>Select Low battery level</MenuItem>
                                            <MenuItem value="50">50%</MenuItem>
                                            <MenuItem value="40">40%</MenuItem>
                                            <MenuItem value="30">30%</MenuItem>
                                            <MenuItem value="20">20%</MenuItem>
                                            <MenuItem value="10">10%</MenuItem>
                                            <MenuItem value="0">0%</MenuItem>
                                        </Select>
                                        {errors.low_battery_level && (
                                            <FormHelperText>
                                                Required Filed
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                {/* Row 6 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Auto close vehicle</InputLabel>
                                    <Select
                                        value={formData.auto_close_vehicle}
                                        defaultValue={formData.auto_close_vehicle}
                                        onChange={handleInputChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            borderRadius: "10px",
                                            width: {
                                                xs: "100px",
                                                sm: "300px",
                                                md: "400px",
                                                lg: "300px",
                                                xl: "400px"
                                            },
                                            height: '40px',
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#283246",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "& .MuiSelect-select": {
                                                color: "#283246",
                                                fontSize: "14px",
                                                fontWeight: 500, 
                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                            },


                                        }}
                                        name="auto_close_vehicle"
                                    >
                                        <MenuItem value={"Select Auto close vehicle"}>Select Auto close vehicle</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Distance to unlock & lock vehicle  <span style={{ color: "#F99494" }}>*</span> </InputLabel>
                                    <FormControl error={errors.distance_lock_unlock_vehicle} >
                                        <Select
                                            value={formData.distance_lock_unlock_vehicle}
                                            defaultValue={formData.distance_lock_unlock_vehicle}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px',
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#283246",
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#DDDEE1", 
                                                },
                                                "& .MuiSelect-select": {
                                                    color: "#283246",
                                                    fontSize: "14px",
                                                    fontWeight: 500, 
                                                    fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                                },


                                            }}
                                            name="distance_lock_unlock_vehicle"
                                        >
                                            <MenuItem value={"Select Distance"}>Select Distance</MenuItem>
                                            <MenuItem value="30">30 meter</MenuItem>
                                            <MenuItem value="20">20 meter</MenuItem>
                                            <MenuItem value="10">10 meter</MenuItem>
                                            <MenuItem value="0">0 meter</MenuItem>
                                        </Select>
                                        {errors.distance_lock_unlock_vehicle && (
                                            <FormHelperText>
                                                Required Filed
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>Pause Ride</InputLabel>
                                    <Select
                                        value={formData.pause_ride}
                                        defaultValue={formData.pause_ride}
                                        onChange={handleInputChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            borderRadius: "10px",
                                            width: {
                                                xs: "100px",
                                                sm: "300px",
                                                md: "400px",
                                                lg: "300px",
                                                xl: "400px"
                                            },
                                            height: '40px',
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#283246",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#DDDEE1", 
                                            },
                                            "& .MuiSelect-select": {
                                                color: "#283246",
                                                fontSize: "14px",
                                                fontWeight: 500, 
                                                fontFamily: '"Helvetica Neue", Arial, sans-serif',
                                            },


                                        }}
                                        name="pause_ride"
                                    >
                                        <MenuItem value={"Select Ride Pause"}>Select Ride Pause</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Box sx={{ marginBlock: "20px" }} >
                                <Box sx={{ display: "flex", gap: "10px" }} >

                                    <Box onClick={() => {
                                        dispatch(updateFleet(formData))
                                        navigate("/dashboard/fleet")
                                    }
                                    } >
                                        <FilledButton sx={{ width: '150px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                            Save Changes
                                        </FilledButton>
                                    </Box>
                                    <Box>
                                        <FilledButton sx={{
                                               border: `1px solid #97A1B6`,
                                               width: "150px",
                                               color: "black",
                                               background: "white",
                                               boxShadow: 'none',
                                               "&:hover": {
                                                 background: "white",
                                               },
                                        }} >
                                            Cancel
                                        </FilledButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ) : (<></>)
                }
                {



                    value == 2 ? (<Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px"
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    color: "#283246",
                                    fontSize: "24px",
                                    fontWeight: "600"
                                }}
                            >
                                {location}

                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    color: "#28324680",
                                    textAlign: "center",
                                    paddingInline: "150px",
                                    fontWeight: "500"
                                }}
                            >
                                This is timeline for fleet here you can see activity that done with fleet like create and update with time.
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }} >
                            <Timeline position="alternate" >
                                {
                                    activity.map((activity, key) => {
                                        return (
                                            <TimelineItem >
                                                <TimelineSeparator
                                                    sx={{ paddingInline: key % 2 !== 0 ? "140px" : "5px" }}
                                                >
                                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                                    <TimelineConnector sx={{ background: "#97A1B6", width: "5px" }} />
                                                </TimelineSeparator>
                                                <TimelineContent

                                                >

                                                    <Box
                                                        sx={{
                                                            width: key % 2 !== 0 ? "135%" : "93%",
                                                            background: "#ffff",
                                                            display: "flex",
                                                            paddingBlock: "20px",
                                                            flexDirection: "row",
                                                            gap: {
                                                                lg: "20px",
                                                                xl: "33px"
                                                            },
                                                            paddingInline: "20px",
                                                            borderRadius: "20px"
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                backgroundColor: "#F994941A",

                                                                width: "fit-content",
                                                                padding: {
                                                                    lg: "18px",
                                                                    xl: "20px"
                                                                },
                                                                borderRadius: "60px",

                                                            }}

                                                        >
                                                            <img src={LinkTimeline} height={"30px"} width={"30px"} />
                                                        </Box>
                                                        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }} >
                                                            <Box>
                                                                <Typography sx={{

                                                                    color: "#283246",
                                                                    fontSize: {
                                                                        lg: "14px",
                                                                        xl: "16px"
                                                                    },
                                                                    fontWeight: "600"

                                                                }} >
                                                                    User ID : {activity.user} ({activity.userRole}) {activity.type == "update" ? ("updated") : ("created")} this Fleet.
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography sx={{
                                                                    fontSize: "14px",
                                                                    color: '#97A1B6'

                                                                }} >
                                                                    This Fleet  {activity.type == "update" ? ("updated") : ("created")}
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        <Box>
                                                            <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                {activity.time.split(" ")[0]}
                                                            </Typography>
                                                            <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600", textAlign: "center" }}>
                                                                {activity.time.split(" ")[1].replace("th,", "")}
                                                            </Typography>
                                                            <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                {activity.time.split(" ")[2]}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )
                                    })
                                }


                            </Timeline>
                        </Box>
                    </Box>) : (<></>)
                }
            </Box>
        </Box>
    )
}

export default EditFleetForm
export const FleetTablesCellItems = [
    { label: "Fleet Name", key: "fleet_name" },
    { label: "Type", key: "fleet_type" },
    { label: "Billing Plan", key: "billing_plan" },
    { label: "Location", key: "location" },
    { label: "Support Email", key: "support_email" },
    { label: "Created Date", key: "created_date" },
]

export const FleetTablesData = [
    {
        "fleet_id": "1", "fleet_name": "Shyamal Road Public Fleet", "fleet_type": "Public", "billing_plan": "Public", "location": "Shyamal Cross Road", "support_email": "admin@gamil.com", "created_date": "Jul 12th, 2024 17:19:09",
        "total_vehicle": "50", "total_trips": "16", "today_trips": "06", "revenue_generated": "50$",
        "alert_email": "alert1@gmail.com", "address": "Shyamal Cross Roads, 132 Feet Ring Rd, Swinagar Society, Nehru Nagar, Shyamal, Ahmedabad, Gujarat 380015", "lat": 23.014433014483792, "lng": 72.53036370328435, "support_phone_number": "1045208972", "parking_requirment": "Required", "auto_end_ride_ideal_vehicle": "Not Required", "auto_end_ride_paused_vehicle": "Required",
        "auto_end_ride_critical_battery_vehicle": "Applicable", "low_battery_level": "20", "auto_close_vehicle": "Yes", "distance_lock_unlock_vehicle": "20", "pause_ride": "Yes",
        "createdUser":"15",
        "createdUserRole":"Superadmin",
        "createdAt": "Jul 12th, 2024 17:19:09",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
    ]
    },
    {
        "fleet_id": "2", "fleet_name": "New Ranip Private Fleet", "fleet_type": "Private", "billing_plan": "Private", "location": "New Ranip", "support_email": "admin2@gamil.com", "created_date": "Jul 13th, 2024 17:19:09", "total_vehicle": "20", "total_trips": "20", "today_trips": "16", "revenue_generated": "150$", 
        "alert_email": "alert2@gmail.com", "address": "New Ranip Private Fleet, Swinagar Society,Ahmedabad, Gujarat 380015", "lat": 23.080933619335358, "lng": 72.57671187123493, "support_phone_number": "1045208915", "parking_requirment": "Not Required", "auto_end_ride_ideal_vehicle": "Required", "auto_end_ride_paused_vehicle": "Not Required",
        "auto_end_ride_critical_battery_vehicle": "Not Applicable", "low_battery_level": "30", "auto_close_vehicle": "No", "distance_lock_unlock_vehicle": "30", "pause_ride": "No",
        "createdAt": "Jul 12th, 2024 17:19:09",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        
    ]
    },
    {
        "fleet_id": "3", "fleet_name": "Iskcon temple Public Fleet", "fleet_type": "Public", "billing_plan": "Public", "location": "Iskcon Temple", "support_email": "admin3@gamil.com", "created_date": "Jul 14th, 2024 17:19:09", "total_vehicle": "150", "total_trips": "12", "today_trips": "04", "revenue_generated": "30$", 
        "alert_email": "alert3@gmail.com", "address": "Iskcon temple, 132 Feet Ring Rd, Swinagar Society, Nehru Nagar, Iskcon, Ahmedabad, Gujarat 380015", "lat": 23.028318448763603, "lng": 72.5068473815918, "support_phone_number": "1045208528", "parking_requirment": "Required", "auto_end_ride_ideal_vehicle": "Not Required", "auto_end_ride_paused_vehicle": "Required",
        "auto_end_ride_critical_battery_vehicle": "Applicable", "low_battery_level": "10", "auto_close_vehicle": "Yes", "distance_lock_unlock_vehicle": "30", "pause_ride": "No",
        "createdAt": "Jul 12th, 2024 17:19:09",
       "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        
    ]
    },
    {
        "fleet_id": "4", "fleet_name": "Shivranjani Private Fleet", "fleet_type": "Private", "billing_plan": "Private", "location": "Shivranjani", "support_email": "admin4@gamil.com", "created_date": "Jul 15th, 2024 17:19:09", "total_vehicle": "80", "total_trips": "12", "today_trips": "8", "revenue_generated": "90$", 
        
        "alert_email": "alert4@gmail.com", "address": "Shivranjani Cross Roads, 132 Feet Ring Rd, Swinagar Society,Shivranjani, Ahmedabad, Gujarat 380015", "lat": 23.006221658723216, "lng": 72.53303053962738, "support_phone_number": "1045208782", "parking_requirment": "Not Required", "auto_end_ride_ideal_vehicle": " Required", "auto_end_ride_paused_vehicle": "Not Required",
        "auto_end_ride_critical_battery_vehicle": "Not Applicable", "low_battery_level": "0", "auto_close_vehicle": "No", "distance_lock_unlock_vehicle": "30", "pause_ride": "No",
        "createdAt": "Jul 12th, 2024 17:19:09","activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
    ]
    }
]
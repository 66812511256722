import React, { useState } from "react";
import { Box, Paper, Typography, useTheme, InputLabel, Grid, Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import mobileScreen1 from "../../assets/Images/mobileScreen1.png";
import mobileScreen2 from "../../assets/Images/mobileScreen2.png";
import mobileScreen3 from "../../assets/Images/mobileScreen3.png";
import FilledButton from "../../components/MainButtons/FilledButton";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import { useSelector, useDispatch } from "react-redux";
import { updateSettings } from "../../slices/settingsSlice"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbar } from "../../hooks/Snackbar";



export const Settings = () => {
  const dispath = useDispatch()
  const { settingsData } = useSelector((state) => state.settingManage)
  console.log(settingsData, "this is bois")
  const inputLabel = {
    fontSize: "12px",
    fontWeight: 700,
    mb: 1,
    color: "#283246"
  }

  const showSnackbar=useSnackbar()
  const [formData, setFormData] = useState({
    current_version: settingsData.current_version,
    app_store_url: settingsData.app_store_url,
    desc: settingsData.desc,
    play_store_url: settingsData.play_store_url,
    iot_type: settingsData.iot_type
  })

  const [errors, setErrors] = useState({
    current_version: false,
    app_store_url: false,
    desc: false,
    play_store_url: false,
    iot_type: false

  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors((prev) => ({
      ...prev,
      [name]: value === "" || (typeof value === "string" && (value.includes("Select"))),
    }));
  }

  const validateForm = () => {
    let hasErrors = false;
    const newErrors = Object.keys(formData).reduce((acc, key) => {
      const value = formData[key];
      acc[key] = value === "" || (typeof value === "string" && (value.includes("Select")));
      if (acc[key]) hasErrors = true;
      return acc;
    }, {});

    setErrors(newErrors);
    return !hasErrors;
  };
  const handleSubmit = () => {

    if (validateForm()) {
      dispath(updateSettings(formData))
      showSnackbar("Updated Axons successfully","success")
    } else {
      console.log(errors, "Validation failed");
    }
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          minHeight: "75vh",
          display: "flex",
          flexDirection: 'column'
        }}
      >

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, width: '100%', flex: 1 }}>
          <Box sx={{ flex: 1 }}>
            {/* Application Configuration Section */}
            <Typography sx={{
              color: "#283246",
              fontSize: "24px",
              fontWeight: "600", mb: 3
            }}>Application Configuration</Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }} >
              <Box sx={{ display: "flex", gap: "20px" }}  >


                <Box>
                  <InputLabel sx={inputLabel}>
                    Current Version  <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <FormControl error={errors.current_version} >
                    <Select
                      value={formData.current_version}
                      defaultValue={formData.current_version}
                      onChange={handleInputChange}
                      IconComponent={KeyboardArrowDownIcon}
                      sx={{
                        borderRadius: "10px",
                        width: {
                            xs: "100px",
                            sm: "300px",
                            md: "400px",
                            lg: "300px",
                            xl: "400px"
                        },
                        height: '40px',
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#283246",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1", 
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1", 
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1", 
                        },
                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1", 
                        },
                        "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#DDDEE1", 
                        },
                        "& .MuiSelect-select": {
                            color: "#283246",
                            fontSize: "14px",
                            fontWeight: 500, 
                            fontFamily: '"Helvetica Neue", Arial, sans-serif',
                        },


                    }}
                      name="current_version"

                    >
                      <MenuItem value={"Select Current Version"}>Select Current Version</MenuItem>
                      <MenuItem value="Version 1.0">Version 1.0</MenuItem>
                      <MenuItem value="Version 2.0">Version 2.0</MenuItem>
                      <MenuItem value="Version 3.0">Version 3.0</MenuItem>
                      <MenuItem value="Version 4.0">Version 4.0</MenuItem>
                    </Select>
                    {errors.current_version && (
                      <FormHelperText>
                        Current Version is required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>


                <Box>
                  <InputLabel sx={inputLabel}>
                    App store URL<span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.app_store_url}
                    onChange={handleInputChange}
                    name="app_store_url"
                    disabled
                    placeholder={"Enter App Store URL"}
                    error={errors.app_store_url}
                    helperText={errors.app_store_url ? "App store url is required" : ""}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "20px" }} >
                <Box>
                  <InputLabel sx={inputLabel}>
                    Description <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.desc}
                    onChange={handleInputChange}
                    name="desc"
                    placeholder={"Enter Description"}
                    error={errors.desc}
                    helperText={errors.desc ? "Description is required" : ""}
                  />
                </Box>
                <Box>
                  <InputLabel sx={inputLabel}>
                    Play store URL<span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.play_store_url}
                    onChange={handleInputChange}
                    disabled
                    name="play_store_url"
                    placeholder={"Enter Play Store URL"}
                    error={errors.play_store_url}
                    helperText={errors.play_store_url ? "Play store url is required" : ""}
                  />
                </Box>
              </Box>

            </Box>
            {/* Vehicle Configuration Section */}
            <Typography
              sx={{
                color: "#283246",
                fontSize: "24px",
                fontWeight: "600",
                mt: 5,
                mb: 2,
              }}
            >
              Vehicle Configuration
            </Typography>
            <Box sx={{ width: '50%', mb: 5 }}>
              <Box>
                <InputLabel sx={inputLabel}>
                  IOT Type<span style={{ color: "#F99494" }}>*</span>
                </InputLabel>
                <FormControl error={errors.iot_type} >
                  <Select
                    value={formData.iot_type}
                    defaultValue={formData.iot_type}
                    onChange={handleInputChange}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      borderRadius: "10px",
                      width: {
                          xs: "100px",
                          sm: "300px",
                          md: "400px",
                          lg: "300px",
                          xl: "400px"
                      },
                      height: '40px',
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#283246",
                      "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1", 
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1", 
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1", 
                      },
                      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1", 
                      },
                      "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#DDDEE1", 
                      },
                      "& .MuiSelect-select": {
                          color: "#283246",
                          fontSize: "14px",
                          fontWeight: 500, 
                          fontFamily: '"Helvetica Neue", Arial, sans-serif',
                      },


                  }}
                    name="iot_type"

                  >
                    <MenuItem value={"Select IOT Type"}>Select IOT Type</MenuItem>
                    <MenuItem value="Axons Micro">Axons Micro</MenuItem>
                    <MenuItem value="Axons Micro Pro">Axons Micro Pro</MenuItem>


                  </Select>
                  {errors.iot_type && (
                      <FormHelperText>
                        IOT type is required
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
            </Box>
          </Box>

          {/* Mobile Screens Section */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: 2, sm: 3 },
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            {[mobileScreen1, mobileScreen2, mobileScreen3].map((screen, index) => (
              <img
                key={index}
                src={screen}
                alt={`Mobile screen ${index + 1}`}
                style={{ width: index === 1 ? '136px' : '96px', height: index === 1 ? '276px' : '195px', borderRadius: '10px' }}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <Box
            onClick={() => handleSubmit()}
          >
            <FilledButton sx={{ width: '150px', height: '50px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>Update</FilledButton>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

